import { Contract, ContractStatus, UserProjectRole } from '@builder-bud/common';

export function canUserCreateContract(userProjectRole: UserProjectRole): boolean {
  return userProjectRole === UserProjectRole.Contractor;
}

export function canUserReadContract(userProjectRole: UserProjectRole, contract: Contract): boolean {
  if (userProjectRole === UserProjectRole.Contractor) {
    return true;
  }

  return (
    userProjectRole === UserProjectRole.Homeowner &&
    [ContractStatus.Published, ContractStatus.Approved, ContractStatus.Paid, ContractStatus.Declined].includes(
      contract.status
    )
  );
}

export function canUserUpdateContract(userProjectRole: UserProjectRole): boolean {
  return userProjectRole === UserProjectRole.Contractor;
}

export function canUserApproveContract(userProjectRole: UserProjectRole): boolean {
  return userProjectRole === UserProjectRole.Homeowner;
}

export function canUserDeleteContract(userProjectRole: UserProjectRole): boolean {
  return userProjectRole === UserProjectRole.Contractor;
}
