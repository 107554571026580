import { ChevronRightRounded, HomeRounded } from '@mui/icons-material';
import { Box, Breadcrumbs, Link, Stack, Typography } from '@mui/joy';

export default function AppDownloadScreen() {
  return (
    <Box sx={{ flex: 1, width: '100%' }}>
      <Box
        sx={{
          position: 'sticky',
          top: { sm: -100, md: -110 },
          bgcolor: 'background.body',
        }}
      >
        <Box sx={{ px: { xs: 2, md: 6 } }}>
          <Breadcrumbs
            size="sm"
            aria-label="breadcrumbs"
            separator={<ChevronRightRounded fontSize="small" />}
            sx={{ pl: 0 }}
          >
            <Link underline="none" color="neutral" href="/" aria-label="Home">
              <HomeRounded />
            </Link>
            <Typography color="primary" fontWeight={500} fontSize={12}>
              App Download
            </Typography>
          </Breadcrumbs>
          <Typography level="h2" component="h1" sx={{ mt: 1, mb: 2 }}>
            App Download
          </Typography>
        </Box>

        <Stack alignItems="center">
          <Stack sx={{ maxWidth: 640 }} alignItems="center" m={4}>
            <img src="../../../assets/builder-bud-landscape.png" style={{ width: '100%' }} alt="" />
          </Stack>
          <Stack
            sx={{ maxWidth: 640, flexDirection: { xs: 'column', sm: 'row' } }}
            justifyContent="center"
            m={4}
            gap={4}
          >
            <Link
              sx={{ width: '100%' }}
              href="https://apps.apple.com/us/app/builderbud/id6541756415?ign-itscg=30200&ign-itsct=apps_box_link&mttnsubad=6541756415"
              target="_blank"
            >
              <img src="../../../assets/download-on-the-appstore.png" style={{ width: '100%' }} alt="" />
            </Link>
            <Link
              sx={{ width: '100%' }}
              href="https://play.google.com/store/apps/details?id=com.builderbud"
              target="_blank"
            >
              <img src="../../../assets/get-it-on-google-play.png" style={{ width: '100%' }} alt="" />
            </Link>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
}
