import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
  AppRole,
  CreateContractorSearchRequest,
  CreateUserRequest,
  ProjectType,
  UpdateUserRequest,
  UserProjectRole,
} from '@builder-bud/common';

import { PHONE_REGEX, RegistrationIntent, WEBSITE_REGEX } from './users';

export type OnboardingStep = {
  index: UnauthenticatedSteps | HomeownerSteps | ContractorSteps;
  label: string;
};

export enum UnauthenticatedSteps {
  selectAccountType = 1,
  createAccount = 2,
  ellipsis = 100, // Fake step to show that there are more steps remaining
}

export type SelectAccountFormData = {
  appRole: AppRole;
};

export const SelectAccountSchemaResolver = yupResolver(
  yup.object<SelectAccountFormData>().shape({
    appRole: yup.mixed<AppRole>().oneOf(Object.values(AppRole)).required('Account Type is required'),
  })
);

export type AccountFormData = {
  email: string;
  password: string;
  phone: string;
  firstName: string;
  lastName: string;
  zipCode: string;
};

export const AccountSchemaResolver = yupResolver(
  yup.object<AccountFormData>().shape({
    email: yup
      .string()
      .email('Please enter a valid email')
      .required('Email is required')
      .max(256, 'Email must be 256 character or less'),
    password: yup
      .string()
      .required('Password is required')
      .min(8, 'Password must be 8 characters or more')
      .max(256, 'Password must be 256 characters or less'),
    phone: yup
      .string()
      .required('Phone Number is required')
      .min(10, 'Please enter a valid 10 digit phone number')
      .max(10, 'Please enter a valid 10 digit phone number')
      .matches(PHONE_REGEX, 'Please enter a valid 10 digit phone number'),
    firstName: yup
      .string()
      .required('First Name is required')
      .min(1, 'First Name must be 1 character or more')
      .max(256, 'First Name must be 256 characters or less'),
    lastName: yup
      .string()
      .required('Last Name is required')
      .min(1, 'Last Name must be 1 character or more')
      .max(256, 'Last Name must be 256 characters or less'),
    zipCode: yup
      .string()
      .required('Zip Code is required')
      .min(5, 'Zip Code must be 5 characters or more')
      .max(11, 'Zip Code must be 11 characters or less'),
  })
);

export function getDefaultAccountFormValues(): AccountFormData {
  return {
    email: '',
    password: '',
    phone: '',
    firstName: '',
    lastName: '',
    zipCode: '',
  };
}

export function getAccountSubmitData(appRole: AppRole, data: AccountFormData): CreateUserRequest {
  return {
    appRole,
    email: data.email,
    password: data.password,
    phone: `+1${data.phone}`,
    firstName: data.firstName,
    lastName: data.lastName,
    zipCode: data.zipCode,
  };
}

//Shared between the Homeowner and Contractor Roles
export type WhyFormData = {
  registrationIntent: RegistrationIntent;
};

//Shared between the Homeowner and Contractor Roles
export const WhySchemaResolver = yupResolver(
  yup.object<WhyFormData>().shape({
    registrationIntent: yup
      .mixed<RegistrationIntent>()
      .oneOf(Object.values(RegistrationIntent))
      .required("Please tell us more about why you're here"),
  })
);

//Shared between the Homeowner and Contractor Roles
export type InvitationsFormData = {
  invites?: InvitationData[];
};

//Shared between the Homeowner and Contractor Roles
export type InvitationData = {
  email: string;
  projectRole: UserProjectRole;
};

//Shared between the Homeowner and Contractor Roles
export const inviteDefaultValue: InvitationData = {
  email: '',
  projectRole: UserProjectRole.Homeowner, //Todo: This should default to the opposite role of the user going through onboarding
};

//Shared between the Homeowner and Contractor Roles
export const InvitesSchemaResolver = yupResolver(
  yup.object<InvitationsFormData>().shape({
    invites: yup
      .array<InvitationData>(
        yup.object({
          email: yup
            .string()
            .email('Please enter a valid email')
            .required('Email is required')
            .max(256, 'Email must be 256 character or less'),
          projectRole: yup
            .mixed<UserProjectRole>()
            .oneOf(Object.values(UserProjectRole))
            .required('Project role is required'),
        })
      )
      .optional(),
  })
);

//Shared between the Homeowner and Contractor Roles
//This is intentionally singular so that it can be used to append to the invites array
export function getDefaultInviteFormValue(): InvitationData {
  return inviteDefaultValue;
}

export enum HomeownerSteps {
  homeownerWhy = 1,
  homeownerGiveContractorInfo = 2,
  homeownerCreateProject = 3,
  homeownerPayment = 4,
  homeownerInvitePeople = 5,
  homeownerEllipsis = 100, // Fake step to show that there are more steps remaining
}

export type HomeownerProfileFormData = {
  zipCode: string;
  projectType: ProjectType;
  projectDescription?: string;
};

export const HomeownerProfileSchemaResolver = yupResolver(
  yup.object<HomeownerProfileFormData>().shape({
    zipCode: yup
      .string()
      .required('Zip Code is required')
      .min(5, 'Zip Code must be 5 characters or more')
      .max(11, 'Zip Code must be 11 characters or less'),
    projectType: yup.mixed<ProjectType>().oneOf(Object.values(ProjectType)).required('Project Type is required'),
    projectDescription: yup.string().optional().max(2048, 'Description must be 2048 characters or less'),
  })
);

export function getDefaultHomeownerProfileFormValues(): HomeownerProfileFormData {
  return {
    zipCode: '',
    projectType: ProjectType.ADU,
    projectDescription: '',
  };
}

export function getHomeownerProfileSubmitData(data: HomeownerProfileFormData): CreateContractorSearchRequest {
  return {
    //zipCode: data.zipCode, TODO: Wire up when zipcode is added to the API
    projectType: data.projectType,
    projectDescription: data.projectDescription === '' ? undefined : data.projectDescription,
  };
}

export enum ContractorSteps {
  contractorWhy = 1,
  contractorCreateProfile = 2,
  contractorCreateProject = 3,
  contractorPayment = 4,
  contractorContinueProfile = 5,
  contractorInvitePeople = 6,
  contractorEllipsis = 100, // Fake step to show that there are more steps remaining
}

export type ContractorProfileFormData = {
  businessName?: string;
  bio?: string;
  website?: string;
};

export const ContractorProfileSchemaResolver = yupResolver(
  yup.object<ContractorProfileFormData>().shape({
    businessName: yup.string().optional().max(128, 'Business Name must be 128 characters or less'),
    bio: yup.string().optional().max(128, 'Bio must be 1024 characters or less'),
    website: yup
      .string()
      .optional()
      .matches(WEBSITE_REGEX, { message: 'Please enter a valid URL', excludeEmptyString: true }),
  })
);

export function getDefaultContractorProfileFormValues(): ContractorProfileFormData {
  return {
    businessName: '',
    bio: '',
    website: '',
  };
}

export function getContractorProfileSubmitData(data: ContractorProfileFormData): UpdateUserRequest {
  return {
    businessName: data.businessName === '' ? null : data.businessName,
    bio: data.bio === '' ? null : data.bio,
    website: data.website === '' ? null : data.website,
  };
}

export type ContractorContinueFormData = {
  name?: string;
  displayAddress: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  stateCode?: string;
  zipCode?: string;
  projectTypes: ProjectType[];
  license?: string;
};

export const ContractorContinueSchemaResolver = yupResolver(
  yup.object<ContractorContinueFormData>().shape({
    displayAddress: yup.string().required('Address is required'),
    addressLine1: yup.string().optional(),
    addressLine2: yup.string().optional(),
    city: yup.string().optional(),
    stateCode: yup.string().optional(),
    zipCode: yup.string().optional(),
    projectTypes: yup.array().min(1, 'Project Type is required').required('Project Type is required'),
    license: yup.string().optional().max(128, 'License # must be 64 characters or less'),
  })
);

export function getDefaultContractorContinueFormValues(): ContractorContinueFormData {
  return {
    displayAddress: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    stateCode: '',
    zipCode: '',
    projectTypes: [ProjectType.ADU],
    license: '',
  };
}

export function getContractorContinueSubmitData(data: ContractorContinueFormData): UpdateUserRequest {
  return {
    //TODO: Wire up when the API supports this data
    //Send null to clear values
    addressLine1: data.addressLine1,
    addressLine2: data.addressLine2 === '' ? null : data.addressLine2,
    city: data.city,
    stateCode: data.stateCode,
    zipCode: data.zipCode,
    projectTypes: data.projectTypes,
    license: data.license === '' ? null : data.license,
  };
}
