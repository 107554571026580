import {
  ChevronLeft,
  ChevronRight,
  ChevronRightRounded,
  Close,
  FilterAlt,
  HomeRounded,
  Search,
} from '@mui/icons-material';
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Link,
  Modal,
  ModalClose,
  ModalDialog,
  Option,
  Select,
  Sheet,
  Stack,
  Typography,
  useTheme,
} from '@mui/joy';
import { useMemo, useState } from 'react';
import { ScrollView } from 'react-native';
import { Calendar } from 'react-native-calendars';

import { DateWithoutTime, EventId, TaskId, TaskStatus, dateToDateWithoutTime } from '@builder-bud/common';
import { useGetMeEventsQuery, useGetMeTasksQuery, useGetProjectsQuery } from '@builder-bud/common-ui';

import CircularProgressComponent from '../../components/circular-progress.component';
import ErrorLoadingComponent from '../../components/error-loading.component';
import EventTableComponent from '../events/events-table.component';
import TaskTableComponent from '../tasks/tasks-table.component';

//TODO replace with a hardcoded set of colors that align with the style of the app
const calendarColors = [] as string[];
for (let i = 0; i < 1000; i++) {
  calendarColors.push(
    '#000000'.replace(/0/g, function () {
      return (~~(Math.random() * 16)).toString(16);
    })
  );
}

function CalendarScreen() {
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const [projectFilter, setProjectFilter] = useState<string[]>([]);
  const [search, setSearch] = useState('');

  const today = new Date();
  const [selectedDay, setSelectedDay] = useState<DateWithoutTime | ''>(dateToDateWithoutTime(today));
  const [startDate, setStartDate] = useState<DateWithoutTime>(
    dateToDateWithoutTime(new Date(today.getFullYear(), today.getMonth(), 1))
  );
  const [endDate, setEndDate] = useState<DateWithoutTime>(
    dateToDateWithoutTime(new Date(today.getFullYear(), today.getMonth() + 1, 0))
  );

  const {
    data: projects = [],
    isLoading: isProjectsLoading,
    isFetching: isProjectsFetching,
    isError: isProjectsError,
    error: projectsError,
  } = useGetProjectsQuery();

  const {
    data: tasks = [],
    isLoading: isTasksLoading,
    isFetching: isTasksFetching,
    isError: isTasksError,
    error: tasksError,
  } = useGetMeTasksQuery({ startDate, endDate });

  const {
    data: events = [],
    isLoading: isEventsLoading,
    isFetching: isEventsFetching,
    isError: isEventsError,
    error: eventsError,
  } = useGetMeEventsQuery({ startDate, endDate });

  const filteredProjects = projectFilter.length
    ? projects.filter((project) => projectFilter.includes(project.id))
    : projects;

  const tasksWithColor = useMemo(() => {
    return (
      tasks
        //TODO move the filtering of subtasks to the API if this is the only place we use it
        .filter((task) => !task.parentTaskId)
        .filter((task) => task.status !== TaskStatus.Archived)
        .filter((task) => search === '' || task.name.toLowerCase().includes(search.toLowerCase()))
        .filter((task) => filteredProjects.find((project) => project.id === task.projectId))
        .map((task, i) => {
          const projectName = projects.find((project) => project.id === task.projectId)?.name;
          return { ...task, projectName, color: calendarColors[i] };
        })
    );
  }, [filteredProjects, search, tasks, projects]);

  const eventsWithColor = useMemo(() => {
    return events
      .filter((event) => filteredProjects.find((project) => project.id === event.projectId))
      .filter((event) => search === '' || event.name.toLowerCase().includes(search.toLowerCase()))
      .map((event, i) => {
        const projectName = projects.find((project) => project.id === event.projectId)?.name;
        return { ...event, projectName, color: calendarColors[tasks.length + i] };
      });
  }, [filteredProjects, search, events, projects, tasks.length]);

  const markedDates = useMemo(() => {
    const markedDates: Record<
      string,
      { selected?: boolean; dots: { id: TaskId | EventId; key: string; color: string }[] }
    > = {};
    markedDates[selectedDay] = { selected: true, dots: [] };

    for (const task of tasksWithColor) {
      if (task.dueDate) {
        const key = dateToDateWithoutTime(task.dueDate);
        if (!markedDates[key]) {
          markedDates[key] = { dots: [] };
        }
        markedDates[key].dots.push({ id: task.id, key: task.name, color: task.color });
      }
    }

    for (const event of eventsWithColor) {
      const start = new Date(event.startDate);
      const end = new Date(event.endDate);

      let loop = new Date(start);
      while (loop <= end) {
        const key = dateToDateWithoutTime(loop);
        if (!markedDates[key]) {
          markedDates[key] = { dots: [] };
        }
        markedDates[key].dots.push({ id: event.id, key: event.name, color: event.color });
        loop = new Date(loop.setDate(loop.getDate() + 1));
      }
    }

    return markedDates;
  }, [selectedDay, tasksWithColor, eventsWithColor]);

  const calendarTaskItems = useMemo(() => {
    const calendarItems = selectedDay ? new Array<any>() : [...tasksWithColor];

    if (selectedDay) {
      for (const dot of markedDates[selectedDay].dots) {
        const task = tasksWithColor.find((task) => dot.id === task.id);
        if (task) {
          calendarItems.push(task);
        }
      }
    }

    return calendarItems;
  }, [selectedDay, markedDates, tasksWithColor]);

  const calendarEventItems = useMemo(() => {
    const calendarItems = selectedDay ? new Array<any>() : [...eventsWithColor];

    if (selectedDay) {
      for (const dot of markedDates[selectedDay].dots) {
        const event = eventsWithColor.find((event) => dot.id === event.id);
        if (event) {
          calendarItems.push(event);
        }
      }
    }

    return calendarItems;
  }, [selectedDay, eventsWithColor, markedDates]);

  const isFetching = isProjectsFetching || isTasksFetching || isEventsFetching;
  const isLoading = isProjectsLoading || isTasksLoading || isEventsLoading;
  const isError = isProjectsError || isTasksError || isEventsError;
  const error = isProjectsError ? projectsError : isTasksError ? tasksError : isEventsError ? eventsError : {};
  if (isError) return <ErrorLoadingComponent error={error} />;

  const renderFilters = () => (
    <FormControl size="sm">
      <FormLabel>Projects</FormLabel>
      <Select
        value={projectFilter}
        onChange={(e, newValue) => setProjectFilter(newValue)}
        size="sm"
        placeholder="Filter by project"
        slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
        multiple={true}
      >
        {projects.map((option, i) => (
          <Option key={option.id} value={option.id}>
            {option.name}
          </Option>
        ))}
      </Select>
    </FormControl>
  );

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={<ChevronRightRounded fontSize="small" />}
          sx={{ pl: 0 }}
        >
          <Link underline="none" color="neutral" href="/" aria-label="Home">
            <HomeRounded />
          </Link>
          <Link underline="hover" color="neutral" href="/projects" fontSize={12} fontWeight={500}>
            Calendar
          </Link>
        </Breadcrumbs>
      </Box>
      <Box
        sx={{
          display: 'flex',
          mb: 1,
          gap: 1,
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'start', sm: 'center' },
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Typography level="h2" component="h1">
          Calendar
        </Typography>
      </Box>

      <Sheet
        sx={{
          display: { xs: 'flex', sm: 'none' },
          my: 1,
          gap: 1,
        }}
      >
        <Input
          size="sm"
          placeholder="Filter by name"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          startDecorator={<Search />}
          endDecorator={search !== '' ? <Close onClick={() => setSearch('')} /> : null}
          sx={{ flexGrow: 1 }}
        />
        <IconButton size="sm" variant="outlined" color="neutral" onClick={() => setOpen(true)}>
          <FilterAlt />
        </IconButton>
        <Modal open={open} onClose={() => setOpen(false)}>
          <ModalDialog aria-labelledby="filter-modal" layout="fullscreen" sx={{ mt: 'var(--Header-height)' }}>
            <ModalClose />
            <Typography id="filter-modal" level="h2">
              Filters
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Sheet sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {renderFilters()}
              <Button color="primary" onClick={() => setOpen(false)}>
                Submit
              </Button>
            </Sheet>
          </ModalDialog>
        </Modal>
      </Sheet>

      <Box
        sx={{
          borderRadius: 'sm',
          py: 2,
          display: { xs: 'none', sm: 'flex' },
          flexWrap: 'wrap',
          gap: 1.5,
          '& > *': {
            minWidth: { xs: '120px', md: '160px' },
          },
        }}
      >
        <FormControl sx={{ flex: 1 }} size="sm">
          <FormLabel>Name</FormLabel>
          <Input
            size="sm"
            placeholder="Filter by name"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            startDecorator={<Search />}
            endDecorator={search !== '' ? <Close onClick={() => setSearch('')} /> : null}
          />
        </FormControl>
        {renderFilters()}
      </Box>

      <Stack flexDirection="row" flexGrow="1" justifyContent="center">
        <Card sx={{ display: 'flex', flex: 1, flexGrow: 1 }}>
          <Calendar
            markingType={'multi-dot'}
            onMonthChange={(date) => {
              const d = new Date(date.dateString);
              setStartDate(dateToDateWithoutTime(new Date(d.getFullYear(), d.getMonth(), 1)));
              setEndDate(dateToDateWithoutTime(new Date(d.getFullYear(), d.getMonth() + 1, 0)));
              setSelectedDay(date.dateString as DateWithoutTime);
            }}
            onDayPress={(date) => {
              if (selectedDay === date.dateString) {
                setSelectedDay('');
              } else {
                setSelectedDay(date.dateString as DateWithoutTime);
              }
            }}
            markedDates={markedDates}
            style={{ borderRadius: 8 }}
            theme={{
              backgroundColor: theme.vars.palette.common.white,
              calendarBackground: theme.vars.palette.common.white,
              textSectionTitleColor: theme.vars.palette.text.primary,
              selectedDayBackgroundColor: theme.vars.palette.secondary[500],
              selectedDayTextColor: theme.vars.palette.common.white,
              todayTextColor: theme.vars.palette.secondary[500],
              dayTextColor: theme.vars.palette.text.primary,
              textDisabledColor: theme.vars.palette.text.icon,
              textDayFontFamily: theme.vars.fontFamily.body,
              textDayFontWeight: 500,
            }}
            renderArrow={(direction: 'left' | 'right') =>
              direction === 'left' ? (
                <Typography fontSize={32} color="secondary">
                  <ChevronLeft fontSize="inherit" />
                </Typography>
              ) : (
                <Typography fontSize={32} color="secondary">
                  <ChevronRight fontSize="inherit" />
                </Typography>
              )
            }
          />
          {isFetching || isLoading ? (
            <CircularProgressComponent />
          ) : (
            <ScrollView contentContainerStyle={{ rowGap: 16 }}>
              <Typography level="title-lg">Tasks</Typography>
              {calendarTaskItems.length === 0 ? (
                <Typography level="title-md">No tasks due this day</Typography>
              ) : (
                <TaskTableComponent rows={calendarTaskItems} showProject={true} hideSearch={true} />
              )}

              <Typography level="title-lg">Events</Typography>
              {calendarEventItems.length === 0 ? (
                <Typography level="title-md">No events scheduled for this day</Typography>
              ) : (
                <EventTableComponent rows={calendarEventItems} showProject={true} hideSearch={true} />
              )}
            </ScrollView>
          )}
        </Card>
      </Stack>
    </>
  );
}

export default CalendarScreen;
