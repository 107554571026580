import {
  Chat,
  ChatId,
  CreateChatRequest,
  CreateChatResponse,
  GetChatResponse,
  GetChatsResponse,
  ProjectId,
  UpdateChatRequest,
  UpdateChatResponse,
} from '@builder-bud/common';

import { api } from './api';
import { useGetMeChatsQuery } from './me.slice';

export const chatsSlice = api.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getChats: builder.query<Chat[], { projectId: ProjectId }>({
      query: (body) => ({
        url: `/projects/${body.projectId}/chats`,
        method: 'GET',
      }),
      transformResponse: (response: GetChatsResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to get chats');
      },
      providesTags: ['project_chats'],
    }),
    getChat: builder.query<Chat, { projectId: ProjectId; chatId: ChatId }>({
      query: (body) => ({
        url: `/projects/${body.projectId}/chats/${body.chatId}`,
        method: 'GET',
      }),
      transformResponse: (response: GetChatResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to get chat');
      },
      providesTags: ['project_chats'],
    }),
    createChat: builder.mutation<Chat, CreateChatRequest & { projectId: ProjectId }>({
      query: (body) => ({
        url: `/projects/${body.projectId}/chats`,
        method: 'POST',
        body,
      }),
      transformResponse: (response: CreateChatResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to create chat');
      },
      invalidatesTags: ['project_chats', 'me_chats'],
    }),

    editChat: builder.mutation<Chat, UpdateChatRequest & { projectId: ProjectId; chatId: ChatId }>({
      query: (body) => ({
        url: `/projects/${body.projectId}/chats/${body.chatId}`,
        method: 'PATCH',
        body,
      }),
      transformResponse: (response: UpdateChatResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to edit chat');
      },
      invalidatesTags: ['project_chats', 'me_chats'],
    }),
    deleteChat: builder.mutation<Chat, { projectId: ProjectId; chatId: ChatId }>({
      query: (body) => ({
        url: `/projects/${body.projectId}/chats/${body.chatId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['project_chats', 'me_chats'],
    }),
  }),
});

export const { useGetChatsQuery, useGetChatQuery, useCreateChatMutation, useEditChatMutation, useDeleteChatMutation } =
  chatsSlice;

export function useGetMeOrProjectChatsQuery(projectId: ProjectId | undefined) {
  return projectId ? useGetChatsQuery({ projectId: projectId }) : useGetMeChatsQuery();
}
