import {
  CreateTaskRequest,
  CreateTaskResponse,
  GetTaskResponse,
  GetTasksResponse,
  ProjectId,
  Task,
  TaskId,
  UpdateTaskRequest,
  UpdateTaskResponse,
} from '@builder-bud/common';

import { api } from './api';

export const tasksSlice = api.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getTasks: builder.query<Task[], { projectId: ProjectId; taskId?: TaskId }>({
      query: (body) => ({
        url: body.taskId ? `projects/${body.projectId}/tasks/${body.taskId}` : `projects/${body.projectId}/tasks`,
        method: 'GET',
      }),
      transformResponse: (response: GetTasksResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to get tasks');
      },
      providesTags: ['project_tasks'],
    }),
    getTask: builder.query<Task, { projectId: ProjectId; taskId: TaskId }>({
      query: (body) => ({
        url: `/projects/${body.projectId}/tasks/${body.taskId}`,
        method: 'GET',
      }),
      transformResponse: (response: GetTaskResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to get task');
      },
      providesTags: ['project_tasks'],
    }),
    createTask: builder.mutation<Task, CreateTaskRequest & { projectId: ProjectId }>({
      query: (body) => ({
        url: `projects/${body.projectId}/tasks`,
        method: 'POST',
        body,
      }),
      transformResponse: (response: CreateTaskResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to create task');
      },
      invalidatesTags: ['project_tasks', 'me_tasks'],
    }),
    editTask: builder.mutation<Task, UpdateTaskRequest & { id: TaskId; projectId: ProjectId }>({
      query: (body) => ({
        url: `projects/${body.projectId}/tasks/${body.id}`,
        method: 'PATCH',
        body,
      }),
      transformResponse: (response: UpdateTaskResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to edit task');
      },
      invalidatesTags: ['me_tasks'],
      //Updating the cache instead of invalidating 'project_tasks'
      async onQueryStarted({ id, projectId }, { dispatch, queryFulfilled }) {
        const { data: updatedTask } = await queryFulfilled;
        dispatch(
          tasksSlice.util.updateQueryData('getTasks', { projectId }, (draftTasks) => {
            const taskIndex = draftTasks.findIndex((project) => project.id === updatedTask.id);
            draftTasks[taskIndex] = updatedTask;
          })
        );
        dispatch(
          tasksSlice.util.updateQueryData(
            'getTask',
            {
              projectId,
              taskId: updatedTask.parentTaskId ? updatedTask.parentTaskId : id,
            },
            (draftTask) => {
              if (updatedTask.parentTaskId) {
                if (draftTask.subTasks) {
                  const taskIndex = draftTask.subTasks.findIndex((task) => task.id === updatedTask.id);
                  draftTask.subTasks[taskIndex] = updatedTask;
                }
              } else {
                Object.assign(draftTask, updatedTask);
              }
            }
          )
        );
      },
    }),
    deleteTask: builder.mutation<Task, { id: TaskId; projectId: ProjectId }>({
      query: (body) => ({
        url: `projects/${body.projectId}/tasks/${body.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['project_tasks', 'me_tasks'],
    }),
  }),
});

export const { useGetTasksQuery, useGetTaskQuery, useCreateTaskMutation, useEditTaskMutation, useDeleteTaskMutation } =
  tasksSlice;
