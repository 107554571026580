import { Link, Stack, Typography } from '@mui/joy';

import { User, UserId } from '@builder-bud/common';
import { useGetUsersQuery } from '@builder-bud/common-ui';

function CompanyComponent({ userId }: { userId: UserId }) {
  const { data: users = [] } = useGetUsersQuery();
  const user = users.find((user: User) => user.id === userId);

  if (!user) return null;

  return (
    <Stack style={{ rowGap: 4 }}>
      <Typography level="body-md">{user.displayName}</Typography>
      <Typography level="body-sm">
        Prepared By: {user.firstName} {user.lastName}
      </Typography>
      <Link level="body-sm" href={`mailto:${user.email}`}>
        {user.email}
      </Link>
      <Link level="body-sm" href={`tel: ${user.phone}`}>
        {user.phone}
      </Link>
      {user.website && (
        <Link level="body-sm" href={user.website} target="_blank" rel="noopener">
          {user.website}
        </Link>
      )}
    </Stack>
  );
}

export default CompanyComponent;
