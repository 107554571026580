import { Box, Button, Card, Divider, FormLabel, Grid, Input, Stack, Typography } from '@mui/joy';
import { useState } from 'react';

import { User } from '@builder-bud/common';

import DeleteAccountForm from './delete-account.form';
import UpdateEmailForm from './update-email.form';
import UpdatePasswordForm from './update-password.form';
import UpdatePhoneForm from './update-phone.form';

export default function AccountDetailsForm({ me }: { me: User | null }) {
  const [showEmailForm, setShowEmailForm] = useState(false);
  const [showPhoneForm, setShowPhoneForm] = useState(false);
  const [showPasswordForm, setShowPasswordForm] = useState(false);
  const [showDeleteAccountForm, setShowDeleteAccountForm] = useState(false);

  return (
    <Stack>
      <img src="../../../assets/line-hardhat.png" style={styles.image} alt="" />
      <Card sx={{ display: 'flex', flex: 1, maxWidth: 800 }}>
        <Box sx={{ mb: 1 }}>
          <Typography level="title-md">Account details</Typography>
        </Box>
        <Divider />

        {!showEmailForm && !showPhoneForm && !showPasswordForm && !showDeleteAccountForm && (
          <Grid container spacing={2} sx={{ alignItems: 'center' }}>
            <Grid xs={12} md={2}>
              <FormLabel>Email</FormLabel>
            </Grid>
            <Grid xs={6}>
              <Input value={me?.email} disabled={true} />
            </Grid>
            <Grid xs={6} md={4}>
              <Button size="sm" color="secondary" variant="outlined" onClick={() => setShowEmailForm(true)}>
                Update email
              </Button>
            </Grid>

            <Grid xs={12} md={2}>
              <FormLabel>Phone</FormLabel>
            </Grid>
            <Grid xs={6}>
              <Input value={me?.phone} disabled={true} />
            </Grid>
            <Grid xs={6} md={4}>
              <Button size="sm" color="secondary" variant="outlined" onClick={() => setShowPhoneForm(true)}>
                Update phone
              </Button>
            </Grid>

            <Grid xs={12} md={2}>
              <FormLabel>Password</FormLabel>
            </Grid>
            <Grid xs={6}>
              <Input value="************" disabled={true} />
            </Grid>
            <Grid xs={6} md={4}>
              <Button size="sm" color="secondary" variant="outlined" onClick={() => setShowPasswordForm(true)}>
                Update password
              </Button>
            </Grid>

            <Grid xs={3} sm={4}></Grid>
            <Grid xs={6} sm={4} sx={{ marginTop: 2 }}>
              <Button size="md" color="danger" variant="outlined" onClick={() => setShowDeleteAccountForm(true)}>
                Delete account
              </Button>
            </Grid>
            <Grid xs={3} sm={4}></Grid>
          </Grid>
        )}

        {showEmailForm && <UpdateEmailForm hideForm={() => setShowEmailForm(false)} />}
        {showPhoneForm && <UpdatePhoneForm hideForm={() => setShowPhoneForm(false)} />}
        {showPasswordForm && <UpdatePasswordForm hideForm={() => setShowPasswordForm(false)} />}
        {showDeleteAccountForm && <DeleteAccountForm hideForm={() => setShowDeleteAccountForm(false)} />}
      </Card>
    </Stack>
  );
}

const styles = {
  image: {
    alignSelf: 'center',
    height: 240,
  },
};
