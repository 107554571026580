import {
  ConfirmationResult,
  RecaptchaVerifier,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPhoneNumber,
} from '@firebase/auth';
import { AccountCircle, Key, Password, Phone, Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, Button, Divider, FormControl, FormLabel, Input, Link, Stack, Typography, useTheme } from '@mui/joy';
import { Theme } from '@mui/joy/styles';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AlertObject, openModal, showErrorAlert, showInfoAlert, useAppDispatch } from '@builder-bud/common-ui';

import BuilderBudIcon from '../../components/builderbud-icon.component';
import { auth } from './firebase';

export default function LoginScreen() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const styles = makeStyle(theme);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [secureTextEntry, setSecureTextEntry] = useState(true);
  const toggleSecureTextEntry = () => setSecureTextEntry(!secureTextEntry);
  const [emailLoggingIn, setEmailLoggingIn] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState('');
  const [confirm, setConfirm] = useState<ConfirmationResult | null>(null);
  const [code, setCode] = useState('');
  const [phoneLoggingIn, setPhoneLoggingIn] = useState(false);

  const [recaptchaVerifier, setRecaptchaVerifier] = useState<RecaptchaVerifier | null>(null);

  auth.useDeviceLanguage();

  async function handleSignInWithEmailAndPassword() {
    setEmailLoggingIn(true);
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        console.log('User signed in!');
      })
      .catch((error) => {
        console.log(error);
        dispatch(showErrorAlert(error));
      })
      .finally(() => {
        setEmailLoggingIn(false);
      });
  }

  async function handleSendPasswordResetEmail() {
    if (email === '') {
      dispatch(showInfoAlert('Please enter your email address'));
    } else {
      try {
        await sendPasswordResetEmail(auth, email);
        dispatch(showInfoAlert('If an account exists an email will be sent with instructions to reset your password'));
      } catch (error) {
        console.log(error);
        dispatch(showErrorAlert(error as AlertObject));
      }
    }
  }

  // const handleSignInWithPhoneNumber = useCallback(() => {
  //   if (recaptchaVerifier) {
  //     setPhoneLoggingIn(true);
  //     signInWithPhoneNumber(auth, `+1${phoneNumber}`, recaptchaVerifier)
  //       .then((confirmationResult) => {
  //         setConfirm(confirmationResult);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         dispatch(showErrorAlert(error));
  //       })
  //       .finally(() => {
  //         setPhoneLoggingIn(false);
  //       });
  //   } else {
  //     console.log('recaptchaVerifier is null');
  //   }
  // }, [recaptchaVerifier, phoneNumber, dispatch]);

  // async function confirmCode() {
  //   if (confirm) {
  //     setPhoneLoggingIn(true);
  //     confirm
  //       .confirm(code)
  //       .catch((error) => {
  //         console.log(error);
  //         dispatch(showErrorAlert(error));
  //       })
  //       .finally(() => {
  //         setPhoneLoggingIn(false);
  //       });
  //   } else {
  //     console.log('confirmationResult is null');
  //   }
  // }

  // useEffect(() => {
  //   const recaptchaVerifier = new RecaptchaVerifier(auth, 'signin-with-phone-number-button', {
  //     size: 'invisible',
  //     callback: (response: unknown) => {
  //       console.log('recaptchaVerifier');
  //       console.log(response);
  //       handleSignInWithPhoneNumber();
  //     },
  //   });
  //   setRecaptchaVerifier(recaptchaVerifier);
  //   //Fixing this lint error causes infinite re-rendering
  //   /* eslint-disable-next-line react-hooks/exhaustive-deps */
  // }, []);

  return (
    <Box>
      <Box sx={styles.leftContainer}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100dvh',
            width: '100%',
            px: 2,
          }}
        >
          <Box
            component="header"
            sx={{
              py: 2,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ gap: 2, display: 'flex', alignItems: 'center' }}>
              <BuilderBudIcon />
            </Box>
          </Box>
          <Box component="main" sx={styles.loginContainer}>
            <Stack gap={2}>
              <img src="../../assets/line-house1.png" style={{ height: 160, objectFit: 'cover' }} alt="" />
              {process.env['NX_PUBLIC_ENVIRONMENT'] !== 'production' && (
                <Typography
                  level="h4"
                  style={{ top: -60, color: theme.vars.palette.error['500'], alignSelf: 'center' }}
                >
                  {process.env['NX_PUBLIC_ENVIRONMENT']?.toLocaleUpperCase()}
                </Typography>
              )}
              <Typography level="h2" sx={{ fontWeight: 500 }}>
                Welcome to Builder<Typography level="h2">Bud</Typography>
              </Typography>
              <Typography level="body-md">Construction made easy</Typography>
            </Stack>

            <Stack gap={2}>
              <FormControl>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  startDecorator={<AccountCircle />}
                  onKeyUp={(event) => {
                    if (event.key === 'Enter' && email !== '' && password !== '') {
                      handleSignInWithEmailAndPassword();
                    }
                  }}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Password</FormLabel>
                <Input
                  type={secureTextEntry ? 'password' : ''}
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  startDecorator={<Key />}
                  endDecorator={
                    secureTextEntry ? (
                      <Visibility
                        onClick={() => {
                          toggleSecureTextEntry();
                        }}
                      />
                    ) : (
                      <VisibilityOff
                        onClick={() => {
                          toggleSecureTextEntry();
                        }}
                      />
                    )
                  }
                  onKeyUp={(event) => {
                    if (event.key === 'Enter' && email !== '' && password !== '') {
                      handleSignInWithEmailAndPassword();
                    }
                  }}
                />
              </FormControl>
              <Stack gap={2}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}
                >
                  <Link color="neutral" level="title-sm" onClick={handleSendPasswordResetEmail}>
                    Forgot password?
                  </Link>
                </Box>
                <Button
                  fullWidth
                  onClick={handleSignInWithEmailAndPassword}
                  disabled={email === '' || password === '' || emailLoggingIn || phoneLoggingIn}
                >
                  Login
                </Button>
              </Stack>
              {/*<Divider>or continue with</Divider>*/}
              {/*!confirm ? (
                <Box>
                  <FormControl>
                    <FormLabel>10 Digit Phone Number</FormLabel>
                    <Input
                      type="tel"
                      value={phoneNumber}
                      onChange={(event) => setPhoneNumber(event.target.value)}
                      startDecorator={<Phone />}
                    />
                  </FormControl>
                  <Stack gap={2} sx={{ mt: 2 }}>
                    <Button
                      fullWidth
                      onClick={handleSignInWithPhoneNumber}
                      id="signin-with-phone-number-button"
                      disabled={phoneNumber === '' || phoneNumber.length < 10 || phoneLoggingIn || emailLoggingIn}
                    >
                      Login using phone number
                    </Button>
                  </Stack>
                </Box>
              ) : (
                <Box>
                  <FormControl>
                    <FormLabel>6 digit code</FormLabel>
                    <Input
                      type="tel"
                      value={code}
                      onChange={(event) => setCode(event.target.value)}
                      startDecorator={<Password />}
                    />
                  </FormControl>
                  <Stack gap={2} sx={{ mt: 2 }}>
                    <Button
                      fullWidth
                      onClick={confirmCode}
                      disabled={code === '' || code.length < 6 || phoneLoggingIn || emailLoggingIn}
                    >
                      Confirm code
                    </Button>
                  </Stack>
                </Box>
              )*/}
              <Stack sx={{ mt: 1, gap: 1 }}>
                <Link
                  color="neutral"
                  level="body-sm"
                  sx={{ justifyContent: 'center' }}
                  onClick={() => navigate('/onboarding')}
                >
                  Don't have an account?&nbsp;
                  <Typography color="primary">Sign Up</Typography>
                </Link>
                <Typography level="body-sm" sx={{ textAlign: 'center' }}>
                  By registering or signing in you agree to our
                  <Link
                    color="neutral"
                    level="body-sm"
                    onClick={() => {
                      dispatch(
                        openModal(
                          <Stack
                            sx={{
                              margin: 2,
                              height: '90dvh',
                              width: '90dvw',
                              backgroundColor: theme.vars.palette.common.white,
                            }}
                          >
                            <object
                              type="text/html"
                              data="https://storage.googleapis.com/builderbud-legal/terms-conditions.html"
                              style={{ flex: 1 }}
                              aria-label="terms and conditions"
                            />
                          </Stack>
                        )
                      );
                    }}
                  >
                    Terms & Conditions
                  </Link>
                  &nbsp;and&nbsp;
                  <Link
                    color="neutral"
                    level="body-sm"
                    onClick={() => {
                      dispatch(
                        openModal(
                          <Stack
                            sx={{
                              margin: 2,
                              height: '90dvh',
                              width: '90dvw',
                              backgroundColor: theme.vars.palette.common.white,
                            }}
                          >
                            <object
                              type="text/html"
                              data="https://storage.googleapis.com/builderbud-legal/privacy-policy.html"
                              style={{ flex: 1 }}
                              aria-label="privacy policy"
                            />
                          </Stack>
                        )
                      );
                    }}
                  >
                    Privacy Policy
                  </Link>
                </Typography>
              </Stack>
            </Stack>
          </Box>
          <Box component="footer" sx={{ py: 2 }}>
            <Typography level="body-xs" textAlign="center">
              © Builder Bud {new Date().getFullYear()}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={styles.rightContainer} />
    </Box>
  );
}

const makeStyle = (theme: Theme) => {
  return {
    leftContainer: {
      position: 'fixed',
      right: { xs: 0, md: '50vw' },
      top: 0,
      bottom: 0,
      left: 0,
      transition: 'width var(--Transition-duration)',
      transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
      zIndex: 1,
      display: 'flex',
      overflowY: 'scroll',
      backgroundColor: 'var(--joy-palette-neutral-50)',
    },
    rightContainer: {
      position: 'fixed',
      left: { xs: 0, md: '50vw' },
      top: 0,
      bottom: 0,
      right: 0,
      transition: 'background-image var(--Transition-duration), left var(--Transition-duration) !important',
      transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
      backgroundColor: 'var(--joy-palette-primary-500)',
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundImage: 'url(../../assets/splash-screen.png)',
    },
    loginContainer: {
      my: 'auto',
      py: 2,
      pb: 5,
      display: 'flex',
      flexDirection: 'column',
      gap: 2,
      maxWidth: 400,
      mx: 'auto',
      borderRadius: 'sm',
    },
  };
};
