import { INVITATION_STATUS_LABELS, Invitation, InvitationStatus } from '@builder-bud/common';

import { BBListItem } from './common-ui';

export const INVITATION_STATUS_OPTIONS: BBListItem[] = [
  { value: InvitationStatus.Pending, label: INVITATION_STATUS_LABELS[InvitationStatus.Pending], color: 'error' },
  { value: InvitationStatus.Accepted, label: INVITATION_STATUS_LABELS[InvitationStatus.Accepted], color: 'primary' },
  { value: InvitationStatus.Rejected, label: INVITATION_STATUS_LABELS[InvitationStatus.Rejected], color: 'tertiary' },
] as const;

export function getInvitationStatusLabel(value: InvitationStatus): string {
  return INVITATION_STATUS_LABELS[value] ?? '';
}

export function getInvitationsBadge(receivedInvitations: Invitation[] = []): number {
  const pendingInvitations = receivedInvitations.filter((invitation) => invitation.status === InvitationStatus.Pending);
  return pendingInvitations.length;
}
