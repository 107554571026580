import { yupResolver } from '@hookform/resolvers/yup';
import { parse } from 'date-fns';
import * as yup from 'yup';

import {
  CreateDailyLogRequest,
  DAILY_LOG_TYPE_LABELS,
  DailyLog,
  DailyLogType,
  UpdateDailyLogRequest,
  UserId,
  dateToDateWithoutTime,
} from '@builder-bud/common';

import { BBListItem } from './common-ui';

export const DAILY_LOG_OPTIONS: BBListItem[] = [
  { value: DailyLogType.CrewLog, label: DAILY_LOG_TYPE_LABELS[DailyLogType.CrewLog] },
  { value: DailyLogType.ForecastLog, label: DAILY_LOG_TYPE_LABELS[DailyLogType.ForecastLog] },
  { value: DailyLogType.HomeownerLog, label: DAILY_LOG_TYPE_LABELS[DailyLogType.HomeownerLog] },
  { value: DailyLogType.HomeownerUpdate, label: DAILY_LOG_TYPE_LABELS[DailyLogType.HomeownerUpdate] },
  { value: DailyLogType.SupervisorLog, label: DAILY_LOG_TYPE_LABELS[DailyLogType.SupervisorLog] },
  { value: DailyLogType.DesignerLog, label: DAILY_LOG_TYPE_LABELS[DailyLogType.DesignerLog] },
  { value: DailyLogType.GeneralLog, label: DAILY_LOG_TYPE_LABELS[DailyLogType.GeneralLog] },
] as const;

export function getDailyLogTypeLabel(value: DailyLogType): string {
  return DAILY_LOG_TYPE_LABELS[value] ?? '';
}

export type DailyLogFormData = {
  type: DailyLogType;
  logDate: Date;
  notes: string;
  staffOnsiteUserIds?: UserId[];
};

export const DailyLogSchemaResolver = yupResolver(
  yup.object<DailyLogFormData>().shape({
    type: yup.mixed<DailyLogType>().oneOf(Object.values(DailyLogType)).required('Type is required'),
    logDate: yup.date().required('Log date is required'),
    notes: yup.string().required('Notes is required').max(2048, 'Notes must be 2048 characters or less'),
    staffOnsiteUserIds: yup.array().optional(),
  })
);

export function getDefaultDailyLogFormValues(dailyLog: Partial<DailyLog>): DailyLogFormData {
  const defaultType = DAILY_LOG_OPTIONS.find((option) => option.value === dailyLog.type);

  return {
    type: defaultType?.value as DailyLogType,
    logDate: dailyLog.logDate ? parse(dailyLog.logDate, 'yyyy-MM-dd', new Date()) : new Date(),
    notes: dailyLog.notes ? dailyLog.notes : '',
    staffOnsiteUserIds: dailyLog.staffOnsiteUserIds,
  };
}

export function getDailyLogSubmitData(data: DailyLogFormData): CreateDailyLogRequest | UpdateDailyLogRequest {
  return {
    type: data.type,
    logDate: data.logDate ? dateToDateWithoutTime(data.logDate) : undefined,
    notes: data.notes,
    staffOnsiteUserIds: data.staffOnsiteUserIds && data.staffOnsiteUserIds.length > 0 ? data.staffOnsiteUserIds : null,
  };
}
