import { AddCircle, RemoveCircle } from '@mui/icons-material';
import {
  Button,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  Input,
  Option,
  Select,
  Stack,
  Typography,
} from '@mui/joy';
import { Controller, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';

import {
  InvitationsFormData,
  InvitesSchemaResolver,
  USER_PROJECT_ROLE_OPTIONS,
  getDefaultInviteFormValue,
} from '@builder-bud/common-ui';

export default function InvitationsForm({
  onSubmit,
  onCancel,
  cancelButtonLabel,
}: {
  onSubmit: SubmitHandler<InvitationsFormData>;
  onCancel?: () => void;
  cancelButtonLabel?: string;
}) {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<InvitationsFormData>({
    defaultValues: { invites: [getDefaultInviteFormValue()] },
    resolver: InvitesSchemaResolver,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'invites',
  });

  return (
    <Stack spacing={3} sx={{ display: { xs: 'flex' }, my: 1 }}>
      {fields.map((field, index: number) => (
        <Stack key={index} rowGap={2}>
          <Controller
            control={control}
            name={`invites.${index}.email`}
            render={({ field: { onChange, onBlur, value: controlValue } }) => (
              <FormControl error={!!(errors.invites && errors.invites[index]?.email)} required>
                <FormLabel>Email</FormLabel>
                <Input value={controlValue} onBlur={onBlur} onChange={onChange} />
                {errors.invites && errors.invites[index]?.email && (
                  <FormHelperText>{errors.invites[index]?.email?.message}</FormHelperText>
                )}
              </FormControl>
            )}
          />

          <Controller
            control={control}
            name={`invites.${index}.projectRole`}
            render={({ field: { onChange, value: controlValue } }) => (
              <FormControl error={!!(errors.invites && errors.invites[index]?.projectRole)} required>
                <FormLabel>Account Type</FormLabel>
                <Select
                  value={controlValue}
                  onChange={(e, newValue) => {
                    onChange(newValue);
                  }}
                >
                  {USER_PROJECT_ROLE_OPTIONS.map((option, i) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
                {errors.invites && errors.invites[index]?.projectRole && (
                  <FormHelperText>{errors.invites[index]?.projectRole?.message}</FormHelperText>
                )}
              </FormControl>
            )}
          />
          {index < fields.length - 1 && <Divider />}
        </Stack>
      ))}

      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" alignItems="center" onClick={() => append(getDefaultInviteFormValue())}>
          <IconButton size="sm">
            <AddCircle />
          </IconButton>
          <Typography level="body-xs">Add another user</Typography>
        </Stack>

        <Stack direction="row" alignItems="center" onClick={() => remove(fields.length - 1)}>
          <IconButton size="sm">
            <RemoveCircle />
          </IconButton>
          <Typography level="body-xs">Remove last user</Typography>
        </Stack>
      </Stack>

      <Stack direction="row" columnGap={2} justifyContent="flex-end">
        {onCancel && (
          <Button variant="outlined" onClick={() => onCancel()}>
            {cancelButtonLabel ? cancelButtonLabel : 'Cancel'}
          </Button>
        )}
        <Button variant="solid" onClick={handleSubmit(onSubmit)}>
          Send Invitations
        </Button>
      </Stack>
    </Stack>
  );
}
