import { ContractorSearch, CreateContractorSearchRequest, CreateContractorSearchResponse } from '@builder-bud/common';

import { api } from './api';

export const contractorSearchSlice = api.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    createContractorSearch: builder.mutation<ContractorSearch, CreateContractorSearchRequest>({
      query: (body) => ({
        url: '/contractor-searches',
        method: 'POST',
        body,
      }),
      transformResponse: (response: CreateContractorSearchResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to create user');
      },
      invalidatesTags: ['users'],
    }),
  }),
});

export const { useCreateContractorSearchMutation } = contractorSearchSlice;
