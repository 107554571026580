import { Modal, ModalClose, ModalDialog, Typography } from '@mui/joy';
import Box from '@mui/joy/Box';
import { Outlet } from 'react-router-dom';

import { closeModal, useAppDispatch, useAppSelector } from '@builder-bud/common-ui';

import AlertComponent from './components/alert.component';
import Header from './components/header.component';
import Sidebar from './components/sidebar.component';

export default function LayoutComponent() {
  const dispatch = useAppDispatch();

  const modalOpen = useAppSelector((state) => state.layout.modalOpen);
  const modalContent = useAppSelector((state) => state.layout.modalContent);

  return (
    <Box sx={{ display: 'flex', minHeight: '100dvh', backgroundColor: 'var(--joy-palette-background-surface)' }}>
      <Header />
      <Sidebar />
      <Box
        component="main"
        sx={{
          m: { xs: 0, md: 3 },
          p: { xs: 1, md: 3 },
          pt: {
            xs: 'calc(12px + var(--Header-height))',
            sm: 'calc(12px + var(--Header-height))',
          },
          borderRadius: { xs: 0, md: 'xl' },
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          minWidth: 0,
          gap: 1,
          backgroundColor: 'var(--joy-palette-common-white)',
        }}
      >
        <Outlet />
        <Box component="footer">
          <Typography level="body-xs" textAlign="center">
            © Builder Bud {new Date().getFullYear()}
          </Typography>
        </Box>
      </Box>
      <Modal
        open={modalOpen}
        onClose={() => {
          dispatch(closeModal());
        }}
        sx={{ zIndex: 20000 }}
      >
        <ModalDialog>
          <ModalClose />
          {modalContent}
        </ModalDialog>
      </Modal>
      <AlertComponent />
    </Box>
  );
}
