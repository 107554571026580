import { UserRecord } from 'firebase-admin/lib/auth/user-record';

import { BaseResponse, WithTimestamps } from './common';
import { FileWithUrl } from './file';
import { ProjectId, ProjectType, UserProjectRole } from './project';

export const USER_ID_PREFIX = 'user_';

export type UserId = `${typeof USER_ID_PREFIX}${string}`;

export function isUserId(id: string): id is UserId {
  return id.startsWith(USER_ID_PREFIX);
}

export type FirebaseId = UserRecord['uid'];

export type User = WithTimestamps<{
  id: UserId;
  firebaseId: FirebaseId;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  stateCode?: string;
  zipCode: string;
  appRole: AppRole;
  license?: string;
  businessName?: string;
  displayName?: string;
  bio?: string;
  website?: string;
  projectTypes?: ProjectType[];
  stripeCustomerId?: string;
  stripeProductId?: string;
  stripeSubscriptionId?: string;
  stripeSubscriptionStatus?: string;
  maxProjects?: number;
  profileImageUrl?: string;
  profileImageThumbnailUrl?: string;
  projects?: {
    id: ProjectId;
    projectRole: UserProjectRole;
    invitedByUserId?: UserId;
  }[];
}>;

export type UserWithFiles = User & {
  files: FileWithUrl[];
};

export type CreateUserRequest = {
  email: string;
  phone: string;
  password: string;
  firstName: string;
  lastName: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  stateCode?: string;
  zipCode: string;
  appRole: AppRole;
  license?: string;
  businessName?: string;
  displayName?: string;
  bio?: string;
  website?: string;
  projectTypes?: ProjectType[];
};

export type UpdateUserRequest = {
  firstName?: string;
  lastName?: string;
  addressLine1?: string | null;
  addressLine2?: string | null;
  city?: string | null;
  stateCode?: string | null;
  zipCode?: string;
  appRole?: AppRole;
  license?: string | null;
  businessName?: string | null;
  displayName?: string | null;
  bio?: string | null;
  website?: string | null;
  projectTypes?: ProjectType[] | null;
};

export type CreateUserResponse = BaseResponse<User>;

export type GetUserResponse = BaseResponse<User>;
export type GetMeResponse = BaseResponse<UserWithFiles>;
export type GetUsersResponse = BaseResponse<User[]>;

export type UpdateUserResponse = BaseResponse<User>;

export enum AppRole {
  Contractor = 'CONTRACTOR',
  Homeowner = 'HOMEOWNER',
  Subcontractor = 'SUBCONTRACTOR',
  ProjectManager = 'PROJECT_MANAGER',
  InteriorDesigner = 'INTERIOR_DESIGNER',
  Other = 'OTHER',
}

export const APP_ROLE_LABELS: { [key in AppRole]: string } = {
  [AppRole.Contractor]: 'Contractor',
  [AppRole.Homeowner]: 'Homeowner',
  [AppRole.Subcontractor]: 'Subcontractor',
  [AppRole.ProjectManager]: 'Project Manager',
  [AppRole.InteriorDesigner]: 'Interior Designer',
  [AppRole.Other]: 'Other',
};

export type CreateDeviceTokenRequest = {
  token: string;
};

export type CreateDeviceTokenResponse = BaseResponse<void>;
export type DeleteDeviceTokenResponse = BaseResponse<void>;

export function isSubAppRole(role: AppRole): boolean {
  return ![AppRole.Homeowner, AppRole.Contractor].includes(role);
}

function isBusinessProfileAttribute(attribute: keyof UpdateUserRequest): boolean {
  return [
    'license',
    'businessName',
    'displayName',
    'bio',
    'website',
    'addressLine1',
    'addressLine2',
    'city',
    'stateCode',
    'projectTypes',
  ].includes(attribute);
}

export function canUpdateProfileAttribute(appRole: AppRole, attribute: keyof UpdateUserRequest): boolean {
  if (isBusinessProfileAttribute(attribute)) {
    return appRole === AppRole.Contractor || isSubAppRole(appRole);
  }

  return true;
}
