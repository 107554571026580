import {
  CreateEventRequest,
  CreateEventResponse,
  CreateFileResponse,
  Event,
  EventId,
  File,
  FileId,
  GetEventResponse,
  GetEventsResponse,
  GetFilesResponse,
  GetSignedDownloadUrlResponse,
  GetSignedUploadUrlResponse,
  ProjectId,
  SignedDownloadUrlData,
  SignedUploadUrlData,
  UpdateEventRequest,
  UpdateEventResponse,
  UpdateFileRequest,
  UpdateFileResponse,
} from '@builder-bud/common';

import { api } from './api';

export const eventsSlice = api.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getEvents: builder.query<Event[], { projectId: ProjectId }>({
      query: (body) => ({
        url: `projects/${body.projectId}/events`,
        method: 'GET',
      }),
      transformResponse: (response: GetEventsResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to get events');
      },
      providesTags: ['project_events'],
    }),
    getEvent: builder.query<Event, { projectId: ProjectId; eventId: EventId }>({
      query: (body) => ({
        url: `/projects/${body.projectId}/events/${body.eventId}`,
        method: 'GET',
      }),
      transformResponse: (response: GetEventResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to get event');
      },
      providesTags: ['project_events'],
    }),
    createEvent: builder.mutation<Event, CreateEventRequest & { projectId: ProjectId }>({
      query: (body) => ({
        url: `projects/${body.projectId}/events`,
        method: 'POST',
        body,
      }),
      transformResponse: (response: CreateEventResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to create event');
      },
      invalidatesTags: ['project_events', 'me_events'],
    }),
    editEvent: builder.mutation<Event, UpdateEventRequest & { id: EventId; projectId: ProjectId }>({
      query: (body) => ({
        url: `projects/${body.projectId}/events/${body.id}`,
        method: 'PATCH',
        body,
      }),
      transformResponse: (response: UpdateEventResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to edit event');
      },
      invalidatesTags: ['project_events', 'me_events'],
    }),
    deleteEvent: builder.mutation<Event, { id: EventId; projectId: ProjectId }>({
      query: (body) => ({
        url: `projects/${body.projectId}/events/${body.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['project_events', 'me_events'],
    }),
    getEventFiles: builder.query<File[], { projectId: ProjectId; eventId?: EventId }>({
      query: (body) => ({
        url: `/projects/${body.projectId}/events/${body.eventId}/files`,
        method: 'GET',
      }),
      transformResponse: (response: GetFilesResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to get files');
      },
      providesTags: ['project_event_files'],
    }),
    getEventFileUploadUrl: builder.mutation<
      SignedUploadUrlData,
      { projectId: string; eventId: EventId; contentType?: string }
    >({
      query: (body) => ({
        url: `/projects/${body.projectId}/events/${body.eventId}/files/upload-url?contentType=${body.contentType}`,
        method: 'GET',
      }),
      transformResponse: (response: GetSignedUploadUrlResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to get message file upload url');
      },
    }),
    createEventFile: builder.mutation<File, { projectId: ProjectId; eventId: EventId }>({
      query: (body) => ({
        url: `/projects/${body.projectId}/events/${body.eventId}/files`,
        method: 'POST',
        body,
      }),
      transformResponse: (response: CreateFileResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to create message file');
      },
      invalidatesTags: ['project_events', 'project_event_files'],
    }),
    getEventFileDownloadUrl: builder.mutation<
      SignedDownloadUrlData,
      { projectId: ProjectId; eventId: EventId; fileId: FileId }
    >({
      query: (body) => ({
        url: `/projects/${body.projectId}/events/${body.eventId}/files/${body.fileId}/download-url`,
        method: 'GET',
      }),
      transformResponse: (response: GetSignedDownloadUrlResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to get message file download url');
      },
    }),
    editEventFile: builder.mutation<
      File,
      UpdateFileRequest & { projectId: ProjectId; eventId: EventId; fileId: FileId }
    >({
      query: (body) => ({
        url: `/projects/${body.projectId}/events/${body.eventId}/files/${body.fileId}`,
        method: 'PATCH',
        body,
      }),
      transformResponse: (response: UpdateFileResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to edit file');
      },
      invalidatesTags: ['project_events', 'project_event_files'],
    }),
    deleteEventFile: builder.mutation<File, { projectId: ProjectId; eventId: EventId; fileId: FileId }>({
      query: (body) => ({
        url: `/projects/${body.projectId}/events/${body.eventId}/files/${body.fileId}`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: ['project_events', 'project_event_files'],
    }),
  }),
});

export const {
  useGetEventsQuery,
  useGetEventQuery,
  useCreateEventMutation,
  useEditEventMutation,
  useDeleteEventMutation,
  useGetEventFilesQuery,
  useGetEventFileUploadUrlMutation,
  useCreateEventFileMutation,
  useGetEventFileDownloadUrlMutation,
  useEditEventFileMutation,
  useDeleteEventFileMutation,
} = eventsSlice;
