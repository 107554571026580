import { EmailAuthProvider, deleteUser, reauthenticateWithCredential, signOut } from '@firebase/auth';
import { Visibility, VisibilityOff, WarningRounded } from '@mui/icons-material';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Input,
  Modal,
  ModalDialog,
  Stack,
} from '@mui/joy';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import {
  AlertObject,
  DeleteAccountFormData,
  DeleteAccountSchemaResolver,
  api,
  selectFCMToken,
  showErrorAlert,
  useAppDispatch,
  useAppSelector,
  useDeleteDeviceTokenMutation,
} from '@builder-bud/common-ui';

import CircularProgressComponent from '../../components/circular-progress.component';
import { auth } from '../login/firebase';

export default function DeleteAccountForm({ hideForm }: { hideForm: React.Dispatch<void> }) {
  const dispatch = useAppDispatch();

  const [deleteDeviceToken] = useDeleteDeviceTokenMutation();

  const fcmToken = useAppSelector(selectFCMToken);

  const [isSaving, setSaving] = useState(false);
  const [secureCurrentPasswordTextEntry, setSecureCurrentPasswordTextEntry] = useState(true);
  const toggleSecureCurrentPasswordTextEntry = () => setSecureCurrentPasswordTextEntry(!secureCurrentPasswordTextEntry);
  const [modalOpen, setModalOpen] = useState(false);
  const [data, setData] = useState<DeleteAccountFormData | null>(null);

  const {
    control,
    handleSubmit,
    formState: { isDirty, errors },
  } = useForm<DeleteAccountFormData>({
    defaultValues: {
      currentPassword: '',
    },
    resolver: DeleteAccountSchemaResolver,
  });

  if (isSaving) return <CircularProgressComponent />;

  async function handleDeleteAccount() {
    try {
      console.log('Updating password');
      setSaving(true);

      const currentUser = auth.currentUser;
      if (!currentUser || !currentUser.email || !data) throw new Error('Current user not found');
      const credential = EmailAuthProvider.credential(currentUser.email, data.currentPassword);

      await reauthenticateWithCredential(currentUser, credential);
      await deleteUser(currentUser);

      if (fcmToken) {
        await deleteDeviceToken({ token: fcmToken });
      }

      signOut(auth).then(() => {
        console.log('User signed out!');
        //Wait for the screens to unmount before resetting the api state otherwise RTK will try to refetch data automatically
        setTimeout(() => {
          dispatch(api.util.resetApiState());
        }, 250);
      });
    } catch (error) {
      console.log(error);
      setModalOpen(false);
      dispatch(showErrorAlert(error as AlertObject));
    } finally {
      setSaving(false);
    }
  }

  async function onSubmit(data: DeleteAccountFormData) {
    setData(data);
    setModalOpen(true);
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid xs={12}>
          <Controller
            control={control}
            name="currentPassword"
            render={({ field: { onChange, onBlur, value } }) => (
              <FormControl error={!!errors.currentPassword}>
                <FormLabel>Current Password</FormLabel>
                <Input
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  type={secureCurrentPasswordTextEntry ? 'password' : ''}
                  endDecorator={
                    secureCurrentPasswordTextEntry ? (
                      <Visibility
                        onClick={() => {
                          toggleSecureCurrentPasswordTextEntry();
                        }}
                      />
                    ) : (
                      <VisibilityOff
                        onClick={() => {
                          toggleSecureCurrentPasswordTextEntry();
                        }}
                      />
                    )
                  }
                />
                {errors.currentPassword && <FormHelperText>{errors.currentPassword.message}</FormHelperText>}
              </FormControl>
            )}
          />
        </Grid>

        <Grid xs={12}>
          <Stack direction="row" sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }} spacing={1}>
            <Button variant="outlined" onClick={() => hideForm()}>
              Cancel
            </Button>
            <Button color="danger" onClick={handleSubmit(onSubmit)} disabled={!isDirty}>
              Delete account
            </Button>
          </Stack>
        </Grid>
      </Grid>

      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
      >
        <ModalDialog variant="outlined" role="alertdialog">
          <DialogTitle>
            <WarningRounded />
            Confirmation
          </DialogTitle>
          <Divider />
          <DialogContent>Are you sure you want to delete your account? This action cannot be undone.</DialogContent>
          <DialogActions>
            <Button variant="solid" color="danger" onClick={() => handleDeleteAccount()}>
              Delete account
            </Button>
            <Button
              variant="plain"
              color="neutral"
              onClick={() => {
                setModalOpen(false);
                hideForm();
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </>
  );
}
