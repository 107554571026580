import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ChatId, MESSAGE_ID_PREFIX, Message } from '@builder-bud/common';

import { GiftedChatMessage, convertMessagetoGiftedChatMessage } from './messages';
import { RootState } from './store';

type GiftedChatState = {
  activeChatId: ChatId | null;
  messages: GiftedChatMessage[];
};

const initialState: GiftedChatState = {
  activeChatId: null,
  messages: [],
};

export const giftedChatSlice = createSlice({
  name: 'giftedChat',
  initialState,
  reducers: {
    setActiveChatId: (state, action: PayloadAction<ChatId | null>) => {
      state.activeChatId = action.payload;
    },
    setGiftedChatMessages: (state, action: PayloadAction<GiftedChatMessage[]>) => {
      state.messages = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action): action is PayloadAction<Message[] | null> => action.type === 'api/executeQuery/fulfilled',
      (state, action) => {
        if (Array.isArray(action.payload) && action.payload.some((m) => m.id?.startsWith(MESSAGE_ID_PREFIX))) {
          state.messages = action.payload.map((message: Message) => {
            return convertMessagetoGiftedChatMessage(message);
          });
        }
      }
    );
  },
});

export const { setActiveChatId, setGiftedChatMessages } = giftedChatSlice.actions;
