import {
  CreateDailyLogRequest,
  CreateDailyLogResponse,
  CreateFileResponse,
  DailyLog,
  DailyLogId,
  File,
  FileId,
  GetDailyLogResponse,
  GetDailyLogsResponse,
  GetFilesResponse,
  GetSignedDownloadUrlResponse,
  GetSignedUploadUrlResponse,
  ProjectId,
  SignedDownloadUrlData,
  SignedUploadUrlData,
  UpdateDailyLogRequest,
  UpdateDailyLogResponse,
  UpdateFileRequest,
  UpdateFileResponse,
} from '@builder-bud/common';

import { api } from './api';

export const dailyLogsSlice = api.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getDailyLogs: builder.query<DailyLog[], { projectId: ProjectId }>({
      query: (body) => ({
        url: `projects/${body.projectId}/daily-logs`,
        method: 'GET',
      }),
      transformResponse: (response: GetDailyLogsResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to get Daily Logs');
      },
      providesTags: ['project_daily_logs'],
    }),
    getDailyLog: builder.query<DailyLog, { projectId: ProjectId; dailyLogId: DailyLogId }>({
      query: (body) => ({
        url: `/projects/${body.projectId}/daily-logs/${body.dailyLogId}`,
        method: 'GET',
      }),
      transformResponse: (response: GetDailyLogResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to get Daily Log');
      },
      providesTags: ['project_daily_logs'],
    }),
    createDailyLog: builder.mutation<DailyLog, CreateDailyLogRequest & { projectId: ProjectId }>({
      query: (body) => ({
        url: `projects/${body.projectId}/daily-logs`,
        method: 'POST',
        body,
      }),
      transformResponse: (response: CreateDailyLogResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to create Daily Log');
      },
      invalidatesTags: ['project_daily_logs'],
    }),
    editDailyLog: builder.mutation<DailyLog, UpdateDailyLogRequest & { id: DailyLogId; projectId: ProjectId }>({
      query: (body) => ({
        url: `projects/${body.projectId}/daily-logs/${body.id}`,
        method: 'PATCH',
        body,
      }),
      transformResponse: (response: UpdateDailyLogResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to edit Daily Log');
      },
      invalidatesTags: ['project_daily_logs'],
    }),
    deleteDailyLog: builder.mutation<DailyLog, { id: DailyLogId; projectId: ProjectId }>({
      query: (body) => ({
        url: `projects/${body.projectId}/daily-logs/${body.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['project_daily_logs'],
    }),

    getDailyLogFiles: builder.query<File[], { projectId: ProjectId; dailyLogId?: DailyLogId }>({
      query: (body) => ({
        url: `/projects/${body.projectId}/daily-logs/${body.dailyLogId}/files`,
        method: 'GET',
      }),
      transformResponse: (response: GetFilesResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to get files');
      },
      providesTags: ['project_daily_log_files'],
    }),
    getDailyLogFileUploadUrl: builder.mutation<
      SignedUploadUrlData,
      { projectId: string; dailyLogId: DailyLogId; contentType?: string }
    >({
      query: (body) => ({
        url: `/projects/${body.projectId}/daily-logs/${body.dailyLogId}/files/upload-url?contentType=${body.contentType}`,
        method: 'GET',
      }),
      transformResponse: (response: GetSignedUploadUrlResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to get message file upload url');
      },
    }),
    createDailyLogFile: builder.mutation<File, { projectId: ProjectId; dailyLogId: DailyLogId }>({
      query: (body) => ({
        url: `/projects/${body.projectId}/daily-logs/${body.dailyLogId}/files`,
        method: 'POST',
        body,
      }),
      transformResponse: (response: CreateFileResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to create message file');
      },
      invalidatesTags: ['project_daily_logs', 'project_daily_log_files'],
    }),
    getDailyLogFileDownloadUrl: builder.mutation<
      SignedDownloadUrlData,
      { projectId: ProjectId; dailyLogId: DailyLogId; fileId: FileId }
    >({
      query: (body) => ({
        url: `/projects/${body.projectId}/daily-logs/${body.dailyLogId}/files/${body.fileId}/download-url`,
        method: 'GET',
      }),
      transformResponse: (response: GetSignedDownloadUrlResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to get message file download url');
      },
    }),

    editDailyLogFile: builder.mutation<
      File,
      UpdateFileRequest & { projectId: ProjectId; dailyLogId: DailyLogId; fileId: FileId }
    >({
      query: (body) => ({
        url: `/projects/${body.projectId}/daily-logs/${body.dailyLogId}/files/${body.fileId}`,
        method: 'PATCH',
        body,
      }),
      transformResponse: (response: UpdateFileResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to edit file');
      },
      invalidatesTags: ['project_daily_logs', 'project_daily_log_files'],
    }),

    deleteDailyLogFile: builder.mutation<File, { projectId: ProjectId; dailyLogId: DailyLogId; fileId: FileId }>({
      query: (body) => ({
        url: `/projects/${body.projectId}/daily-logs/${body.dailyLogId}/files/${body.fileId}`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: ['project_daily_logs', 'project_daily_log_files'],
    }),
  }),
});

export const {
  useGetDailyLogsQuery,
  useGetDailyLogQuery,
  useCreateDailyLogMutation,
  useEditDailyLogMutation,
  useDeleteDailyLogMutation,
  useGetDailyLogFilesQuery,
  useGetDailyLogFileUploadUrlMutation,
  useCreateDailyLogFileMutation,
  useGetDailyLogFileDownloadUrlMutation,
  useEditDailyLogFileMutation,
  useDeleteDailyLogFileMutation,
} = dailyLogsSlice;
