import CssBaseline from '@mui/joy/CssBaseline';
import { CssVarsProvider } from '@mui/joy/styles';
import * as Sentry from '@sentry/react';
import { Provider } from 'react-redux';

import { store } from '@builder-bud/common-ui';

import Screens from './screens';
import { theme } from './theme/builder-bud.theme';

Sentry.init({
  dsn: 'https://be25593baf228334d96328284a13c8ef@o4508291938320384.ingest.us.sentry.io/4508292068212736',
  integrations: [
    //Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  enabled: process.env['NX_PUBLIC_ENVIRONMENT'] !== 'local',
  environment: process.env['NX_PUBLIC_ENVIRONMENT'],
  // Tracing
  //tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  //tracePropagationTargets: ["localhost:3000/api", "premium-fuze-425619-s8.web.app/api", "app.builderbud.com/api"],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export const App = () => {
  return (
    <Provider store={store}>
      <CssVarsProvider disableTransitionOnChange theme={theme}>
        <CssBaseline />
        <Screens />
      </CssVarsProvider>
    </Provider>
  );
};

export default App;
