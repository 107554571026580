import { Typography, TypographySystem } from '@mui/joy';

import { ProjectId } from '@builder-bud/common';
import { useGetProjectsQuery } from '@builder-bud/common-ui';

function ProjectNameComponent({
  projectId,
  level = 'body-xs',
  style = {},
}: {
  projectId: ProjectId;
  level?: keyof TypographySystem | 'inherit' | undefined;
  style?: React.CSSProperties | undefined;
}) {
  const { data: projects = [] } = useGetProjectsQuery();
  const project = projects.find((project) => project.id === projectId);

  return (
    <Typography level={level} style={style}>
      {project?.name}
    </Typography>
  );
}

export default ProjectNameComponent;
