import { ChevronRightRounded, HomeRounded } from '@mui/icons-material';
import { Box, Breadcrumbs, Link, Typography } from '@mui/joy';

import { ProjectId } from '@builder-bud/common';
import { getUserOptions, useAppSelector, useCreateChatMutation, useGetUsersQuery } from '@builder-bud/common-ui';

import CircularProgressComponent from '../../components/circular-progress.component';
import ErrorLoadingComponent from '../../components/error-loading.component';
import ProjectNameComponent from '../../components/project-name';
import { useRequiredParams } from '../../utils';
import ChatForm from './chat.form';

export default function ChatCreateScreen() {
  const { projectId } = useRequiredParams<{ projectId: ProjectId }>();
  const [createChat] = useCreateChatMutation();

  const me = useAppSelector((state) => state.auth.me);

  const { data: users = [], isLoading, isFetching, isError, error } = useGetUsersQuery();

  if (isLoading || isFetching) return <CircularProgressComponent />;
  if (isError) return <ErrorLoadingComponent error={error} />;

  const chat = {
    projectId,
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={<ChevronRightRounded fontSize="small" />}
          sx={{ pl: 0 }}
        >
          <Link underline="none" color="neutral" href="/" aria-label="Home">
            <HomeRounded />
          </Link>
          <Link underline="hover" color="neutral" href="/projects" fontSize={12} fontWeight={500}>
            Projects
          </Link>
          <Link underline="hover" color="neutral" href={`/projects/${chat.projectId}`} fontSize={12} fontWeight={500}>
            <ProjectNameComponent projectId={chat.projectId} />
          </Link>
          <Link
            underline="hover"
            color="neutral"
            href={`/projects/${chat.projectId}/chats`}
            fontSize={12}
            fontWeight={500}
          >
            Chats
          </Link>
          <Typography color="primary" fontWeight={500} fontSize={12}>
            New chat
          </Typography>
        </Breadcrumbs>
      </Box>
      <Box
        sx={{
          display: 'flex',
          mb: 1,
          gap: 1,
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'start', sm: 'center' },
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Typography level="h2" component="h1">
          New chat
        </Typography>
      </Box>

      <ChatForm chat={chat} saveChat={createChat} users={getUserOptions(users, chat.projectId, me ? [me.id] : [])} />
    </>
  );
}
