import { Task, UserId, UserProjectRole, isNonHomeownerProjectRole } from '@builder-bud/common';

export function canUserCreateTask(userProjectRole: UserProjectRole): boolean {
  return isNonHomeownerProjectRole(userProjectRole);
}

export function canUserReadTask(task: Task, userId: UserId): boolean {
  return [task.createdByUserId, ...task.assignedToUserIds].includes(userId);
}

export function canUserUpdateTask(task: Task, userId: UserId): boolean {
  return [task.createdByUserId, ...task.assignedToUserIds].includes(userId);
}

export function canUserAssignTask(task: Task, userId: UserId): boolean {
  return task.createdByUserId === userId;
}

export function canUserDeleteTask(task: Task, userId: UserId): boolean {
  return task.createdByUserId === userId;
}
