import { ChevronRightRounded, HomeRounded } from '@mui/icons-material';
import { Box, Breadcrumbs, Card, Link, Theme, Typography, useTheme } from '@mui/joy';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ProjectId } from '@builder-bud/common';
import {
  AlertObject,
  InvitationsFormData,
  showErrorAlert,
  showInfoAlert,
  useAppDispatch,
  useCreateInvitationMutation,
} from '@builder-bud/common-ui';

import CircularProgressComponent from '../../components/circular-progress.component';
import NotFoundComponent from '../../components/not-found.component';
import { useRequiredParams } from '../../utils';
import InvitationsForm from './invitations.form';

function InvitePeopleScreen() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const styles = makeStyles(useTheme());
  const [createInvitation] = useCreateInvitationMutation();

  const { projectId } = useRequiredParams<{ projectId: ProjectId }>();

  const [saving, setSaving] = useState(false);

  if (!projectId) return <NotFoundComponent />;

  async function sendInvitations(data: InvitationsFormData) {
    setSaving(true);
    try {
      const invites = data.invites ? data.invites : [];
      for (const invite of invites) {
        await createInvitation({
          projectId: projectId,
          email: invite.email,
          projectRole: invite.projectRole,
          isConfirmed: true,
        }).unwrap();
      }
      dispatch(showInfoAlert('Successfully sent invitations'));
      navigate(-1);
    } catch (error) {
      console.log(error);
      dispatch(showErrorAlert(error as AlertObject));
    } finally {
      setSaving(false);
    }
  }

  if (saving) return <CircularProgressComponent />;

  return (
    <Box flex={1}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={<ChevronRightRounded fontSize="small" />}
          sx={{ pl: 0 }}
        >
          <Link underline="none" color="neutral" href="/" aria-label="Home">
            <HomeRounded />
          </Link>
          <Link underline="hover" color="neutral" href="/projects" fontSize={12} fontWeight={500}>
            Projects
          </Link>
          <Link underline="hover" color="neutral" href={`/projects/${projectId}`} fontSize={12} fontWeight={500}>
            {projectId}
          </Link>
          <Typography color="primary" fontWeight={500} fontSize={12}>
            Invite people
          </Typography>
        </Breadcrumbs>
      </Box>
      <Box
        sx={{
          display: 'flex',
          mb: 1,
          gap: 1,
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'start', sm: 'center' },
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Typography level="h2" component="h1">
          Invite people to your project
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center" my={4}>
        <Card sx={{ display: 'flex', flex: 1, maxWidth: 800 }}>
          <img src="../../../assets/line-3-tools-row.png" style={styles.image} alt="" />
          <InvitationsForm onSubmit={sendInvitations} onCancel={() => navigate(-1)} />
        </Card>
      </Box>
    </Box>
  );
}

export default InvitePeopleScreen;

const makeStyles = (theme: Theme) => {
  return {
    container: {
      flex: 1,
      justifyContent: 'center',
      padding: 16,
    },
    image: {
      height: 240,
    },
  };
};
