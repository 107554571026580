import { MessagePayload } from '@firebase/messaging';
import { GiftedChat } from 'react-native-gifted-chat';

import { ChatId, EventId, NotificationType, ProjectId, TaskId, UserNotification } from '@builder-bud/common';

import { showMessageAlert, showNotificationAlert } from './alerts.slice';
import { api } from './api';
import { setGiftedChatMessages } from './giftedChat.slice';
import { GiftedChatMessage, convertMessagetoGiftedChatMessage } from './messages';
import { AppDispatch } from './store';

export function processRemoteMessage(
  remoteMessage: MessagePayload,
  activeChatId: ChatId | null,
  giftedChatMessages: GiftedChatMessage[],
  dispatch: AppDispatch
) {
  if (remoteMessage.data) {
    if (remoteMessage.data['message']) {
      if (remoteMessage.data['chatId'] === activeChatId) {
        const message = JSON.parse(remoteMessage.data['message'] as string);
        const giftedChatMessage = convertMessagetoGiftedChatMessage(message);
        dispatch(setGiftedChatMessages(GiftedChat.prepend(giftedChatMessages, [giftedChatMessage], true)));
      } else {
        const message = JSON.parse(remoteMessage.data['message'] as string);
        message.projectId = remoteMessage.data['projectId'];
        message.projectName = remoteMessage.data['projectName'];
        message.chatName = remoteMessage.data['chatName'];
        message.chatId = remoteMessage.data['chatId'];
        dispatch(api.util.invalidateTags(['project_chats', 'me_chats']));
        dispatch(showMessageAlert(message));
      }
    } else if (remoteMessage.notification) {
      const notification = remoteMessage.notification as UserNotification & {
        type: NotificationType;
        projectName: string;
        projectId: ProjectId;
        taskId?: TaskId;
        eventId?: EventId;
      };
      notification.type = remoteMessage.data['type'] as NotificationType;
      notification.projectId = remoteMessage.data['projectId'] as ProjectId;
      notification.projectName = remoteMessage.data['projectName'] as string;
      notification.taskId = remoteMessage.data['taskId'] as TaskId;
      notification.eventId = remoteMessage.data['eventId'] as EventId;
      dispatch(showNotificationAlert(notification));

      switch (notification?.type) {
        case NotificationType.ProjectInvitationReceived:
        case NotificationType.ProjectInvitationAccepted:
          dispatch(api.util.invalidateTags(['invitations', 'projects', 'users']));
          break;
        case NotificationType.ProjectStatusChange:
          dispatch(api.util.invalidateTags(['projects']));
          break;
        case NotificationType.TaskNewAssignment:
        case NotificationType.TaskStatusChange:
          dispatch(api.util.invalidateTags(['project_tasks', 'me_tasks']));
          break;
        case NotificationType.EventNewAssignment:
          dispatch(api.util.invalidateTags(['project_events', 'me_events']));
          break;
      }
    }
  }
}

//TODO: need to clean up these types
export function handleRemoteMessageMobileTap(notification: any, navigation: any) {
  switch (notification?.type) {
    case NotificationType.ProjectInvitationReceived:
      navigation.navigate('SettingsStack', { screen: 'Invitations', initial: false });
      break;
    case NotificationType.ProjectInvitationAccepted:
      navigation.navigate('SettingsStack', { screen: 'Invitations', initial: false });
      break;
    case NotificationType.ProjectStatusChange:
      navigation.navigate('ProjectsStack', {
        screen: 'ProjectDetails',
        params: { projectId: notification.projectId, initial: false },
      });
      break;
    case NotificationType.TaskStatusChange:
      navigation.navigate('ProjectsStack', {
        screen: 'TaskDetails',
        params: { projectId: notification.projectId, taskId: notification.taskId, initial: false },
      });
      break;
    case NotificationType.TaskNewAssignment:
      navigation.navigate('ProjectsStack', {
        screen: 'TaskDetails',
        params: { projectId: notification.projectId, taskId: notification.taskId, initial: false },
      });
      break;
    case NotificationType.EventNewAssignment:
      navigation.navigate('ProjectsStack', {
        screen: 'EventDetails',
        params: { projectId: notification.projectId, eventId: notification.eventId, initial: false },
      });
      break;
    case NotificationType.ChatMessageReceived:
      navigation.navigate('ChatDetails', {
        projectId: notification.projectId,
        chatId: notification.chatId,
        initial: false,
      });
      break;
  }
}

//TODO: need to clean up these types
export function handleRemoteMessageWebClick(notification: any, navigate: any) {
  switch (notification?.type) {
    case NotificationType.ProjectInvitationReceived:
      navigate(`/settings/invitations`);
      break;
    case NotificationType.ProjectInvitationAccepted:
      navigate(`/settings/invitations`);
      break;
    case NotificationType.ProjectStatusChange:
      navigate(`/projects/${notification?.projectId}`);
      break;
    case NotificationType.TaskStatusChange:
      navigate(`/projects/${notification?.projectId}/tasks/${notification?.taskId}`);
      break;
    case NotificationType.TaskNewAssignment:
      navigate(`/projects/${notification?.projectId}/tasks/${notification?.taskId}`);
      break;
    case NotificationType.EventNewAssignment:
      navigate(`/projects/${notification?.projectId}/events/${notification?.eventId}`);
      break;
    //Don't need to handle this case because its handled at a different level in the alert component
    //case NotificationType.ChatMessageReceived:
    //  break;
  }
}
