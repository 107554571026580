import { CreateUserRequest, CreateUserResponse, GetUsersResponse, ProjectId, User, UserId } from '@builder-bud/common';

import { api } from './api';

export type UserOption = { value: UserId; label: string };

export const usersSlice = api.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getUsers: builder.query<User[], void>({
      query: () => ({
        url: '/users/',
        method: 'Get',
      }),
      transformResponse: (response: GetUsersResponse) => {
        if ('data' in response) {
          return response.data;
        }

        throw new Error('Failed to get users');
      },
      providesTags: ['users'],
    }),
    createUser: builder.mutation<User, CreateUserRequest>({
      query: (body) => ({
        url: '/users',
        method: 'POST',
        body,
      }),
      transformResponse: (response: CreateUserResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to create user');
      },
      invalidatesTags: ['users'],
    }),
  }),
});

export const { useGetUsersQuery, useCreateUserMutation } = usersSlice;

export function getUserOptions(users: User[], projectId: ProjectId, filterIds: UserId[] = []) {
  const filteredUsers = users.filter((user) => !filterIds.includes(user.id));
  const projectUsers = filteredUsers.filter((user) => user.projects?.find((project) => project.id === projectId));
  return projectUsers.map((user) => ({ value: user.id, label: `${user.firstName} ${user.lastName}` }));
}
