import {
  CreateFileResponse,
  DailyLogId,
  EventId,
  File,
  FileId,
  GetFilesResponse,
  GetSignedDownloadUrlResponse,
  GetSignedUploadUrlResponse,
  ProjectId,
  SignedDownloadUrlData,
  SignedUploadUrlData,
  TaskId,
  UpdateFileRequest,
  UpdateFileResponse,
} from '@builder-bud/common';

import { api } from './api';
import { useGetDailyLogFilesQuery } from './daily-log.slice';
import { useGetEventFilesQuery } from './events.slice';

export const filesSlice = api.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getFiles: builder.query<File[], { projectId: ProjectId; taskId?: TaskId }>({
      query: (body) => ({
        url: body.taskId
          ? `/projects/${body.projectId}/tasks/${body.taskId}/files`
          : `projects/${body.projectId}/files`,
        method: 'GET',
      }),
      transformResponse: (response: GetFilesResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to get files');
      },
      providesTags: ['project_files'],
    }),
    getFileUploadUrl: builder.mutation<
      SignedUploadUrlData,
      { projectId: string; taskId?: string; contentType?: string }
    >({
      query: (body) => ({
        url: body.taskId
          ? `/projects/${body.projectId}/tasks/${body.taskId}/files/upload-url?contentType=${body.contentType}`
          : `/projects/${body.projectId}/files/upload-url?contentType=${body.contentType}`,
        method: 'GET',
      }),
      transformResponse: (response: GetSignedUploadUrlResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to get file upload url');
      },
    }),
    createFile: builder.mutation<File, { projectId: ProjectId; taskId?: TaskId }>({
      query: (body) => ({
        url: body.taskId
          ? `/projects/${body.projectId}/tasks/${body.taskId}/files`
          : `/projects/${body.projectId}/files`,
        method: 'POST',
        body,
      }),
      transformResponse: (response: CreateFileResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to create file');
      },
      invalidatesTags: ['projects', 'project_files', 'project_tags'],
    }),
    getFileDownloadUrl: builder.mutation<
      SignedDownloadUrlData,
      { projectId: ProjectId; fileId: FileId; taskId?: TaskId }
    >({
      query: (body) => ({
        url: body.taskId
          ? `/projects/${body.projectId}/tasks/${body.taskId}/files/${body.fileId}/download-url`
          : `/projects/${body.projectId}/files/${body.fileId}/download-url`,
        method: 'GET',
      }),
      transformResponse: (response: GetSignedDownloadUrlResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to get file download url');
      },
    }),
    editFile: builder.mutation<File, UpdateFileRequest & { projectId: ProjectId; taskId?: TaskId; fileId: FileId }>({
      query: (body) => ({
        url: body.taskId
          ? `/projects/${body.projectId}/tasks/${body.taskId}/files/${body.fileId}`
          : `/projects/${body.projectId}/files/${body.fileId}`,
        method: 'PATCH',
        body,
      }),
      transformResponse: (response: UpdateFileResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to edit file');
      },
      invalidatesTags: ['projects', 'project_files', 'project_tags'],
    }),
    deleteFile: builder.mutation<File, { projectId: ProjectId; taskId?: TaskId; fileId: FileId }>({
      query: (body) => ({
        url: body.taskId
          ? `/projects/${body.projectId}/tasks/${body.taskId}/files/${body.fileId}`
          : `/projects/${body.projectId}/files/${body.fileId}`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: ['projects', 'project_files', 'project_tags'],
    }),
  }),
});

export const {
  useGetFilesQuery,
  useGetFileUploadUrlMutation,
  useCreateFileMutation,
  useGetFileDownloadUrlMutation,
  useEditFileMutation,
  useDeleteFileMutation,
} = filesSlice;

export function useGetObjectFilesQuery(
  projectId: ProjectId,
  taskId?: TaskId,
  eventId?: EventId,
  dailyLogId?: DailyLogId
) {
  if (eventId) {
    return useGetEventFilesQuery({ projectId, eventId });
  } else if (dailyLogId) {
    return useGetDailyLogFilesQuery({ projectId, dailyLogId });
  } else {
    return useGetFilesQuery({ projectId, taskId });
  }
}
