import {
  Button,
  Card,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Stack,
  Textarea,
  Typography,
} from '@mui/joy';
import { Dispatch, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { CreateProjectRequest, ProjectId } from '@builder-bud/common';
import {
  AlertObject,
  ContractorSteps,
  ProjectFormData,
  ProjectSchemaResolver,
  getDefaultProjectFormValues,
  getProjectSubmitData,
  showErrorAlert,
  useAppDispatch,
  useCreateProjectMutation,
} from '@builder-bud/common-ui';

import GooglePlacesAutocomplete from '../../../components/google-places-autocomplete.component';

export default function ContractorCreateProjectStep({
  setStep,
  setProjectId,
}: {
  setStep: Dispatch<ContractorSteps>;
  setProjectId: Dispatch<ProjectId>;
}) {
  const dispatch = useAppDispatch();
  const [createProject] = useCreateProjectMutation();
  const [saving, setSaving] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<ProjectFormData>({
    defaultValues: getDefaultProjectFormValues({}),
    resolver: ProjectSchemaResolver,
  });

  async function onSubmit(data: ProjectFormData) {
    setSaving(true);
    try {
      const project = await createProject(getProjectSubmitData(data) as CreateProjectRequest).unwrap();
      setProjectId(project.id);
      setStep(ContractorSteps.contractorPayment);
    } catch (error) {
      console.log(error);
      dispatch(showErrorAlert(error as AlertObject));
    } finally {
      setSaving(false);
    }
  }

  return (
    <Card style={{ backgroundColor: 'var(--joy-palette-common-white)' }}>
      <Stack sx={{ mb: 1 }}>
        <Typography level="title-md">Create your project!</Typography>
      </Stack>
      <Divider />
      <Stack rowGap={2}>
        <Controller
          control={control}
          name="name"
          render={({ field: { onChange, onBlur, value: controlValue } }) => (
            <FormControl error={!!errors.name} required>
              <FormLabel>Project name</FormLabel>
              <Input value={controlValue} onBlur={onBlur} onChange={onChange} />
              {errors.name && <FormHelperText>{errors.name.message}</FormHelperText>}
            </FormControl>
          )}
        />

        <Controller
          control={control}
          name="description"
          render={({ field: { onChange, onBlur, value: controlValue } }) => (
            <FormControl error={!!errors.description}>
              <FormLabel>Description</FormLabel>
              <Textarea value={controlValue} onBlur={onBlur} onChange={onChange} minRows={2} />
              {errors.description && <FormHelperText>{errors.description.message}</FormHelperText>}
            </FormControl>
          )}
        />

        <Controller
          control={control}
          name="displayAddress"
          render={({ field: { onChange, onBlur, value: controlValue } }) => (
            <FormControl error={!!errors.displayAddress} required>
              <FormLabel>Address</FormLabel>
              <GooglePlacesAutocomplete value={controlValue} setValue={setValue} onChange={onChange} />
              {errors.displayAddress && <FormHelperText>{errors.displayAddress.message}</FormHelperText>}
            </FormControl>
          )}
        />
      </Stack>

      <Button variant="solid" onClick={handleSubmit(onSubmit)} style={styles.button} disabled={saving}>
        Next
      </Button>
    </Card>
  );
}

const styles = {
  button: {
    width: 100,
    alignSelf: 'flex-end',
  },
};
