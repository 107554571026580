import {
  Button,
  Card,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Option,
  Select,
  Stack,
  Textarea,
  Typography,
} from '@mui/joy';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import {
  AlertObject,
  HomeownerProfileFormData,
  HomeownerProfileSchemaResolver,
  PROJECT_TYPE_OPTIONS,
  getDefaultHomeownerProfileFormValues,
  getHomeownerProfileSubmitData,
  setIsOnboarding,
  showErrorAlert,
  useAppDispatch,
  useCreateContractorSearchMutation,
} from '@builder-bud/common-ui';

export default function HomeownerGiveContractorInfoStep() {
  const dispatch = useAppDispatch();
  const [createContractorSearch] = useCreateContractorSearchMutation();
  const [saving, setSaving] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<HomeownerProfileFormData>({
    defaultValues: getDefaultHomeownerProfileFormValues(),
    resolver: HomeownerProfileSchemaResolver,
  });

  async function onSubmit(data: HomeownerProfileFormData) {
    setSaving(true);
    try {
      await createContractorSearch(getHomeownerProfileSubmitData(data)).unwrap();
      dispatch(setIsOnboarding(false));
    } catch (error) {
      console.log(error);
      dispatch(showErrorAlert(error as AlertObject));
    } finally {
      setSaving(false);
    }
  }

  return (
    <Card style={{ backgroundColor: 'var(--joy-palette-common-white)' }}>
      <Stack sx={{ mb: 1 }}>
        <Typography level="title-md">Give your contractor some information!</Typography>
      </Stack>
      <Divider />
      <Stack rowGap={2}>
        <Controller
          control={control}
          name="zipCode"
          render={({ field: { onChange, onBlur, value: controlValue } }) => (
            <FormControl error={!!errors.zipCode} required>
              <FormLabel>Zip Code</FormLabel>
              <Input value={controlValue} onBlur={onBlur} onChange={onChange} />
              {errors.zipCode && <FormHelperText>{errors.zipCode.message}</FormHelperText>}
            </FormControl>
          )}
        />

        <Controller
          control={control}
          name="projectType"
          render={({ field: { onChange, onBlur, value: controlValue } }) => (
            <FormControl error={!!errors.projectType} required>
              <FormLabel>Project Type</FormLabel>
              <Select
                value={controlValue}
                onChange={(e, newValue) => {
                  onChange(newValue);
                }}
              >
                {PROJECT_TYPE_OPTIONS.map((option, i) => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
              {errors.projectType && <FormHelperText>{errors.projectType.message}</FormHelperText>}
            </FormControl>
          )}
        />

        <Controller
          control={control}
          name="projectDescription"
          render={({ field: { onChange, onBlur, value: controlValue } }) => (
            <FormControl error={!!errors.projectDescription}>
              <FormLabel>Project Description</FormLabel>
              <Textarea value={controlValue} onBlur={onBlur} onChange={onChange} minRows={2} />
              {errors.projectDescription && <FormHelperText>{errors.projectDescription.message}</FormHelperText>}
            </FormControl>
          )}
        />
      </Stack>

      <Button variant="solid" onClick={handleSubmit(onSubmit)} style={styles.button} disabled={saving}>
        Next
      </Button>
    </Card>
  );
}

const styles = {
  button: {
    width: 100,
    alignSelf: 'flex-end',
  },
};
