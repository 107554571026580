import { Stack, Typography } from '@mui/joy';
import { useDropzone } from 'react-dropzone';

import { closeModal, useAppDispatch } from '@builder-bud/common-ui';

export default function ChatFileUploadModal({ setFile }: { setFile: any }) {
  const dispatch = useAppDispatch();
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: {
      'image/*': ['.jpeg', '.png'],
      'video/*': ['.mp4'],
    },
  });

  function onDrop(acceptedFiles: File[]) {
    if (acceptedFiles.length > 0) {
      setFile(acceptedFiles[0]);
      dispatch(closeModal());
    }
  }

  return (
    <Stack sx={{ margin: 2, gap: 2, padding: 8, borderRadius: 'sm', border: '1px dashed ' }} {...getRootProps()}>
      <Typography>Select a photo or video</Typography>
      <Typography level="body-sm">Drag & drop or click here</Typography>
      <input {...getInputProps()} />
    </Stack>
  );
}
