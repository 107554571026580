import * as Sentry from '@sentry/react';
import { getToken, onMessage } from 'firebase/messaging';
import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import {
  processRemoteMessage,
  selectActiveChatId,
  selectGiftedChatMessages,
  selectIsHomeownerAppRole,
  setAuth,
  setAuthToken,
  setAuthUser,
  setFCMToken,
  useAppDispatch,
  useAppSelector,
  useCreateDeviceTokenMutation,
  useLazyGetMeQuery,
} from '@builder-bud/common-ui';

import ErrorScreen from './error.screen';
import LayoutNoSidebarComponent from './layout-no-sidebar.component';
import LayoutComponent from './layout.component';
import CalendarScreen from './modules/calendars/calendar.screen';
import ChatCreateScreen from './modules/chats/chat-create.screen';
import ChatDetailsScreen from './modules/chats/chat-details.screen';
import ChatMessagesScreen from './modules/chats/chat-messages.screen';
import ChatsScreen from './modules/chats/chats.screen';
import ContractCreateScreen from './modules/contracts/contract-create.screen';
import ContractDetailsScreen from './modules/contracts/contract-details.screen';
import DailyLogCreateScreen from './modules/daily-logs/daily-log-create.screen';
import DailyLogDetailsScreen from './modules/daily-logs/daily-log-details.screen';
import DashboardScreen from './modules/dashboard/dashboard.screen';
import EventCreateScreen from './modules/events/event-create.screen';
import EventDetailsScreen from './modules/events/event-details.screen';
import AuthLoadingScreen from './modules/login/auth-loading.screen';
import ContactUsFullScreen from './modules/login/contact-us-full.screen';
import ContactUsScreen from './modules/login/contact-us.screen';
import { VAPID_KEY, auth, messaging } from './modules/login/firebase';
import LoginScreen from './modules/login/login.screen';
import ContractorOnboardingScreen from './modules/onboarding/contractor/contractor-onboarding.screen';
import HomeownerOnboardingScreen from './modules/onboarding/homeowner/homeowner-onboarding.screen';
import OnboardingScreen from './modules/onboarding/onboarding.screen';
import InvitePeopleScreen from './modules/projects/invite-people.screen';
import ProjectCreateScreen from './modules/projects/project-create.screen';
import ProjectDetailsScreen from './modules/projects/project-details.screen';
import ProjectsScreen from './modules/projects/projects.screen';
import AppDownloadScreen from './modules/settings/app-download.screen';
import SettingsScreen from './modules/settings/settings.screen';
import TaskCreateScreen from './modules/tasks/task-create.screen';
import TaskDetailsScreen from './modules/tasks/task-details.screen';

const loginRoutes = (
  <Route path="/" Component={LayoutNoSidebarComponent} errorElement={<ErrorScreen />}>
    <Route path="" Component={LoginScreen} />
    <Route path="onboarding" Component={OnboardingScreen} />
    <Route path="contactUs" Component={ContactUsFullScreen} />
    <Route path="*" Component={LoginScreen} />
  </Route>
);

const HomeownerOnboardingRoutes = (
  <Route path="/" Component={LayoutNoSidebarComponent} errorElement={<ErrorScreen />}>
    <Route path="*" Component={HomeownerOnboardingScreen} />
  </Route>
);

const ContractorOnboardingRoutes = (
  <Route path="/" Component={LayoutNoSidebarComponent} errorElement={<ErrorScreen />}>
    <Route path="*" Component={ContractorOnboardingScreen} />
  </Route>
);

const routes = (
  <Route path="/" Component={LayoutComponent} errorElement={<ErrorScreen />}>
    <Route path="" Component={DashboardScreen} />
    <Route path="projects" Component={ProjectsScreen} />
    <Route path="projects/new" Component={ProjectCreateScreen} />
    <Route path="projects/:projectId" Component={ProjectDetailsScreen} />
    <Route path="projects/:projectId/members" Component={ProjectDetailsScreen} />
    <Route path="projects/:projectId/tasks" Component={ProjectDetailsScreen} />
    <Route path="projects/:projectId/events" Component={ProjectDetailsScreen} />
    <Route path="projects/:projectId/chats" Component={ProjectDetailsScreen} />
    <Route path="projects/:projectId/files" Component={ProjectDetailsScreen} />
    <Route path="projects/:projectId/contracts" Component={ProjectDetailsScreen} />
    <Route path="projects/:projectId/dailyLogs" Component={ProjectDetailsScreen} />
    <Route path="projects/:projectId/plans" Component={ProjectDetailsScreen} />
    <Route path="projects/:projectId/specs" Component={ProjectDetailsScreen} />
    <Route path="projects/:projectId/tasks/new" Component={TaskCreateScreen} />
    <Route path="projects/:projectId/tasks/:taskId" Component={TaskDetailsScreen} />
    <Route path="projects/:projectId/tasks/:taskId/punchlist" Component={TaskDetailsScreen} />
    <Route path="projects/:projectId/tasks/:taskId/files" Component={TaskDetailsScreen} />
    <Route path="projects/:projectId/contracts/new" Component={ContractCreateScreen} />
    <Route path="projects/:projectId/contracts/:contractId" Component={ContractDetailsScreen} />
    <Route path="projects/:projectId/contracts/:contractId/view" Component={ContractDetailsScreen} />
    <Route path="projects/:projectId/dailyLogs/new" Component={DailyLogCreateScreen} />
    <Route path="projects/:projectId/dailyLogs/:dailyLogId" Component={DailyLogDetailsScreen} />
    <Route path="projects/:projectId/dailyLogs/:dailyLogId/files" Component={DailyLogDetailsScreen} />
    <Route path="projects/:projectId/invitePeople" Component={InvitePeopleScreen} />

    <Route path="calendar" Component={CalendarScreen} />
    <Route path="projects/:projectId/events/new" Component={EventCreateScreen} />
    <Route path="projects/:projectId/events/:eventId" Component={EventDetailsScreen} />
    <Route path="projects/:projectId/events/:eventId/files" Component={EventDetailsScreen} />

    <Route path="chats" Component={ChatsScreen} />
    <Route path="projects/:projectId/chats/new" Component={ChatCreateScreen} />
    <Route path="projects/:projectId/chats/:chatId" Component={ChatDetailsScreen} />
    <Route path="projects/:projectId/chats/:chatId/messages" Component={ChatMessagesScreen} />

    <Route path="settings" Component={SettingsScreen} />
    <Route path="settings/profile" Component={SettingsScreen} />
    <Route path="settings/invitations" Component={SettingsScreen} />
    <Route path="settings/subscriptions" Component={SettingsScreen} />

    <Route path="contactUs" Component={ContactUsScreen} />
    <Route path="appDownload" Component={AppDownloadScreen} />
    <Route path="*" Component={DashboardScreen} />
  </Route>
);

export default function Screens() {
  const dispatch = useAppDispatch();

  const [triggerGetMe] = useLazyGetMeQuery();
  const [createDeviceToken] = useCreateDeviceTokenMutation();

  const [authLoading, setAuthLoading] = useState(true);
  const [user, setUser] = useState();

  const isOnboarding = useAppSelector((state) => state.auth.isOnboarding);
  const isHomeowner = useAppSelector(selectIsHomeownerAppRole);
  const activeChatId = useAppSelector(selectActiveChatId);
  const giftedChatMessages = useAppSelector(selectGiftedChatMessages);

  // this function gets called twice on login/page load
  useEffect(() => {
    async function onAuthStateChanged(user: any) {
      setAuthLoading(false);
      setUser(user);

      Sentry.setUser(user ? { id: user.uid } : null);

      let idToken = '';
      if (user) {
        const idTokenResult = await user.getIdTokenResult();
        idToken = idTokenResult.token;
      }

      dispatch(setAuth(auth));
      dispatch(setAuthUser(user));
      dispatch(setAuthToken(idToken));

      if (user) {
        triggerGetMe();

        // this runs twice, which can generate two different tokens if one has not already been generated
        // token is saved in firebase-messaging-database/firebase-messaging-store indexedDB
        const fcmToken = await getToken(messaging, {
          vapidKey: VAPID_KEY,
        });
        dispatch(setFCMToken(fcmToken));
        console.log('fcmToken: ' + fcmToken);

        await createDeviceToken({
          token: fcmToken,
        });
      }
    }
    auth.onAuthStateChanged(onAuthStateChanged);
  }, [createDeviceToken, dispatch, triggerGetMe]);

  useEffect(() => {
    const unsubscribe = onMessage(messaging, async (remoteMessage) => {
      processRemoteMessage(remoteMessage, activeChatId, giftedChatMessages, dispatch);
    });
    return unsubscribe;
  }, [activeChatId, dispatch, giftedChatMessages]);

  let component = null;
  if (authLoading) {
    component = <Route path="/*" Component={AuthLoadingScreen} />;
  } else if (!user) {
    component = loginRoutes;
  } else if (isOnboarding) {
    if (isHomeowner) {
      component = HomeownerOnboardingRoutes;
    } else {
      component = ContractorOnboardingRoutes;
    }
  } else {
    component = routes;
  }

  return (
    <BrowserRouter basename="/">
      <Routes>{component}</Routes>
    </BrowserRouter>
  );
}
