import { getIdToken, signOut } from '@firebase/auth';
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { setAuthToken } from './auth.slice';
import { type RootState, store } from './store';

function getBaseUrl(): string {
  return process.env['NX_PUBLIC_API_URL'] || '';
}

const baseQuery = fetchBaseQuery({
  baseUrl: getBaseUrl(),
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token; // FIXME
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && (result.error.status === 401 || result.error.status === 403)) {
    try {
      const auth = (store.getState() as RootState).auth.auth;
      const currentUser = auth?.currentUser;
      if (!currentUser || !currentUser.email) throw new Error('Current user is null');
      const refreshResult = await getIdToken(currentUser, true);
      if (refreshResult) {
        console.log('Refreshed ID Token');
        api.dispatch(setAuthToken(refreshResult));
        result = await baseQuery(args, api, extraOptions);
      } else {
        throw new Error('Token refresh failed');
      }
    } catch (error) {
      console.log(error);
      const auth = (store.getState() as RootState).auth.auth;
      if (auth) {
        signOut(auth).then(() => {
          console.log('User signed out!');
        });
      }
    }
  }

  return result;
};

export const api = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'me',
    'me_tasks',
    'me_events',
    'me_chats',
    'me_daily_logs',
    'me_files',
    'users',
    'invitations',
    'messages',
    'projects',
    'project_tasks',
    'project_events',
    'project_chats',
    'project_files',
    'project_contracts',
    'project_daily_logs',
    'project_daily_log_files',
    'project_contract_files',
    'project_event_files',
    'project_tags',
  ],
  refetchOnReconnect: true,
  endpoints: (builder) => ({}),
});
