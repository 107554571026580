import { Box, Button, Typography, useTheme } from '@mui/joy';
import { Theme } from '@mui/joy/styles';

export default function ContactUsScreen() {
  const styles = makeStyle(useTheme());

  return (
    <Box>
      <Box component="main" sx={styles.contactUsContainer}>
        <Typography>
          The developers of BuilderBud welcome your feedback! Please reach out to us with any questions or comments
          using the Contact Us button below.
        </Typography>
        <Typography>
          You can also contact us if you no longer need your BuilderBud account and we will delete your account and any
          project data that is not shared with other platform users.
        </Typography>
        <Button size="sm" variant="solid" color="primary" component="a" href="mailto:hello@builderbud.com">
          Contact Us
        </Button>
      </Box>
    </Box>
  );
}

const makeStyle = (theme: Theme) => {
  return {
    contactUsContainer: {
      my: 'auto',
      py: 2,
      pb: 5,
      display: 'flex',
      flexDirection: 'column',
      gap: 2,
      width: 600,
      maxWidth: '100%',
      mx: 'auto',
      borderRadius: 'sm',
    },
  };
};
