import { UploadFile } from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Option,
  Select,
  Stack,
  Textarea,
  Typography,
} from '@mui/joy';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { ContractId, DailyLogId, EventId, File, ProjectId, TaskId } from '@builder-bud/common';
import {
  AlertObject,
  FileFormData,
  FileSchemaResolver,
  IMAGE_TYPE,
  closeModal,
  getDefaultFileFormValues,
  getFileSubmitData,
  showErrorAlert,
  showInfoAlert,
  useAppDispatch,
  useEditContractFileMutation,
  useEditDailyLogFileMutation,
  useEditEventFileMutation,
  useEditFileMutation,
  useGetContractFileDownloadUrlMutation,
  useGetDailyLogFileDownloadUrlMutation,
  useGetEventFileDownloadUrlMutation,
  useGetFileDownloadUrlMutation,
  useGetProjectTagsQuery,
} from '@builder-bud/common-ui';

import CircularProgressComponent from '../../components/circular-progress.component';

export default function ProjectFileEditModal({
  projectId,
  taskId,
  eventId,
  dailyLogId,
  contractId,
  file,
}: {
  projectId: ProjectId;
  taskId?: TaskId;
  eventId?: EventId;
  dailyLogId?: DailyLogId;
  contractId?: ContractId;
  file: File;
}) {
  const dispatch = useAppDispatch();

  const [getFileDownloadUrl] = useGetFileDownloadUrlMutation();
  const [updateFile] = useEditFileMutation();

  const [getEventFileDownloadUrl] = useGetEventFileDownloadUrlMutation();
  const [updateEventFile] = useEditEventFileMutation();

  const [getDailyLogFileDownloadUrl] = useGetDailyLogFileDownloadUrlMutation();
  const [updateDailyLogFile] = useEditDailyLogFileMutation();

  const [getContractFileDownloadUrl] = useGetContractFileDownloadUrlMutation();
  const [updateContractFile] = useEditContractFileMutation();

  const [fileDownloadUrl, setFileDownloadUrl] = useState('');
  const [isDownloadUrlLoading, setIsDownloadUrlLoading] = useState(false);
  const [saving, setSaving] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<FileFormData>({
    defaultValues: getDefaultFileFormValues(file),
    resolver: FileSchemaResolver,
  });

  useEffect(() => {
    async function getProjectFileDownloadUrl(file: File) {
      try {
        setIsDownloadUrlLoading(true);

        let fileDownloadUrlResult;
        if (eventId) {
          fileDownloadUrlResult = await getEventFileDownloadUrl({ projectId, eventId, fileId: file.id }).unwrap();
        } else if (dailyLogId) {
          fileDownloadUrlResult = await getDailyLogFileDownloadUrl({ projectId, dailyLogId, fileId: file.id }).unwrap();
        } else if (contractId) {
          fileDownloadUrlResult = await getContractFileDownloadUrl({ projectId, contractId, fileId: file.id }).unwrap();
        } else {
          fileDownloadUrlResult = await getFileDownloadUrl({ projectId, taskId, fileId: file.id }).unwrap();
        }

        if (fileDownloadUrlResult.url) {
          setFileDownloadUrl(fileDownloadUrlResult.url);
        }
      } catch (error) {
        console.log(error);
        dispatch(showErrorAlert(error as AlertObject));
      } finally {
        setIsDownloadUrlLoading(false);
      }
    }

    getProjectFileDownloadUrl(file);
  }, [
    dispatch,
    file,
    getFileDownloadUrl,
    getContractFileDownloadUrl,
    getDailyLogFileDownloadUrl,
    getEventFileDownloadUrl,
    projectId,
    taskId,
    eventId,
    dailyLogId,
    contractId,
  ]);

  async function onSubmit(data: FileFormData) {
    setSaving(true);
    try {
      const fileData = getFileSubmitData(data);
      if (eventId) {
        await updateEventFile({ ...fileData, ...{ fileId: file.id, projectId, eventId } }).unwrap();
      } else if (dailyLogId) {
        await updateDailyLogFile({ ...fileData, ...{ fileId: file.id, projectId, dailyLogId } }).unwrap();
      } else if (contractId) {
        await updateContractFile({ ...fileData, ...{ fileId: file.id, projectId, contractId } }).unwrap();
      } else {
        await updateFile({ ...fileData, ...{ fileId: file.id, projectId, taskId } }).unwrap();
      }
      dispatch(showInfoAlert('Successfully updated file'));
      dispatch(closeModal());
    } catch (error) {
      console.log(error);
      dispatch(showErrorAlert(error as AlertObject));
    } finally {
      setSaving(false);
    }
  }

  const { data: projectTags = [] } = useGetProjectTagsQuery(projectId);

  if (isDownloadUrlLoading) return <CircularProgressComponent />;

  return (
    <Stack sx={{ margin: 2, gap: 2 }}>
      <Typography>Edit file</Typography>

      {file.contentType?.startsWith(IMAGE_TYPE) ? (
        fileDownloadUrl && <img height={200} width={200} src={fileDownloadUrl} alt="" />
      ) : (
        <UploadFile />
      )}

      <Controller
        control={control}
        name="name"
        render={({ field: { onChange, onBlur, value: controlValue } }) => (
          <FormControl error={!!errors.name} required>
            <FormLabel>File name</FormLabel>
            <Input value={controlValue} onBlur={onBlur} onChange={onChange} />
            {errors.name && <FormHelperText>{errors.name.message}</FormHelperText>}
          </FormControl>
        )}
      />

      <Controller
        control={control}
        name="description"
        render={({ field: { onChange, onBlur, value: controlValue } }) => (
          <FormControl error={!!errors.description}>
            <FormLabel>Description</FormLabel>
            <Textarea value={controlValue} onBlur={onBlur} onChange={onChange} minRows={2} />
            {errors.description && <FormHelperText>{errors.description.message}</FormHelperText>}
          </FormControl>
        )}
      />

      <Controller
        control={control}
        name="tags"
        render={({ field: { onChange, onBlur, value: controlValue } }) => (
          <FormControl error={!!errors.tags}>
            <FormLabel>Tags</FormLabel>
            <Select
              multiple
              value={controlValue}
              onChange={(e, newValue) => {
                onChange(newValue);
              }}
              onBlur={onBlur}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', gap: '0.25rem' }}>
                  {selected.map((selectedOption) => (
                    <Chip key={selectedOption.value} variant="soft" color="secondary">
                      {selectedOption.label}
                    </Chip>
                  ))}
                </Box>
              )}
              sx={{ minWidth: '15rem' }}
              slotProps={{
                listbox: {
                  sx: {
                    width: '100%',
                    zIndex: 20001,
                  },
                },
              }}
            >
              {projectTags.map((tag) => (
                <Option key={tag} value={tag}>
                  {tag}
                </Option>
              ))}
            </Select>
          </FormControl>
        )}
      />

      <Button onClick={handleSubmit(onSubmit)} disabled={!isDirty || saving}>
        Save
      </Button>
    </Stack>
  );
}
