/* eslint-disable jsx-a11y/anchor-is-valid */
import { ArrowDropDown, MoreHorizRounded, WarningRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Dropdown,
  FormControl,
  FormLabel,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  Modal,
  ModalDialog,
  Option,
  Select,
  Typography,
  useTheme,
} from '@mui/joy';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Event, EventRelationship, canUserDeleteEvent } from '@builder-bud/common';
import {
  AlertObject,
  BBListItem,
  selectCurrentUserId,
  showErrorAlert,
  showInfoAlert,
  useAppDispatch,
  useAppSelector,
  useDeleteEventMutation,
  useGetUsersQuery,
} from '@builder-bud/common-ui';

import CircularProgressComponent from '../../components/circular-progress.component';
import DateComponent from '../../components/date.component';
import MembersComponent from '../../components/members.component';
import ProjectNameComponent from '../../components/project-name';
import { Order, getComparator } from '../../components/table';
import TableComponent from '../../components/table.component';

export default function EventTableComponent({
  rows,
  showProject = false,
  hideSearch = false,
}: {
  rows: Event[];
  showProject?: boolean;
  hideSearch?: boolean;
}) {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const smallAndUp = useMediaQuery(theme.breakpoints.up('sm'));

  const [deleteEvent] = useDeleteEventMutation();

  const currentUserId = useAppSelector(selectCurrentUserId);

  const [order, setOrder] = useState<Order>('');
  const [userFilter, setUserFilter] = useState<string[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [rowToDelete, setRowToDelete] = useState<Event | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const { data: users = [] } = useGetUsersQuery();

  const filteredRows = userFilter.length
    ? rows.filter((event) => {
        return event.relatedUsers.filter((user) => userFilter.includes(user.id)).length > 0;
      })
    : rows;

  async function handleDeleteEvent() {
    if (rowToDelete) {
      setIsDeleting(true);
      try {
        await deleteEvent({ projectId: rowToDelete.projectId, id: rowToDelete.id }).unwrap();
        dispatch(showInfoAlert('Successfully deleted Event'));
      } catch (error) {
        console.log(error);
        dispatch(showErrorAlert(error as AlertObject));
      } finally {
        setRowToDelete(null);
        setIsDeleting(false);
      }
    }
  }

  const renderFilters = () => {
    const userIds = rows.flatMap((event) => event.relatedUsers.map((user) => user.id));

    //Gets a new array with just the unique content types converted to options
    const options = Array.from(new Set(userIds)).map((userId) => {
      const user = users.find((user) => user.id === userId);
      return { label: user?.displayName, value: user?.id } as BBListItem;
    });

    return (
      <FormControl size="sm">
        <FormLabel>User</FormLabel>
        <Select
          value={userFilter}
          onChange={(e, newValue) => setUserFilter(newValue)}
          size="sm"
          placeholder="Filter by user"
          slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
          multiple={true}
        >
          {options.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
      </FormControl>
    );
  };
  const renderTableHead = () => (
    <thead>
      <tr>
        <th style={{ padding: '12px 6px' }}>
          <Link
            underline="none"
            color="primary"
            component="button"
            onClick={() => setOrder(order === '' ? 'asc' : order === 'asc' ? 'desc' : '')}
            fontWeight="lg"
            endDecorator={<ArrowDropDown />}
            sx={{
              '& svg': {
                transition: '0.2s',
                transform: order === '' ? 'rotate(90deg)' : order === 'desc' ? 'rotate(0deg)' : 'rotate(180deg)',
              },
            }}
          >
            Event
          </Link>
        </th>
        {showProject && smallAndUp && <th style={{ padding: '12px 6px' }}>Project name</th>}
        <th style={{ padding: '12px 6px' }}>Start date</th>
        <th style={{ padding: '12px 6px' }}>End date</th>
        <th style={{ padding: '12px 6px' }}>Assigned To</th>
        <th style={{ width: 48, padding: '12px 6px' }}></th>
      </tr>
    </thead>
  );

  function TableRowMenu({ row }: { row: Event }) {
    const navigate = useNavigate();

    return (
      <Dropdown>
        <MenuButton
          slots={{ root: IconButton }}
          slotProps={{ root: { variant: 'plain', color: 'neutral', size: 'sm' } }}
        >
          <MoreHorizRounded />
        </MenuButton>
        <Menu size="sm" sx={{ minWidth: 140 }}>
          <MenuItem onClick={() => navigate(`/projects/${row.projectId}/events/${row.id}`)}>View</MenuItem>
          {currentUserId && canUserDeleteEvent(row, currentUserId) && (
            <MenuItem
              color="danger"
              onClick={() => {
                setRowToDelete(row);
                setModalOpen(true);
              }}
            >
              Delete
            </MenuItem>
          )}
        </Menu>
      </Dropdown>
    );
  }

  function TableRow({ row }: { row: Event }) {
    const navigate = useNavigate();

    const assignedToUsers = row.relatedUsers
      .filter((user) => user.relationship === EventRelationship.Assigned)
      .map((user) => user.id);

    return (
      <tr key={row.id} style={{ backgroundColor: 'var(--joy-palette-common-white)' }}>
        <td>
          <Link onClick={() => navigate(`/projects/${row.projectId}/events/${row.id}`)}>
            <Typography level="body-xs">{row.name}</Typography>
          </Link>
        </td>
        {showProject && smallAndUp && (
          <td>
            <Typography>
              <ProjectNameComponent projectId={row.projectId} />
            </Typography>
          </td>
        )}
        <td>
          <DateComponent date={row.startDate} />
        </td>
        <td>
          <DateComponent date={row.endDate} />
        </td>
        <td>
          <MembersComponent userIds={assignedToUsers} projectId={row.projectId} />
        </td>
        <td>
          <TableRowMenu row={row} />
        </td>
      </tr>
    );
  }

  if (isDeleting) return <CircularProgressComponent />;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <TableComponent
        renderFilters={renderFilters}
        renderTableHead={renderTableHead}
        TableRow={TableRow}
        rows={filteredRows}
        comparator={getComparator(order, 'name')}
        hideSearch={hideSearch}
      />
      <Modal
        open={modalOpen}
        onClose={() => {
          setRowToDelete(null);
          setModalOpen(false);
        }}
      >
        <ModalDialog variant="outlined" role="alertdialog">
          <DialogTitle>
            <WarningRounded />
            Confirmation
          </DialogTitle>
          <Divider />
          <DialogContent>Are you sure you want to delete this event?</DialogContent>
          <DialogActions>
            <Button variant="solid" color="danger" onClick={() => handleDeleteEvent()}>
              Delete event
            </Button>
            <Button variant="plain" color="neutral" onClick={() => setModalOpen(false)}>
              Cancel
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </Box>
  );
}
