import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  FormControl,
  FormLabel,
  Option,
  Select,
  Stack,
  Typography,
  useTheme,
} from '@mui/joy';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Invitation, InvitationStatus, SentInvitation, sortByInvitationStatus } from '@builder-bud/common';
import {
  AlertObject,
  INVITATION_STATUS_OPTIONS,
  getInvitationStatusLabel,
  getUserProjectRoleLabel,
  showErrorAlert,
  showInfoAlert,
  useAppDispatch,
  useDeleteInvitationMutation,
  useEditInvitationMutation,
  useGetMeInvitationsQuery,
} from '@builder-bud/common-ui';

import CircularProgressComponent from '../../components/circular-progress.component';
import DateTimeComponent from '../../components/date-time.component';
import EmptyListComponent from '../../components/empty-list.component';
import ErrorLoadingComponent from '../../components/error-loading.component';
import MemberComponent from '../../components/member.component';

export default function InvitationsForm() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  const [editInvitation] = useEditInvitationMutation();
  const [deleteInvitation] = useDeleteInvitationMutation();

  const [saving, setSaving] = useState(false);

  const [statusFilter, setStatusFilter] = useState<string[]>([InvitationStatus.Pending]);

  async function handleUpdateInvitation(
    invitation: Invitation,
    status: InvitationStatus.Accepted | InvitationStatus.Rejected
  ) {
    setSaving(true);
    try {
      await editInvitation({
        projectId: invitation.projectId,
        invitationId: invitation.id,
        status,
      }).unwrap();
      dispatch(showInfoAlert('Successfully updated invitation'));
    } catch (error) {
      console.log(error);
      dispatch(showErrorAlert(error as AlertObject));
    } finally {
      setSaving(false);
    }
  }

  async function handleDeleteInvitation(invitation: Invitation) {
    setSaving(true);
    try {
      await deleteInvitation({
        projectId: invitation.projectId,
        invitationId: invitation.id,
      }).unwrap();
      dispatch(showInfoAlert('Successfully deleted invitation'));
    } catch (error) {
      console.log(error);
      dispatch(showErrorAlert(error as AlertObject));
    } finally {
      setSaving(false);
    }
  }

  const { data = { received: [], sent: [] }, isLoading, isFetching, isError, error } = useGetMeInvitationsQuery();

  if (isFetching || isLoading || saving) return <CircularProgressComponent />;
  if (isError) return <ErrorLoadingComponent error={error} />;

  const filteredRecieved = statusFilter.length
    ? data.received.filter((row) => statusFilter.includes(row.status))
    : data.received;
  const filteredSent = statusFilter.length
    ? data.received.filter((row) => statusFilter.includes(row.status))
    : data.received;

  const sortedReceived = [...filteredRecieved].sort(sortByInvitationStatus);
  const sortedSent = [...filteredSent].sort(sortByInvitationStatus);

  return (
    <Card sx={{ display: 'flex', flex: 1, maxWidth: 800 }}>
      <Box sx={{ mb: 1, display: { sm: 'flex' } }} justifyContent="space-between">
        <Typography level="title-md">Invitations</Typography>
        <FormControl size="sm" sx={{ flexDirection: 'row', gap: 1 }}>
          <FormLabel sx={{ alignSelf: 'center' }}>Status</FormLabel>
          <Select
            value={statusFilter}
            onChange={(e, newValue) => setStatusFilter(newValue)}
            size="sm"
            placeholder="Filter by status"
            slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
            multiple={true}
          >
            {INVITATION_STATUS_OPTIONS.map((option, i) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Divider />
      <Stack spacing={3} sx={{ display: { xs: 'flex' }, my: 1 }}>
        {sortedReceived.length === 0 && sortedSent.length === 0 && <EmptyListComponent />}
        {sortedReceived.length > 0 && (
          <Stack style={{ rowGap: 16 }}>
            <Typography level="title-sm">Recieved Invitations</Typography>

            {sortedReceived.map((invitation) => (
              <Card key={invitation.id} sx={{ backgroundColor: theme.vars.palette.common.white }}>
                <CardContent>
                  <Stack
                    sx={{ flexDirection: { xs: 'column', sm: 'row' } }}
                    columnGap={2}
                    justifyContent="space-between"
                  >
                    <Stack
                      flexDirection="row"
                      columnGap={2}
                      onClick={() =>
                        invitation.status === InvitationStatus.Accepted && navigate(`/projects/${invitation.projectId}`)
                      }
                    >
                      <MemberComponent userId={invitation.createdByUserId} projectId={invitation.projectId} size="lg" />
                      <Stack>
                        <Typography level="title-sm">{invitation.project.name}</Typography>
                        <Stack flexDirection="row" alignItems="center" columnGap={2}>
                          <Typography level="title-sm">{getUserProjectRoleLabel(invitation.projectRole)}</Typography>
                          <DateTimeComponent date={invitation.updatedAt} />
                        </Stack>
                      </Stack>
                    </Stack>
                    {invitation.status === InvitationStatus.Pending ? (
                      <Stack sx={{ alignSelf: { xs: 'flex-end', sm: 'initial' } }} flexDirection="row" columnGap={1}>
                        <Button
                          variant="plain"
                          onClick={() => handleUpdateInvitation(invitation, InvitationStatus.Rejected)}
                        >
                          Ignore
                        </Button>
                        <Button
                          variant="outlined"
                          onClick={() => handleUpdateInvitation(invitation, InvitationStatus.Accepted)}
                        >
                          Accept
                        </Button>
                      </Stack>
                    ) : (
                      <Stack>
                        <Typography level="body-md">{getInvitationStatusLabel(invitation.status)}</Typography>
                      </Stack>
                    )}
                  </Stack>
                </CardContent>
              </Card>
            ))}
          </Stack>
        )}

        {sortedSent.length > 0 && (
          <Stack style={{ rowGap: 16 }}>
            <Typography level="title-sm">Sent Invitations</Typography>

            {sortedSent.map((invitation: SentInvitation) => (
              <Card
                key={invitation.id}
                sx={{ backgroundColor: theme.vars.palette.common.white }}
                onClick={() => navigate(`/projects/${invitation.projectId}`)}
              >
                <CardContent>
                  <Stack
                    sx={{ flexDirection: { xs: 'column', sm: 'row' } }}
                    columnGap={2}
                    justifyContent="space-between"
                  >
                    <Stack flexDirection="row" columnGap={2}>
                      <Stack>
                        <Typography level="title-sm">{invitation.project.name}</Typography>
                        {invitation.invitedUser ? (
                          <Typography level="title-sm">
                            {invitation.invitedUser.firstName} {invitation.invitedUser.lastName}
                          </Typography>
                        ) : (
                          <Typography level="title-sm">{invitation.email}</Typography>
                        )}
                        <Stack flexDirection="row" alignItems="center" columnGap={2}>
                          <Typography level="title-sm">{getUserProjectRoleLabel(invitation.projectRole)}</Typography>
                          <DateTimeComponent date={invitation.updatedAt} />
                        </Stack>
                      </Stack>
                    </Stack>
                    {invitation.status === InvitationStatus.Pending ? (
                      <Stack sx={{ alignSelf: { xs: 'flex-end', sm: 'initial' } }} flexDirection="row" columnGap={1}>
                        <Button variant="plain" color="danger" onClick={() => handleDeleteInvitation(invitation)}>
                          Delete
                        </Button>
                      </Stack>
                    ) : (
                      <Stack>
                        <Typography level="body-md">{getInvitationStatusLabel(invitation.status)}</Typography>
                      </Stack>
                    )}
                  </Stack>
                </CardContent>
              </Card>
            ))}
          </Stack>
        )}
      </Stack>
    </Card>
  );
}
