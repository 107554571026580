import {
  ChatId,
  CreateFileResponse,
  CreateMessageRequest,
  CreateMessageResponse,
  File,
  FileId,
  GetMessagesResponse,
  GetSignedDownloadUrlResponse,
  GetSignedUploadUrlResponse,
  Message,
  MessageId,
  ProjectId,
  SignedDownloadUrlData,
  SignedUploadUrlData,
  UpdateMessageRequest,
  UpdateMessageResponse,
} from '@builder-bud/common';

import { api } from './api';

export const messagesSlice = api.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getMessages: builder.query<Message[], { projectId: ProjectId; chatId: ChatId }>({
      query: (body) => ({
        url: `/projects/${body.projectId}/chats/${body.chatId}/messages`,
        method: 'GET',
      }),
      transformResponse: (response: GetMessagesResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to get chats');
      },
      providesTags: ['messages'],
    }),

    createMessage: builder.mutation<Message, CreateMessageRequest & { projectId: ProjectId; chatId: ChatId }>({
      query: (body) => ({
        url: `/projects/${body.projectId}/chats/${body.chatId}/messages`,
        method: 'POST',
        body,
      }),
      transformResponse: (response: CreateMessageResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to create chat');
      },
    }),

    editMessage: builder.mutation<
      Message,
      UpdateMessageRequest & { projectId: ProjectId; chatId: ChatId; messageId: MessageId }
    >({
      query: (body) => ({
        url: `/projects/${body.projectId}/chats/${body.chatId}/messages/${body.messageId}`,
        method: 'PATCH',
        body,
      }),
      transformResponse: (response: UpdateMessageResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to edit chat');
      },
    }),
    deleteMessage: builder.mutation<Message, { projectId: ProjectId; chatId: ChatId; messageId: MessageId }>({
      query: (body) => ({
        url: `/projects/${body.projectId}/chats/${body.chatId}/messages/${body.messageId}`,
        method: 'DELETE',
      }),
    }),

    readMessage: builder.mutation<Message, { projectId: ProjectId; chatId: ChatId; messageId: MessageId }>({
      query: (body) => ({
        url: `/projects/${body.projectId}/chats/${body.chatId}/messages/${body.messageId}/read`,
        method: 'POST',
      }),
      transformResponse: (response: UpdateMessageResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to edit chat');
      },
      invalidatesTags: ['project_chats', 'me_chats'],
    }),

    getMessageFileUploadUrl: builder.mutation<
      SignedUploadUrlData,
      { projectId: string; chatId: ChatId; contentType?: string }
    >({
      query: (body) => ({
        url: `/projects/${body.projectId}/chats/${body.chatId}/files/upload-url?contentType=${body.contentType}`,
        method: 'GET',
      }),
      transformResponse: (response: GetSignedUploadUrlResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to get message file upload url');
      },
    }),
    createMessageFile: builder.mutation<File, { projectId: ProjectId; chatId: ChatId }>({
      query: (body) => ({
        url: `/projects/${body.projectId}/chats/${body.chatId}/files`,
        method: 'POST',
        body,
      }),
      transformResponse: (response: CreateFileResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to create message file');
      },
    }),
    getMessageFileDownloadUrl: builder.mutation<
      SignedDownloadUrlData,
      { projectId: ProjectId; chatId: ChatId; fileId: FileId }
    >({
      query: (body) => ({
        url: `/projects/${body.projectId}/chats/${body.chatId}/files/${body.fileId}/download-url`,
        method: 'GET',
      }),
      transformResponse: (response: GetSignedDownloadUrlResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to get message file download url');
      },
    }),
  }),
});

export const {
  useGetMessagesQuery,
  useLazyGetMessagesQuery,
  useCreateMessageMutation,
  useEditMessageMutation,
  useDeleteMessageMutation,
  useReadMessageMutation,
  useGetMessageFileUploadUrlMutation,
  useCreateMessageFileMutation,
  useGetMessageFileDownloadUrlMutation,
} = messagesSlice;
