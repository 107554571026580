import { Person } from '@mui/icons-material';
import { Avatar, AvatarProps, Box, Typography } from '@mui/joy';

import { User } from '@builder-bud/common';

function AvatarComponent({
  user,
  size = 'sm',
  showName = false,
}: {
  user?: User;
  size?: AvatarProps['size'];
  showName?: boolean;
}) {
  return (
    <Box>
      {user ? (
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {user.profileImageThumbnailUrl ? (
            <Avatar
              size={size}
              src={user.profileImageThumbnailUrl}
              onError={(e) => {
                if (e.target && user.profileImageUrl) {
                  (e.target as HTMLImageElement).src = user.profileImageUrl;
                }
              }}
            />
          ) : (
            <Avatar size={size}>{user.firstName?.slice(0, 1) + user.lastName.slice(0, 1)}</Avatar>
          )}
          {showName && <Typography style={{ marginLeft: 8 }}>{`${user.firstName} ${user.lastName}`}</Typography>}
        </Box>
      ) : (
        <Avatar size={size}>
          <Person />
        </Avatar>
      )}
    </Box>
  );
}

export default AvatarComponent;
