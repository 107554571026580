import { DailyLog, DailyLogType, UserId, UserProjectRole, isSubUserProjectRole } from '@builder-bud/common';

export function canUserCreateDailyLog(userProjectRole: UserProjectRole, dailyLogType: DailyLogType): boolean {
  return canRoleCreateLogType(userProjectRole, dailyLogType);
}

export function canUserReadDailyLog(dailyLog: DailyLog, userProjectRole: UserProjectRole, userId: UserId): boolean {
  if (userProjectRole === UserProjectRole.Contractor) {
    return true;
  }

  if (
    [DailyLogType.HomeownerUpdate, DailyLogType.HomeownerLog].includes(dailyLog.type) &&
    userProjectRole === UserProjectRole.Homeowner
  ) {
    return true;
  }
  return dailyLog.createdByUserId === userId;
}

export function canUserUpdateDailyLog(
  dailyLog: DailyLog,
  userProjectRole: UserProjectRole,
  userId: UserId,
  type?: DailyLogType
): boolean {
  if (isSubUserProjectRole(userProjectRole)) {
    return false;
  }

  if (type && !canRoleCreateLogType(userProjectRole, type)) {
    return false;
  }

  return userProjectRole === UserProjectRole.Contractor || dailyLog.createdByUserId === userId;
}

export function canUserDeleteDailyLog(dailyLog: DailyLog, userProjectRole: UserProjectRole, userId: UserId): boolean {
  if (isSubUserProjectRole(userProjectRole)) {
    //this.logger.log({ projectId, dailyLogId, projectContext }, 'Sub user cannot delete daily logs');
    return false;
  }

  return (
    (userProjectRole === UserProjectRole.Contractor && dailyLog.type !== DailyLogType.HomeownerLog) ||
    (dailyLog.type === DailyLogType.HomeownerLog && dailyLog.createdByUserId === userId)
  );
}

function canRoleCreateLogType(role: UserProjectRole, type: DailyLogType): boolean {
  if (role === UserProjectRole.Homeowner) {
    return type === DailyLogType.HomeownerLog;
  }

  return type !== DailyLogType.HomeownerLog;
}
