import { createSelector } from '@reduxjs/toolkit';

import { AppRole } from '@builder-bud/common';

import { RootState } from './store';

export const selectIsHomeownerAppRole = createSelector([(state: RootState) => state.auth.me], (me) => {
  return me?.appRole === AppRole.Homeowner;
});

export const selectIsContractorAppRole = createSelector([(state: RootState) => state.auth.me], (me) => {
  return me?.appRole === AppRole.Contractor;
});

export const selectIsSubcontractorAppRole = createSelector([(state: RootState) => state.auth.me], (me) => {
  return me?.appRole !== AppRole.Homeowner && me?.appRole !== AppRole.Contractor;
});

export const selectCurrentUserId = createSelector([(state: RootState) => state.auth.me], (me) => {
  return me?.id;
});

export const selectToken = createSelector([(state: RootState) => state.auth], (auth) => {
  return auth.token;
});

export const selectFCMToken = createSelector([(state: RootState) => state.auth], (auth) => {
  return auth.fcmToken;
});

export const selectFilterValue = createSelector([(state: RootState) => state.layout], (layout) => {
  return layout.filterValue;
});

export const selectFilterOptions = createSelector([(state: RootState) => state.layout], (layout) => {
  return layout.filterOptions;
});

export const selectActiveChatId = createSelector([(state: RootState) => state.giftedChat], (giftedChat) => {
  return giftedChat.activeChatId;
});

export const selectGiftedChatMessages = createSelector([(state: RootState) => state.giftedChat], (giftedChat) => {
  return giftedChat.messages;
});
