import { Box, Button, Typography, useTheme } from '@mui/joy';
import { Theme } from '@mui/joy/styles';

import BuilderBudIcon from '../../components/builderbud-icon.component';

export default function ContactUsFullScreen() {
  const styles = makeStyle(useTheme());

  return (
    <Box>
      <Box sx={styles.leftContainer} />
      <Box sx={styles.rightContainer}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100dvh',
            width: '100%',
            px: 2,
          }}
        >
          <Box
            component="header"
            sx={{
              py: 2,
              gap: 2,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box sx={{ gap: 2, display: 'flex', alignItems: 'center' }}>
              <BuilderBudIcon />
              <Typography level="title-md">Welcome to BuilderBud</Typography>
            </Box>
            <Typography level="title-md">Construction made easy</Typography>
          </Box>
          <Box component="main" sx={styles.contactUsContainer}>
            <Typography>
              The developers of BuilderBud welcome your feedback! Please reach out to us with any questions or comments
              using the Contact Us button below.
            </Typography>
            <Typography>
              You can also contact us if you no longer need your BuilderBud account and we will delete your account and
              any project data that is not shared with other platform users.
            </Typography>
            <Button size="sm" variant="solid" color="primary" component="a" href="mailto:hello@builderbud.com">
              Contact Us
            </Button>
          </Box>
          <Box component="footer" sx={{ py: 2 }}>
            <Typography level="body-xs" textAlign="center">
              © Builder Bud {new Date().getFullYear()}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

const makeStyle = (theme: Theme) => {
  return {
    leftContainer: {
      position: 'fixed',
      left: 0,
      top: 0,
      bottom: 0,
      right: { xs: 0, md: '50vw' },
      transition: 'background-image var(--Transition-duration), left var(--Transition-duration) !important',
      transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
      backgroundColor: 'var(--joy-palette-primary-500)',
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundImage: 'url(../../assets/splash-screen.png)',
    },

    rightContainer: {
      position: 'fixed',
      right: 0,
      top: 0,
      bottom: 0,
      left: { xs: 0, md: '50vw' },
      transition: 'width var(--Transition-duration)',
      transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
      zIndex: 1,
      display: 'flex',
      justifyContent: 'flex-end',
      backgroundColor: 'var(--joy-palette-common-white)',
    },

    contactUsContainer: {
      my: 'auto',
      py: 2,
      pb: 5,
      display: 'flex',
      flexDirection: 'column',
      gap: 2,
      width: 600,
      maxWidth: '100%',
      mx: 'auto',
      borderRadius: 'sm',
    },
  };
};
