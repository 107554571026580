import { signInWithEmailAndPassword } from '@firebase/auth';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Button,
  Card,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Input,
  Stack,
  Typography,
} from '@mui/joy';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { AppRole } from '@builder-bud/common';
import {
  AccountFormData,
  AccountSchemaResolver,
  AlertObject,
  getAccountSubmitData,
  getDefaultAccountFormValues,
  setIsOnboarding,
  showErrorAlert,
  useAppDispatch,
  useCreateUserMutation,
} from '@builder-bud/common-ui';

import CircularProgressComponent from '../../components/circular-progress.component';
import { auth } from '../login/firebase';

export default function CreateAccountStep({ appRole }: { appRole: AppRole }) {
  const dispatch = useAppDispatch();
  const [createUser] = useCreateUserMutation();

  const [registering, setRegistering] = useState(false);
  const [loggingIn, setLoggingIn] = useState(false);
  const [secureTextEntry, setSecureTextEntry] = useState(true);
  const toggleSecureTextEntry = () => setSecureTextEntry(!secureTextEntry);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<AccountFormData>({
    defaultValues: getDefaultAccountFormValues(),
    resolver: AccountSchemaResolver,
  });

  async function onSubmit(data: AccountFormData) {
    try {
      console.log('registering');
      setRegistering(true);

      await createUser(getAccountSubmitData(appRole, data)).unwrap();

      console.log('User Registered!');

      dispatch(setIsOnboarding(true));
      setLoggingIn(true);
      console.log('Logging in user...');

      signInWithEmailAndPassword(auth, data.email, data.password);
      console.log('User signed in!');
    } catch (error) {
      console.log(error);
      dispatch(showErrorAlert(error as AlertObject));
    } finally {
      setRegistering(false);
      setLoggingIn(false);
    }
  }

  if (registering || loggingIn) return <CircularProgressComponent />;

  return (
    <Card style={{ backgroundColor: 'var(--joy-palette-common-white)' }}>
      <Stack sx={{ mb: 1 }}>
        <Typography level="title-md">Create your account.</Typography>
      </Stack>
      <Divider />
      <Stack rowGap={2}>
        <Controller
          control={control}
          name="email"
          render={({ field: { onChange, onBlur, value: controlValue } }) => (
            <FormControl error={!!errors.email} required>
              <FormLabel>Email</FormLabel>
              <Input value={controlValue} onBlur={onBlur} onChange={onChange} />
              {errors.email && <FormHelperText>{errors.email.message}</FormHelperText>}
            </FormControl>
          )}
        />

        <Controller
          control={control}
          name="password"
          render={({ field: { onChange, onBlur, value: controlValue } }) => (
            <FormControl error={!!errors.password} required>
              <FormLabel>Password</FormLabel>
              <Input
                type={secureTextEntry ? 'password' : ''}
                value={controlValue}
                onBlur={onBlur}
                onChange={onChange}
                endDecorator={
                  secureTextEntry ? (
                    <Visibility
                      onClick={() => {
                        toggleSecureTextEntry();
                      }}
                    />
                  ) : (
                    <VisibilityOff
                      onClick={() => {
                        toggleSecureTextEntry();
                      }}
                    />
                  )
                }
              />
              {errors.password && <FormHelperText>{errors.password.message}</FormHelperText>}
            </FormControl>
          )}
        />

        <Controller
          control={control}
          name="phone"
          render={({ field: { onChange, onBlur, value: controlValue } }) => (
            <FormControl error={!!errors.phone} required>
              <FormLabel>Phone</FormLabel>
              <Input value={controlValue} onBlur={onBlur} onChange={onChange} />
              {errors.phone && <FormHelperText>{errors.phone.message}</FormHelperText>}
            </FormControl>
          )}
        />

        <Grid container spacing={2}>
          <Grid xs={12} sm={6}>
            <Controller
              control={control}
              name="firstName"
              render={({ field: { onChange, onBlur, value: controlValue } }) => (
                <FormControl error={!!errors.firstName} required>
                  <FormLabel>First Name</FormLabel>
                  <Input value={controlValue} onBlur={onBlur} onChange={onChange} />
                  {errors.firstName && <FormHelperText>{errors.firstName.message}</FormHelperText>}
                </FormControl>
              )}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <Controller
              control={control}
              name="lastName"
              render={({ field: { onChange, onBlur, value: controlValue } }) => (
                <FormControl error={!!errors.lastName} required>
                  <FormLabel>Last Name</FormLabel>
                  <Input value={controlValue} onBlur={onBlur} onChange={onChange} />
                  {errors.lastName && <FormHelperText>{errors.lastName.message}</FormHelperText>}
                </FormControl>
              )}
            />
          </Grid>
        </Grid>

        <Controller
          control={control}
          name="zipCode"
          render={({ field: { onChange, onBlur, value: controlValue } }) => (
            <FormControl error={!!errors.zipCode} required>
              <FormLabel>ZipCode</FormLabel>
              <Input value={controlValue} onBlur={onBlur} onChange={onChange} />
              {errors.zipCode && <FormHelperText>{errors.zipCode.message}</FormHelperText>}
            </FormControl>
          )}
        />
      </Stack>

      <Button variant="solid" onClick={handleSubmit(onSubmit)} style={styles.button}>
        Next
      </Button>
    </Card>
  );
}

const styles = {
  name: {
    display: 'flex',
    flex: 1,
  },
  button: {
    width: 100,
    alignSelf: 'flex-end',
  },
};
