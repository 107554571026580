import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Chat, CreateChatRequest, UpdateChatRequest, User, UserId } from '@builder-bud/common';

export type ChatFormData = {
  name: string;
  additionalUserIds: UserId[];
};

export const ChatSchemaResolver = yupResolver(
  yup.object<ChatFormData>().shape({
    name: yup.string().required('Name is required').max(256, 'Name must be 256 characters or less'),
    additionalUserIds: yup.array().required().min(1, 'Additional users is required'),
  })
);

export function getDefaultChatFormValues(chat: Partial<Chat>, me?: UserId): ChatFormData {
  const chatUserIds = chat.userIds ? chat.userIds.filter((userId) => userId !== me) : [];
  return {
    name: chat.name ? chat.name : '',
    additionalUserIds: chatUserIds,
  };
}

export function getChatSubmitData(data: ChatFormData): CreateChatRequest | UpdateChatRequest {
  return {
    name: data.name,
    additionalUserIds: data.additionalUserIds,
  };
}

export function getUnreadMessagesBadge(chats: Chat[] = []): number {
  return chats.reduce((accumulator, chat) => {
    return chat.unreadMessageCount ? (accumulator += chat.unreadMessageCount) : accumulator;
  }, 0);
}
