import { Typography, TypographySystem } from '@mui/joy';
import { format } from 'date-fns';

export default function DateTimeComponent({
  date,
  level = 'body-xs',
}: {
  date?: string;
  level?: keyof TypographySystem | 'inherit' | undefined;
}) {
  let dateString = 'TBD';
  if (date) {
    dateString = format(date, 'MMM dd yyyy h:mm a');
  }
  return <Typography level={level}>{dateString}</Typography>;
}
