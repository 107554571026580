import { Message, ReferencedFile } from '@builder-bud/common';

export interface GiftedChatUser {
  _id: string | number;
  name?: string;
  avatar?: string; //string | number | renderFunction;
}

export interface GiftedChatMessage {
  _id: string | number;
  text: string;
  createdAt: Date | number;
  user: GiftedChatUser;
  files: ReferencedFile[] | undefined;
  image?: string;
  video?: string;
  //audio?: string;
  //system?: boolean;
  //sent?: boolean;
  //received?: boolean;
  //pending?: boolean;
  //quickReplies?: QuickReplies;
  isRead: boolean;
}

export function convertMessagetoGiftedChatMessage(message: Message) {
  return {
    _id: message.id,
    text: message.content ? message.content : '',
    createdAt: new Date(message.createdAt),
    user: {
      _id: message.createdByUserId,
      //name: 'AI',
      //avatar?: string,
    },
    files: message.files,
    isRead: message.isRead,
    //audio?: string
    //system?: boolean
    //sent?: boolean
    //received?: boolean
    //pending?: boolean
    //quickReplies?: QuickReplies
  };
}
