import {
  Button,
  Card,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Option,
  Select,
  Stack,
  Typography,
} from '@mui/joy';
import { Dispatch } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { AppRole } from '@builder-bud/common';
import {
  APP_ROLE_OPTIONS,
  SelectAccountFormData,
  SelectAccountSchemaResolver,
  UnauthenticatedSteps,
} from '@builder-bud/common-ui';

import './onboarding.screen';

export default function SelectAccountTypeStep({
  setStep,
  setAppRole,
}: {
  setStep: Dispatch<UnauthenticatedSteps>;
  setAppRole: Dispatch<AppRole>;
}) {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<SelectAccountFormData>({
    defaultValues: {
      appRole: AppRole.Homeowner,
    },
    resolver: SelectAccountSchemaResolver,
  });

  async function onSubmit(data: SelectAccountFormData) {
    setAppRole(data.appRole);
    setStep(UnauthenticatedSteps.createAccount);
  }

  return (
    <Card style={{ backgroundColor: 'var(--joy-palette-common-white)' }}>
      <Stack sx={{ mb: 1 }}>
        <Typography level="title-md">Select your account type.</Typography>
      </Stack>
      <Divider />
      <Stack rowGap={2}>
        <Controller
          control={control}
          name="appRole"
          render={({ field: { onChange, onBlur, value: controlValue } }) => (
            <FormControl error={!!errors.appRole} required>
              <FormLabel>Account Type</FormLabel>
              <Select
                value={controlValue}
                onChange={(e, newValue) => {
                  onChange(newValue);
                }}
              >
                {APP_ROLE_OPTIONS.map((option, i) => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
              {errors.appRole && <FormHelperText>{errors.appRole.message}</FormHelperText>}
            </FormControl>
          )}
        />
      </Stack>
      <Button variant="solid" onClick={handleSubmit(onSubmit)} style={styles.button}>
        Next
      </Button>
    </Card>
  );
}

const styles = {
  button: {
    width: 100,
    alignSelf: 'flex-end',
  },
};
