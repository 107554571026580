import { Chip, Stack } from '@mui/joy';

function FileTagsComponent({ tags = [] }: { tags: string[] }) {
  return (
    <Stack flexDirection="row" gap={1} flexWrap="wrap">
      {tags.map((tag) => (
        <Chip key={tag} variant="soft" color="secondary">
          {tag}
        </Chip>
      ))}
    </Stack>
  );
}

export default FileTagsComponent;
