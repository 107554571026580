import {
  Invitation,
  Project,
  UpdateProjectRequest,
  User,
  UserId,
  UserProjectRole,
  isNonHomeownerProjectRole,
} from '@builder-bud/common';

export function canUserCreateProject(user: User, activeProjectCount: number): boolean {
  if (user.maxProjects === 0) {
    return false;
  }
  if (!user.maxProjects) {
    return true;
  }
  return activeProjectCount < user.maxProjects;
}

export function canUserReadProject(userProjectRole: UserProjectRole): boolean {
  return Boolean(userProjectRole);
}

/* The logic is tightly coupled with the middleware and does not make sense in the UI at this level */
//export function canUserUpdateProject(userProjectRole: UserProjectRole): boolean { return false}

export function canUserDeleteProject(project: Project, userId: UserId): boolean {
  return project.createdByUserId === userId;
}

export function canUserRemoveUserFromProject(userInvitation: Invitation | null, userId: UserId): boolean {
  return userInvitation?.createdByUserId === userId;
}

export function canUserCreateInvitation(userProjectRole: UserProjectRole): boolean {
  return [UserProjectRole.Contractor, UserProjectRole.Homeowner].includes(userProjectRole);
}

export function canUserRespondToInvitation(invitation: Invitation, userId?: UserId): boolean {
  return invitation.userId === userId;
}

export function canUserDeleteInvitation(invitation: Invitation, userId: UserId): boolean {
  return invitation.createdByUserId === userId;
}

export function canUserCreateProjectNotification(userProjectRole: UserProjectRole): boolean {
  return isNonHomeownerProjectRole(userProjectRole);
}

function isBasicProjectAttribute(attribute: keyof UpdateProjectRequest): boolean {
  return ['id', 'name', 'description', 'addressLine1', 'addressLine2', 'city', 'stateCode', 'zipCode'].includes(
    attribute
  );
}

export function canUpdateProjectAttribute(
  userProjectRole: UserProjectRole,
  attribute: keyof UpdateProjectRequest
): boolean {
  // shouldn't happen, just as a backstop
  if (!userProjectRole) {
    return false;
  }

  if (isBasicProjectAttribute(attribute)) {
    return true;
  }

  return userProjectRole !== UserProjectRole.Homeowner;
}
