import React from 'react';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import GooglePlacesAutocompleteProps, {
  GooglePlacesAutocompleteHandle,
  Option,
} from 'react-google-places-autocomplete/build/types';
import { UseFormSetValue } from 'react-hook-form';

type BBGooglePlacesAutocompleteProps = GooglePlacesAutocompleteProps & {
  value: string;
  onChange: any;
  setValue: UseFormSetValue<any>;
  disabled?: boolean;
};

const BBGooglePlacesAutocomplete: React.FC<BBGooglePlacesAutocompleteProps> = React.forwardRef(
  (props, ref: React.Ref<GooglePlacesAutocompleteHandle>): JSX.Element => {
    return (
      <GooglePlacesAutocomplete
        apiKey="AIzaSyCrGVBXbDQvoSVZpHgrzygGS3j48k01KdY" //TODO: Move to config file and limit the API
        selectProps={{
          styles: {
            //TODO: Need to finish styling this like the normal material selects
            container: (provided) => ({
              ...provided,
              flex: 1,
            }),
            menu: (provided) => ({
              ...provided,
              zIndex: 20000,
            }),
          },
          value: { value: props.value, label: props.value } as Option,
          onChange: async (value) => {
            props.onChange(value?.label);

            try {
              const details = await geocodeByPlaceId(value?.value.place_id);
              const components = details[0]?.address_components;
              if (components) {
                let addressLine1 =
                  components.find((component) => component.types.includes('street_number'))?.short_name + ' ';
                addressLine1 += components.find((component) => component.types.includes('route'))?.short_name;
                const addressLine2 = components.find((component) => component.types.includes('subpremise'))?.short_name;
                const city = components.find((component) => component.types.includes('locality'))?.short_name;
                const state = components.find((component) =>
                  component.types.includes('administrative_area_level_1')
                )?.short_name;
                const zipCode = components.find((component) => component.types.includes('postal_code'))?.short_name;

                props.setValue('addressLine1', addressLine1);
                props.setValue('addressLine2', addressLine2 ? addressLine2 : '');
                props.setValue('city', city ? city : '');
                props.setValue('stateCode', state ? state : '');
                props.setValue('zipCode', zipCode ? zipCode : '');
              }
            } catch (error) {
              console.log(error);
            }
          },
          isDisabled: props.disabled,
        }}
        minLengthAutocomplete={3}
        {...props}
      />
    );
  }
);

export default BBGooglePlacesAutocomplete;
