import { ExpandMore } from '@mui/icons-material';
import { Chip, Dropdown, Menu, MenuButton, MenuItem, Stack, useTheme } from '@mui/joy';
import { useState } from 'react';

import { Project, ProjectStatus } from '@builder-bud/common';
import {
  AlertObject,
  BBListItem,
  PROJECT_STATUS_OPTIONS,
  showErrorAlert,
  useAppDispatch,
  useEditProjectMutation,
} from '@builder-bud/common-ui';

import CircularProgressComponent from './circular-progress.component';

function convertProjectStatusValue(value: ProjectStatus): BBListItem {
  const status = PROJECT_STATUS_OPTIONS.find((option) => option.value === value);
  return status ? status : PROJECT_STATUS_OPTIONS[0];
}

function ProjectStatusComponent({ project, allowUpdate = false }: { project: Project; allowUpdate?: boolean }) {
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const [editProject] = useEditProjectMutation();

  const [saving, setSaving] = useState(false);

  async function updateStatus(status: ProjectStatus) {
    setSaving(true);
    try {
      await editProject({ id: project.id, status }).unwrap();
    } catch (error) {
      console.log(error);
      dispatch(showErrorAlert(error as AlertObject));
    } finally {
      setSaving(false);
    }
  }

  if (saving) return <CircularProgressComponent />;

  const statusOption = convertProjectStatusValue(project.status);

  return (
    <Stack>
      <Dropdown>
        <MenuButton variant="plain" disabled={!allowUpdate} style={{ justifyContent: 'flex-start' }}>
          <Chip
            sx={{
              minWidth: 90,
              fontSize: 12,
              color: theme.vars.palette.common.white,
              backgroundColor: `var(--joy-palette-${statusOption.color}-500)`,
            }}
            endDecorator={allowUpdate && <ExpandMore />}
          >
            {statusOption.label}
          </Chip>
        </MenuButton>
        <Menu size="sm" variant="plain">
          {PROJECT_STATUS_OPTIONS.map((option) => {
            return (
              <MenuItem
                key={option.value}
                onClick={() => {
                  updateStatus(option.value as ProjectStatus);
                }}
                sx={{
                  minWidth: 90,
                  fontSize: 12,
                  color: theme.vars.palette.common.white,
                  backgroundColor: `var(--joy-palette-${option.color}-500)`,
                }}
              >
                {option.label}
              </MenuItem>
            );
          })}
        </Menu>
      </Dropdown>
    </Stack>
  );
}

export default ProjectStatusComponent;
