import { Modal, ModalClose, ModalDialog } from '@mui/joy';
import Box from '@mui/joy/Box';
import { Outlet } from 'react-router-dom';

import { closeModal, useAppDispatch, useAppSelector } from '@builder-bud/common-ui';

import AlertComponent from './components/alert.component';

export default function LayoutNoSidebarComponent() {
  const dispatch = useAppDispatch();

  const modalOpen = useAppSelector((state) => state.layout.modalOpen);
  const modalContent = useAppSelector((state) => state.layout.modalContent);

  return (
    <Box sx={{ display: 'flex', minHeight: '100dvh' }}>
      <Outlet />
      <Modal
        open={modalOpen}
        onClose={() => {
          dispatch(closeModal());
        }}
        sx={{ zIndex: 20000 }}
      >
        <ModalDialog>
          <ModalClose />
          {modalContent}
        </ModalDialog>
      </Modal>
      <AlertComponent />
    </Box>
  );
}
