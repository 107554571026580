/* eslint-disable jsx-a11y/anchor-is-valid */
import { ArrowDropDown, MoreHorizRounded, WarningRounded } from '@mui/icons-material';
//Icons used for the file table
import { Article, CoPresent, FilePresent, FolderZip, PictureAsPdf, TableChart, VideoFile } from '@mui/icons-material';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Dropdown,
  FormControl,
  FormLabel,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  Modal,
  ModalDialog,
  Option,
  Select,
  Typography,
  useTheme,
} from '@mui/joy';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { ContractId, DailyLogId, EventId, FileWithUrl, ProjectId, TaskId } from '@builder-bud/common';
import {
  AlertObject,
  IMAGE_TYPE,
  convertFileTypeToIcon,
  openModal,
  showErrorAlert,
  useAppDispatch,
  useDeleteContractFileMutation,
  useDeleteDailyLogFileMutation,
  useDeleteEventFileMutation,
  useDeleteFileMutation,
  useGetContractFileDownloadUrlMutation,
  useGetDailyLogFileDownloadUrlMutation,
  useGetEventFileDownloadUrlMutation,
  useGetFileDownloadUrlMutation,
} from '@builder-bud/common-ui';

import CircularProgressComponent from '../../components/circular-progress.component';
import DateTimeComponent from '../../components/date-time.component';
import FileTagsComponent from '../../components/file-tags.component';
import MemberComponent from '../../components/member.component';
import { Order, getComparator } from '../../components/table';
import TableComponent from '../../components/table.component';
import { useRequiredParams } from '../../utils';
import ProjectFileEditModal from './project-file-edit.modal';

const iconsMap = { Article, CoPresent, FilePresent, FolderZip, PictureAsPdf, TableChart, VideoFile };

export default function FilesTableComponent({
  rows,
  hideSearch = false,
  hideRowMenu = false,
}: {
  rows: FileWithUrl[];
  hideSearch?: boolean;
  hideRowMenu?: boolean;
}) {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const smallAndUp = useMediaQuery(theme.breakpoints.up('sm'));

  const [deleteFile] = useDeleteFileMutation();
  const [getFileDownloadUrl] = useGetFileDownloadUrlMutation();

  const [deleteEventFile] = useDeleteEventFileMutation();
  const [getEventFileDownloadUrl] = useGetEventFileDownloadUrlMutation();

  const [deleteDailyLogFile] = useDeleteDailyLogFileMutation();
  const [getDailyLogFileDownloadUrl] = useGetDailyLogFileDownloadUrlMutation();

  const [deleteContractFile] = useDeleteContractFileMutation();
  const [getContractFileDownloadUrl] = useGetContractFileDownloadUrlMutation();

  const { projectId } = useRequiredParams<{ projectId: ProjectId }>();
  const { taskId } = useParams<{ taskId: TaskId }>();
  const { eventId } = useParams<{ eventId: EventId }>();
  const { dailyLogId } = useParams<{ dailyLogId: DailyLogId }>();
  const { contractId } = useParams<{ contractId: ContractId }>();

  const [order, setOrder] = useState<Order>('');
  const [fileTagFilter, setFileTagFilter] = useState<string[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [rowToDelete, setRowToDelete] = useState<FileWithUrl | null>(null);

  const [isFileDownloading, setIsFileDownloading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const filteredRows = fileTagFilter.length
    ? rows.filter((row) => row.tags.some((tag) => fileTagFilter.includes(tag)))
    : rows;

  const viewFile = async (row: FileWithUrl) => {
    setIsFileDownloading(true);

    try {
      let fileDownloadUrlResult;
      if (eventId) {
        fileDownloadUrlResult = await getEventFileDownloadUrl({ projectId, eventId, fileId: row.id }).unwrap();
      } else if (dailyLogId) {
        fileDownloadUrlResult = await getDailyLogFileDownloadUrl({ projectId, dailyLogId, fileId: row.id }).unwrap();
      } else if (contractId) {
        fileDownloadUrlResult = await getContractFileDownloadUrl({ projectId, contractId, fileId: row.id }).unwrap();
      } else {
        fileDownloadUrlResult = await getFileDownloadUrl({ projectId, taskId, fileId: row.id }).unwrap();
      }

      if (row.contentType.startsWith(IMAGE_TYPE)) {
        const viewPhotoModal = <img src={fileDownloadUrlResult.url} loading="lazy" alt="" style={{ margin: 16 }} />;
        dispatch(openModal(viewPhotoModal));
      } else {
        window.open(fileDownloadUrlResult.url, '_blank');
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsFileDownloading(false);
    }
  };

  const downloadFile = async function (row: FileWithUrl) {
    setIsFileDownloading(true);

    try {
      let fileDownloadUrlResult;
      if (eventId) {
        fileDownloadUrlResult = await getEventFileDownloadUrl({ projectId, eventId, fileId: row.id }).unwrap();
      } else if (dailyLogId) {
        fileDownloadUrlResult = await getDailyLogFileDownloadUrl({ projectId, dailyLogId, fileId: row.id }).unwrap();
      } else if (contractId) {
        fileDownloadUrlResult = await getContractFileDownloadUrl({ projectId, contractId, fileId: row.id }).unwrap();
      } else {
        fileDownloadUrlResult = await getFileDownloadUrl({ projectId, taskId, fileId: row.id }).unwrap();
      }
      //console.log(fileDownloadUrlResult);

      const response = await fetch(fileDownloadUrlResult.url);
      if (!response.ok) {
        throw new Error('Failed to download file');
      }

      const blob = await response.blob();
      const objectUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');

      link.href = objectUrl;
      link.download = row.name;
      link.click();

      URL.revokeObjectURL(objectUrl);
    } catch (error) {
      console.log(error);
    } finally {
      setIsFileDownloading(false);
    }
  };

  async function handleDeleteFile() {
    if (rowToDelete) {
      setIsDeleting(true);
      try {
        if (!projectId) {
          throw new Error('Project not found');
        }
        if (eventId) {
          await deleteEventFile({ projectId, eventId, fileId: rowToDelete.id }).unwrap();
        } else if (dailyLogId) {
          await deleteDailyLogFile({ projectId, dailyLogId, fileId: rowToDelete.id }).unwrap();
        } else if (contractId) {
          await deleteContractFile({ projectId, contractId, fileId: rowToDelete.id }).unwrap();
        } else {
          await deleteFile({ projectId, taskId, fileId: rowToDelete.id }).unwrap();
        }
      } catch (error) {
        console.log(error);
        dispatch(showErrorAlert(error as AlertObject));
      } finally {
        setRowToDelete(null);
        setIsDeleting(false);
      }
    }
  }

  const renderFilters = () => (
    <FormControl size="sm">
      <FormLabel>File Tag</FormLabel>
      <Select
        value={fileTagFilter}
        onChange={(e, newValue) => setFileTagFilter(newValue)}
        size="sm"
        placeholder="Filter by file tag"
        slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
        multiple={true}
      >
        {[...new Set(rows.flatMap((item) => item.tags))].map((option) => (
          <Option key={option} value={option}>
            {option}
          </Option>
        ))}
      </Select>
    </FormControl>
  );

  const renderTableHead = () => (
    <thead>
      <tr>
        <th style={{ padding: '12px 6px' }}>
          <Link
            underline="none"
            color="primary"
            component="button"
            onClick={() => setOrder(order === '' ? 'asc' : order === 'asc' ? 'desc' : '')}
            fontWeight="lg"
            endDecorator={<ArrowDropDown />}
            sx={{
              '& svg': {
                transition: '0.2s',
                transform: order === '' ? 'rotate(90deg)' : order === 'desc' ? 'rotate(0deg)' : 'rotate(180deg)',
              },
            }}
          >
            File
          </Link>
        </th>
        {smallAndUp && (
          <>
            <th style={{ padding: '12px 6px' }}>Description</th>
            <th style={{ padding: '12px 6px' }}>Tags</th>
            <th style={{ padding: '12px 6px' }}>Date</th>
            <th style={{ padding: '12px 6px' }}>Uploaded By</th>
          </>
        )}
        {!hideRowMenu && <th style={{ width: 48, padding: '12px 6px' }}></th>}
      </tr>
    </thead>
  );

  function TableRowMenu({ row }: { row: FileWithUrl }) {
    return (
      <Dropdown>
        <MenuButton
          slots={{ root: IconButton }}
          slotProps={{ root: { variant: 'plain', color: 'neutral', size: 'sm' } }}
        >
          <MoreHorizRounded />
        </MenuButton>
        <Menu size="sm" sx={{ minWidth: 140 }}>
          <MenuItem onClick={() => viewFile(row)}>View</MenuItem>
          <Divider />
          <MenuItem onClick={() => downloadFile(row)}>Download</MenuItem>
          <Divider />
          <MenuItem
            onClick={() => {
              dispatch(
                openModal(
                  <ProjectFileEditModal
                    projectId={projectId}
                    taskId={taskId}
                    eventId={eventId}
                    dailyLogId={dailyLogId}
                    contractId={contractId}
                    file={row}
                  />
                )
              );
            }}
          >
            Edit
          </MenuItem>
          <Divider />
          <MenuItem
            color="danger"
            onClick={() => {
              setRowToDelete(row);
              setModalOpen(true);
            }}
          >
            Delete
          </MenuItem>
        </Menu>
      </Dropdown>
    );
  }

  function TableRow({ row }: { row: FileWithUrl }) {
    const iconName = convertFileTypeToIcon(row.contentType);
    // @ts-expect-error Element implicitly has an 'any' type TODO: Fix
    const FileIconComponent = iconsMap[iconName];

    return (
      <tr key={row.id} style={{ backgroundColor: 'var(--joy-palette-common-white)' }}>
        <td>
          <Link onClick={() => viewFile(row)}>
            {row.contentType.startsWith(IMAGE_TYPE) ? (
              <img
                src={row.thumbnailUrl}
                alt=""
                height={32}
                width={32}
                style={{ marginRight: 8 }}
                onError={(e) => {
                  if (e.target && row.url) {
                    (e.target as HTMLImageElement).src = row.url;
                  }
                }}
              />
            ) : (
              <FileIconComponent style={{ fontSize: 32, marginRight: 8, color: 'var(--joy-palette-secondary-500)' }} />
            )}
            <Typography level="body-xs">{row.name}</Typography>
          </Link>
        </td>
        {smallAndUp && (
          <>
            <td>{row.description}</td>
            <td>
              <FileTagsComponent tags={row.tags} />
            </td>
            <td>
              <DateTimeComponent date={row.updatedAt} />
            </td>
            <td>
              <MemberComponent userId={row.createdByUserId} projectId={projectId} showName={true} />
            </td>
          </>
        )}
        {!hideRowMenu && (
          <td>
            <TableRowMenu row={row} />
          </td>
        )}
      </tr>
    );
  }

  if (isFileDownloading || isDeleting) return <CircularProgressComponent />;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <TableComponent
        renderFilters={renderFilters}
        renderTableHead={renderTableHead}
        TableRow={TableRow}
        rows={filteredRows}
        comparator={getComparator(order, 'name')}
        hideSearch={hideSearch}
      />
      <Modal
        open={modalOpen}
        onClose={() => {
          setRowToDelete(null);
          setModalOpen(false);
        }}
      >
        <ModalDialog variant="outlined" role="alertdialog">
          <DialogTitle>
            <WarningRounded />
            Confirmation
          </DialogTitle>
          <Divider />
          <DialogContent>Are you sure you want to delete this file?</DialogContent>
          <DialogActions>
            <Button variant="solid" color="danger" onClick={() => handleDeleteFile()}>
              Delete file
            </Button>
            <Button variant="plain" color="neutral" onClick={() => setModalOpen(false)}>
              Cancel
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </Box>
  );
}
