import { AddCircle } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardOverflow,
  Checkbox,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  Input,
  Option,
  Select,
  Stack,
  Textarea,
  Typography,
} from '@mui/joy';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

import { Contract, ContractStatus, ContractType, File, FileWithUrl, UserProjectRole } from '@builder-bud/common';
import {
  AlertObject,
  CONTRACT_TERMS_OPTIONS,
  CONTRACT_TYPE_OPTIONS,
  ContractFormData,
  ContractSchemaResolver,
  getContractSubmitData,
  getDefaultContractFormValues,
  getUserProjectRole,
  openModal,
  showErrorAlert,
  showInfoAlert,
  useAppDispatch,
  useAppSelector,
  useCreateContractLineItemMutation,
} from '@builder-bud/common-ui';

import ContractStatusComponent from '../../components/contract-status.component';
import { DatePickerComponent } from '../../components/date-picker.component';
import FilesTableComponent from '../files/files-table.component';
import ContractLineItemTableComponent from './contract-line-item-table.component';
import ContractLineItemForm from './contract-line-item.form';

export default function ContractForm({
  contract,
  files,
  saveContract,
}: {
  contract: Partial<Contract>;
  files: File[];
  saveContract: any;
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [createContractLineItem] = useCreateContractLineItemMutation();

  const me = useAppSelector((state) => state.auth.me);
  const userProjectRole = getUserProjectRole(contract.projectId, me?.projects);

  const [saving, setSaving] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isDirty },
  } = useForm<ContractFormData>({
    defaultValues: getDefaultContractFormValues(contract),
    resolver: ContractSchemaResolver,
  });

  async function saveDraft(data: ContractFormData) {
    setSaving(true);
    try {
      const contractData = getContractSubmitData(data);
      const response = await saveContract({
        ...contractData,
        ...{ id: contract.id, projectId: contract.projectId },
      }).unwrap();
      dispatch(showInfoAlert(contract.id ? 'Successfully updated work order' : 'Successfully created work order'));
      if (matchPath(`/projects/${response.projectId}/contracts/new`, location.pathname)) {
        navigate(`/projects/${response.projectId}/contracts/${response.id}`, { replace: true });
      }
    } catch (error) {
      console.log(error);
      dispatch(showErrorAlert(error as AlertObject));
    } finally {
      setSaving(false);
    }
  }

  async function sendToHomeowner(data: ContractFormData) {
    setSaving(true);
    try {
      const contractData = getContractSubmitData(data);
      await saveContract({
        ...contractData,
        ...{ id: contract.id, projectId: contract.projectId, status: ContractStatus.Published },
      }).unwrap();
      dispatch(showInfoAlert('Successfully sent contract to the Homeowner'));
    } catch (error) {
      console.log(error);
      dispatch(showErrorAlert(error as AlertObject));
    } finally {
      setSaving(false);
    }
  }

  async function editContract(data: ContractFormData) {
    setSaving(true);
    try {
      await saveContract({ id: contract.id, projectId: contract.projectId, status: ContractStatus.Draft }).unwrap();
    } catch (error) {
      console.log(error);
      dispatch(showErrorAlert(error as AlertObject));
    } finally {
      setSaving(false);
    }
  }

  async function markPaid(data: ContractFormData) {
    setSaving(true);
    try {
      await saveContract({ id: contract.id, projectId: contract.projectId, status: ContractStatus.Paid }).unwrap();
    } catch (error) {
      console.log(error);
      dispatch(showErrorAlert(error as AlertObject));
    } finally {
      setSaving(false);
    }
  }

  const type = watch('type');
  const isInvoice = type === ContractType.ProgressInvoice || type === ContractType.Invoice;
  const disabled = contract.status !== ContractStatus.Draft && !!contract.id;

  return (
    <Stack rowGap={2}>
      <Card sx={{ backgroundColor: 'var(--joy-palette-common-white)' }}>
        <Box
          sx={{
            display: 'flex',
            mb: 1,
            gap: 1,
            flexDirection: { xs: 'column', sm: 'row' },
            alignItems: { xs: 'start', sm: 'center' },
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
        >
          <Typography level="title-md">Work order info</Typography>
          <ContractStatusComponent contract={contract as Contract} />
        </Box>
        <Divider />
        <Grid container spacing={2}>
          <Grid xs={12} sm={6} md={4} lg={3}>
            <Controller
              control={control}
              name="type"
              render={({ field: { onChange, onBlur, value: controlValue } }) => (
                <FormControl error={!!errors.type} required>
                  <FormLabel>Work order type</FormLabel>
                  <Select
                    value={controlValue}
                    onChange={(e, newValue) => {
                      onChange(newValue);
                    }}
                    onBlur={onBlur}
                    disabled={!!contract.id}
                  >
                    {CONTRACT_TYPE_OPTIONS.map((option) => (
                      <Option key={option.value} value={option.value}>
                        {option.label}
                      </Option>
                    ))}
                  </Select>
                  {errors.type && <FormHelperText>{errors.type.message}</FormHelperText>}
                </FormControl>
              )}
            />
          </Grid>
          {type && (
            <Grid container xs={12} spacing={2}>
              <Grid container xs={12} sm={8} spacing={2}>
                <Grid xs={12}>
                  <Controller
                    control={control}
                    name="name"
                    render={({ field: { onChange, onBlur, value: controlValue } }) => (
                      <FormControl error={!!errors.name} required>
                        <FormLabel>Work order name</FormLabel>
                        <Input value={controlValue} onBlur={onBlur} onChange={onChange} disabled={disabled} />
                        {errors.name && <FormHelperText>{errors.name.message}</FormHelperText>}
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid xs={12}>
                  <Controller
                    control={control}
                    name="description"
                    render={({ field: { onChange, onBlur, value: controlValue } }) => (
                      <FormControl error={!!errors.description}>
                        <FormLabel>Description</FormLabel>
                        <Textarea
                          value={controlValue}
                          onBlur={onBlur}
                          onChange={onChange}
                          minRows={isInvoice ? 8 : 4}
                          disabled={disabled}
                        />
                        {errors.description && <FormHelperText>{errors.description.message}</FormHelperText>}
                      </FormControl>
                    )}
                  />
                </Grid>
              </Grid>
              <Grid container xs={12} sm={4} spacing={2}>
                <Grid xs={12}>
                  <Controller
                    control={control}
                    name="reference"
                    render={({ field: { onChange, onBlur, value: controlValue } }) => (
                      <FormControl error={!!errors.reference}>
                        <FormLabel>Reference</FormLabel>
                        <Textarea
                          value={controlValue}
                          onBlur={onBlur}
                          onChange={onChange}
                          minRows={1}
                          disabled={disabled}
                        />
                        {errors.reference && <FormHelperText>{errors.reference.message}</FormHelperText>}
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid xs={12}>
                  <Controller
                    control={control}
                    name="terms"
                    render={({ field: { onChange, onBlur, value: controlValue } }) => (
                      <FormControl error={!!errors.terms}>
                        <FormLabel>Terms</FormLabel>
                        <Select
                          value={controlValue}
                          onChange={(e, newValue) => {
                            onChange(newValue);
                          }}
                          onBlur={onBlur}
                          disabled={disabled}
                        >
                          {CONTRACT_TERMS_OPTIONS.map((option) => (
                            <Option key={option.value} value={option.value}>
                              {option.label}
                            </Option>
                          ))}
                        </Select>
                        {errors.terms && <FormHelperText>{errors.terms.message}</FormHelperText>}
                      </FormControl>
                    )}
                  />
                </Grid>
                {isInvoice && (
                  <Grid xs={12}>
                    <Controller
                      control={control}
                      name="invoiceDate"
                      render={({ field: { onChange, onBlur, value: controlValue } }) => (
                        <FormControl error={!!errors.dueDate}>
                          <FormLabel>Invoice Date</FormLabel>
                          <DatePickerComponent value={controlValue} onChange={onChange} disabled={disabled} />
                          {errors.invoiceDate && <FormHelperText>{errors.invoiceDate.message}</FormHelperText>}
                        </FormControl>
                      )}
                    />
                  </Grid>
                )}
                <Grid xs={12}>
                  <Controller
                    control={control}
                    name="dueDate"
                    render={({ field: { onChange, onBlur, value: controlValue } }) => (
                      <FormControl error={!!errors.dueDate}>
                        <FormLabel>Due Date</FormLabel>
                        <DatePickerComponent value={controlValue} onChange={onChange} disabled={disabled} />
                        {errors.dueDate && <FormHelperText>{errors.dueDate.message}</FormHelperText>}
                      </FormControl>
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>

        {contract.id && (
          <>
            <Stack sx={{ mb: 1 }} direction="row" justifyContent="space-between" alignItems="center">
              <Typography level="title-md">Line items</Typography>
              {userProjectRole === UserProjectRole.Contractor && contract.status === ContractStatus.Draft && (
                <Stack
                  direction="row"
                  alignItems="center"
                  onClick={() => {
                    const contractLineItemModal = (
                      <ContractLineItemForm
                        contractLineItem={{ contractId: contract.id }}
                        projectId={contract.projectId!}
                        contractType={contract.type!}
                        saveContractLineItem={createContractLineItem}
                      />
                    );
                    dispatch(openModal(contractLineItemModal));
                  }}
                >
                  <IconButton size="sm">
                    <AddCircle />
                  </IconButton>
                  <Typography level="body-xs">Add line item</Typography>
                </Stack>
              )}
            </Stack>
            <ContractLineItemTableComponent
              projectId={contract.projectId!}
              contractType={contract.type!}
              contractStatus={contract.status!}
              rows={contract.lineItems ? contract.lineItems : []}
            />
          </>
        )}

        {files.length > 0 && (
          <>
            <Typography level="title-md">Files</Typography>
            <FilesTableComponent rows={files as FileWithUrl[]} hideSearch={true} />
          </>
        )}

        <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
          <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
            <Controller
              control={control}
              name="signatureRequired"
              render={({ field: { onChange, onBlur, value: controlValue } }) => (
                <Checkbox label="Signature required" checked={controlValue} onChange={onChange} disabled={disabled} />
              )}
            />

            {contract.status === ContractStatus.Draft && (
              <>
                <Button
                  size="sm"
                  variant="outlined"
                  onClick={() => (contract.id ? reset() : navigate(-1))}
                  disabled={!isDirty && !!contract.id}
                >
                  Cancel
                </Button>
                <Button size="sm" variant="solid" onClick={handleSubmit(saveDraft)} disabled={!isDirty || saving}>
                  Save draft
                </Button>
                {!!contract.id && (
                  <Button
                    size="sm"
                    variant="solid"
                    onClick={handleSubmit(sendToHomeowner)}
                    disabled={contract.lineItems?.length === 0 || saving}
                  >
                    Send to homeowner
                  </Button>
                )}
              </>
            )}
            {contract.status === ContractStatus.Published && (
              <Button size="sm" variant="solid" onClick={handleSubmit(editContract)} disabled={saving}>
                Edit
              </Button>
            )}
            {isInvoice && contract.status === ContractStatus.Approved && (
              <Button size="sm" variant="solid" onClick={handleSubmit(markPaid)} disabled={saving}>
                Mark Paid
              </Button>
            )}
          </CardActions>
        </CardOverflow>
      </Card>
    </Stack>
  );
}
