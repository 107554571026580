import { BaseResponse, DateWithoutTime, WithTimestamps } from './common';
import { FileWithUrl } from './file';
import { NotificationType } from './notification';
import { ProjectId } from './project';
import { UserId } from './user';

export const TASK_ID_PREFIX = 'task_';

export type TaskId = `${typeof TASK_ID_PREFIX}${string}`;

export function isTaskId(id: string): id is TaskId {
  return id.startsWith(TASK_ID_PREFIX);
}

export type Task = WithTimestamps<{
  id: TaskId;
  projectId: ProjectId;
  name: string;
  description?: string;
  status: TaskStatus;
  parentTaskId?: TaskId;
  dueDate?: DateWithoutTime;
  completedAt?: Date;
  notes?: string;
  order: number;
  assignedToUserIds: UserId[];
  // should always be populated in response if parent task exists
  parentTask?: Task;
  // only populated in responses for a single task
  subTasks?: Task[];
  createdByUserId: UserId;
}>;

export type TaskWithFiles = Task & {
  files: FileWithUrl[];
};

export type CreateTaskRequest = {
  parentTaskId?: TaskId;
  name: string;
  description?: string | null;
  status?: TaskStatus;
  dueDate?: DateWithoutTime;
  assignedToUserIds?: UserId[];
  notes?: string | null;
};

export type UpdateTaskRequest = {
  parentTaskId?: TaskId;
  name?: string;
  description?: string | null;
  status?: TaskStatus;
  dueDate?: DateWithoutTime;
  completedAt?: Date;
  assignedToUserIds?: UserId[];
  notes?: string | null;
};

export type CreateTaskResponse = BaseResponse<Task>;

export type GetTaskResponse = BaseResponse<TaskWithFiles>;

export type GetTasksResponse = BaseResponse<Task[]>;

export type UpdateTaskResponse = BaseResponse<Task>;

export type DeleteTaskResponse = BaseResponse<void>;

export enum TaskStatus {
  NotStarted = 'NOT_STARTED',
  InProgress = 'IN_PROGRESS',
  Paused = 'PAUSED',
  Completed = 'COMPLETED',
  Archived = 'ARCHIVED',
}

export const TASK_STATUS_LABELS: { [key in TaskStatus]: string } = {
  [TaskStatus.NotStarted]: 'Not started',
  [TaskStatus.InProgress]: 'In progress',
  [TaskStatus.Paused]: 'Paused',
  [TaskStatus.Completed]: 'Completed',
  [TaskStatus.Archived]: 'Archived',
};

export type TaskStatusChangeNotification = {
  title: string;
  body: string;
  data: {
    type: NotificationType.TaskStatusChange;
    taskId: TaskId;
    taskName: string;
    taskStatus: string;
    projectId: ProjectId;
    projectName: string;
  };
};

export type TaskNewAssignmentNotification = {
  title: string;
  body: string;
  data: {
    type: NotificationType.TaskNewAssignment;
    taskId: TaskId;
    taskName: string;
    taskStatus: string;
    projectId: ProjectId;
    projectName: string;
  };
};
