import { BaseResponse, WithTimestamps } from './common';
import { ProjectId, ReferencedProject, UserProjectRole } from './project';
import { User, UserId } from './user';

export const INVITATION_ID_PREFIX = 'invn_';

export type InvitationId = `${typeof INVITATION_ID_PREFIX}${string}`;

export enum InvitationStatus {
  Pending = 'PENDING',
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED',
}

export const INVITATION_STATUS_LABELS: { [key in InvitationStatus]: string } = {
  [InvitationStatus.Pending]: 'Pending',
  [InvitationStatus.Accepted]: 'Accepted',
  [InvitationStatus.Rejected]: 'Ignored',
};

const INVITATION_STATUS_RANK_MAP = {
  [InvitationStatus.Pending]: 0,
  [InvitationStatus.Accepted]: 1,
  [InvitationStatus.Rejected]: 2,
};

export function sortByInvitationStatus(a: Invitation, b: Invitation): number {
  return INVITATION_STATUS_RANK_MAP[a.status] - INVITATION_STATUS_RANK_MAP[b.status];
}

export type Invitation = WithTimestamps<{
  id: InvitationId;
  projectId: ProjectId;
  userId?: UserId;
  email?: string;
  phone?: string;
  projectRole: UserProjectRole;
  status: InvitationStatus;
  createdByUserId: UserId;
}>;

export type InvitedUser = Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'appRole' | 'profileImageUrl'>;

export type SentInvitation = Invitation & {
  project: ReferencedProject;
  invitedUser?: InvitedUser; // populated if invitee is a registered user
};

export type ReceivedInvitation = Omit<Invitation, 'project'> & {
  project: ReferencedProject;
};

export type InvitationNeedsConfirmation = {
  needsConfirmation: true;
};

export type CreateInvitationRequest = {
  email?: string;
  phone?: string;
  projectRole: UserProjectRole;
  isConfirmed?: boolean;
};

export type UpdateInvitationRequest = {
  status: InvitationStatus.Accepted | InvitationStatus.Rejected;
};

export type DeleteInvitationRequest = Record<string, never>;

export type CreateInvitationResponse = BaseResponse<Invitation | InvitationNeedsConfirmation>;

export type GetInvitationsResponse = BaseResponse<{
  sent: SentInvitation[];
  received: ReceivedInvitation[];
}>;

export type UpdateInvitationResponse = BaseResponse<Invitation>;

export type DeleteInvitationResponse = BaseResponse<undefined>;
