import { BaseResponse, DateWithoutTime, WithTimestamps } from './common';
import { FileWithUrl } from './file';
import { NotificationType } from './notification';
import { ProjectId, ReferencedProject } from './project';
import { UserId } from './user';

export const EVENT_ID_PREFIX = 'event_';
export type EventId = `${typeof EVENT_ID_PREFIX}${string}`;

export enum EventRelationship {
  Assigned = 'ASSIGNED',
  Interested = 'INTERESTED',
}

export type EventRelatedUser = {
  id: UserId;
  relationship: EventRelationship;
};

export type Event = WithTimestamps<{
  id: EventId;
  projectId: ProjectId;
  name: string;
  notes?: string;
  startDate: DateWithoutTime;
  endDate: DateWithoutTime;
  createdByUserId: UserId;
  project?: ReferencedProject;
  relatedUsers: EventRelatedUser[];
}>;

export type EventWithFiles = Event & {
  files: FileWithUrl[];
};

export type CreateEventRequest = {
  name: string;
  notes?: string;
  startDate: DateWithoutTime;
  endDate: DateWithoutTime;
  relatedUsers?: EventRelatedUser[];
};

export type CreateEventResponse = BaseResponse<Event>;

export type GetEventResponse = BaseResponse<EventWithFiles>;

export type GetEventsResponse = BaseResponse<Event[]>;

export type UpdateEventRequest = {
  name?: string;
  notes?: string | null;
  startDate?: DateWithoutTime;
  endDate?: DateWithoutTime;
  relatedUsers?: EventRelatedUser[] | null;
};

export type UpdateEventResponse = BaseResponse<Event>;

export type DeleteEventResponse = BaseResponse<void>;

export type EventNewAssignmentNotification = {
  title: string;
  body: string;
  data: {
    type: NotificationType.EventNewAssignment;
    eventId: EventId;
    eventName: string;
    projectId: ProjectId;
    projectName: string;
  };
};

export function getAssignedUserIds(event: Event): UserId[] {
  return event.relatedUsers.filter((user) => user.relationship === EventRelationship.Assigned).map((user) => user.id);
}
