import { CircularProgress, Stack, Theme, useTheme } from '@mui/joy';

export default function AuthLoadingScreen() {
  const styles = makeStyle(useTheme());

  return (
    <Stack sx={styles.container}>
      <img src="../../../assets/builder-bud-logo.png" style={{ width: 240 }} alt="" />
      <CircularProgress />
    </Stack>
  );
}

const makeStyle = (theme: Theme) => {
  return {
    container: {
      display: 'flex',
      minHeight: '100dvh',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.vars.palette.primary[500],
      gap: 8,
    },
  };
};
