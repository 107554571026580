import { ChevronRightRounded, HomeRounded } from '@mui/icons-material';
import { Badge, TabPanel } from '@mui/joy';
import Box from '@mui/joy/Box';
import Breadcrumbs from '@mui/joy/Breadcrumbs';
import Link from '@mui/joy/Link';
import Tab, { tabClasses } from '@mui/joy/Tab';
import TabList from '@mui/joy/TabList';
import Tabs from '@mui/joy/Tabs';
import Typography from '@mui/joy/Typography';
import { skipToken } from '@reduxjs/toolkit/query';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

import { UserWithFiles } from '@builder-bud/common';
import { getInvitationsBadge, useAppSelector, useGetMeInvitationsQuery, useGetMeQuery } from '@builder-bud/common-ui';

import CircularProgressComponent from '../../components/circular-progress.component';
import ErrorLoadingComponent from '../../components/error-loading.component';
import NotFoundComponent from '../../components/not-found.component';
import AccountDetailsForm from './account-details.form';
import InvitationsForm from './invitations.form';
import ProfileForm from './profile.form';
import SubscriptionsForm from './subscriptions.form';

export default function SettingsScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const token = useAppSelector((state) => state.auth.token);

  const {
    data: me = null,
    isLoading: isMeLoading,
    isFetching: isMeFetching,
    isError: isMeError,
    error: meError,
  } = useGetMeQuery(token ? undefined : skipToken);

  const {
    data: invitations,
    isLoading: isInvitationsLoading,
    isFetching: isInvitationsFetching,
    isError: isInvitationsError,
    error: invitationsError,
  } = useGetMeInvitationsQuery();
  const invitationsBadge = getInvitationsBadge(invitations?.received);

  const isLoading = isMeLoading || isInvitationsLoading;
  const isFetching = isMeFetching || isInvitationsFetching;
  const isError = isMeError || isInvitationsError;
  const error = isMeError ? meError : isInvitationsError ? invitationsError : {};

  if (isLoading || isFetching) return <CircularProgressComponent />;
  if (isError) return <ErrorLoadingComponent error={error} />;
  if (!me) return <NotFoundComponent />;

  const getDefaultTabValue = () => {
    return matchPath('settings/profile', location.pathname)
      ? 1
      : matchPath('settings/invitations', location.pathname)
      ? 2
      : matchPath('settings/subscriptions', location.pathname)
      ? 3
      : 0;
  };

  return (
    <Box sx={{ flex: 1, width: '100%' }}>
      <Box
        sx={{
          position: 'sticky',
          top: { sm: -100, md: -110 },
          bgcolor: 'background.body',
        }}
      >
        <Box sx={{ px: { xs: 2, md: 6 } }}>
          <Breadcrumbs
            size="sm"
            aria-label="breadcrumbs"
            separator={<ChevronRightRounded fontSize="small" />}
            sx={{ pl: 0 }}
          >
            <Link underline="none" color="neutral" href="/" aria-label="Home">
              <HomeRounded />
            </Link>
            <Typography color="primary" fontWeight={500} fontSize={12}>
              Settings
            </Typography>
          </Breadcrumbs>
          <Typography level="h2" component="h1" sx={{ mt: 1, mb: 2 }}>
            Account settings
          </Typography>
        </Box>
        <Tabs
          defaultValue={0}
          value={getDefaultTabValue()}
          onChange={(e, selectedTab) => {
            selectedTab === 0 && navigate(`/settings/`, { replace: true });
            selectedTab === 1 && navigate(`/settings/profile`, { replace: true });
            selectedTab === 2 && navigate(`/settings/invitations`, { replace: true });
            selectedTab === 3 && navigate(`/settings/subscriptions`, { replace: true });
          }}
          sx={{
            bgcolor: 'transparent',
          }}
        >
          <TabList
            size="sm"
            sx={{
              pl: { xs: 0, md: 4 },
              justifyContent: 'left',
              [`&& .${tabClasses.root}`]: {
                fontWeight: '600',
                //flex: 'initial',
                color: 'text.tertiary',
                [`&.${tabClasses.selected}`]: {
                  bgcolor: 'transparent',
                  color: 'text.primary',
                  '&::after': {
                    height: '2px',
                    bgcolor: 'primary.500',
                  },
                },
              },
              overflow: 'auto',
              scrollSnapType: 'x mandatory',
              '&::-webkit-scrollbar': { display: 'none' },
            }}
          >
            <Tab sx={{ flex: 'none', scrollSnapAlign: 'start', borderRadius: '6px 6px 0 0' }} value={0}>
              Account Details
            </Tab>
            <Tab sx={{ flex: 'none', scrollSnapAlign: 'start', borderRadius: '6px 6px 0 0' }} value={1}>
              Profile Management
            </Tab>
            <Tab sx={{ flex: 'none', scrollSnapAlign: 'start', borderRadius: '6px 6px 0 0' }} value={2}>
              Invitations
              {invitationsBadge > 0 && <Badge badgeContent={invitationsBadge} sx={{ marginLeft: 1 }} />}
            </Tab>
            <Tab sx={{ flex: 'none', scrollSnapAlign: 'start', borderRadius: '6px 6px 0 0' }} value={3}>
              Subscription Management
            </Tab>
          </TabList>
          <TabPanel value={0} sx={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
            <AccountDetailsForm me={me} />
          </TabPanel>
          <TabPanel value={1} sx={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
            <ProfileForm me={me} files={(me as UserWithFiles).files} />
          </TabPanel>
          <TabPanel value={2} sx={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
            <InvitationsForm />
          </TabPanel>
          <TabPanel value={3} sx={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
            <SubscriptionsForm me={me} />
          </TabPanel>
        </Tabs>
      </Box>
    </Box>
  );
}
