// Import the functions you need from the SDKs you need
import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getMessaging } from 'firebase/messaging';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.NX_PUBLIC_API_KEY,
  authDomain: process.env.NX_PUBLIC_AUTH_DOMAIN,
  projectId: process.env.NX_PUBLIC_PROJECT_ID,
  storageBucket: process.env.NX_PUBLIC_STORAGE_BUCKET,
  messagingSenderId: process.env.NX_PUBLIC_MESSAGING_SENDER_ID,
  appId: process.env.NX_PUBLIC_APP_ID,
  measurementId: process.env.NX_PUBLIC_MEASUREMENT_ID,
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);

export const auth = getAuth(firebaseApp);

export const messaging = getMessaging(firebaseApp);

export const analytics = getAnalytics(firebaseApp);

export const VAPID_KEY = process.env.NX_PUBLIC_VAPID_KEY;
