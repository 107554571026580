import { ChatId, isChatId } from './chat';
import { BaseResponse, WithTimestamps } from './common';
import { ContractId, isContractId } from './contract';
import { DailyLogId, isDailyLogId } from './daily-log';
import { EventId } from './event';
import { ProjectId, isProjectId } from './project';
import { TaskId, isTaskId } from './task';
import { UserId, isUserId } from './user';

export const FILE_ID_PREFIX = 'file_';

export type FileId = `${typeof FILE_ID_PREFIX}${string}`;

export function isFileId(id: string): id is FileId {
  return id.startsWith(FILE_ID_PREFIX);
}

export type FileRefId = ProjectId | TaskId | UserId | ChatId | ContractId | DailyLogId | EventId;

export enum FileType {
  ProfileImage = 'PROFILE_IMAGE',
  ProjectCoverImage = 'PROJECT_COVER_IMAGE',
  License = 'LICENSE',
  COI = 'COI',
}

export type File = WithTimestamps<{
  id: FileId;
  refId: FileRefId;
  name: string;
  description?: string;
  contentType: string;
  size: number;
  type?: FileType;
  tags: string[];
  createdByUserId: UserId;
}>;

export type FileWithUrl = File & {
  url: string;
  thumbnailUrl?: string;
};

export type ReferencedFile = {
  id: FileId;
  name: string;
  description?: string;
  contentType: string;
  size: number;
  type?: FileType;
};

export type ProjectFile = Omit<File, 'refId'> & {
  refId: ProjectId;
};

export type TaskFile = Omit<File, 'refId'> & {
  refId: TaskId;
};

export type UserFile = Omit<File, 'refId'> & {
  refId: UserId;
};

export type ChatFile = Omit<File, 'refId'> & {
  refId: ChatId;
};

export type ContractFile = Omit<File, 'refId'> & {
  refId: ContractId;
};

export type DailyLogFile = Omit<File, 'refId'> & {
  refId: DailyLogId;
};

export function isProjectFile(file: File): file is ProjectFile {
  return isProjectId(file.refId);
}

export function isTaskFile(file: File): file is TaskFile {
  return isTaskId(file.refId);
}

export function isUserFile(file: File): file is UserFile {
  return isUserId(file.refId);
}

export function isChatFile(file: File): file is ChatFile {
  return isChatId(file.refId);
}

export function isContractFile(file: File): file is ContractFile {
  return isContractId(file.refId);
}

export function isDailyLogFile(file: File): file is DailyLogFile {
  return isDailyLogId(file.refId);
}

export type SignedUrls = {
  url: string;
  thumbnailUrl?: string;
};

export type CreateFileRequest = {
  // special case where client tells us the id that was previously
  // provided to them as part of the upload url
  id: FileId;
  name: string;
  description?: string;
  contentType: string;
  size: number;
  fileType?: FileType;
  tags?: string[];
};

export type UpdateFileRequest = {
  name?: string;
  description?: string;
  tags?: string[];
};

export type CreateFileResponse = BaseResponse<File>;

export type UpdateFileResponse = BaseResponse<File>;

export type DeleteFileResponse = BaseResponse<void>;

export type GetFileResponse = BaseResponse<File>;

export type GetFilesResponse = BaseResponse<File[]>;

export type SignedUploadUrlData = {
  id: FileId;
  url: string;
};

export type SignedDownloadUrlData = SignedUrls;

export type GetSignedUploadUrlResponse = BaseResponse<SignedUploadUrlData>;
export type GetSignedDownloadUrlResponse = BaseResponse<SignedDownloadUrlData>;
