import { yupResolver } from '@hookform/resolvers/yup';
import { parse } from 'date-fns';
import * as yup from 'yup';

import {
  CreateTaskRequest,
  TASK_STATUS_LABELS,
  Task,
  TaskId,
  TaskStatus,
  UpdateTaskRequest,
  UserId,
  dateToDateWithoutTime,
} from '@builder-bud/common';

import { BBListItem } from './common-ui';
import { UserOption } from './users.slice';

export const TASK_STATUS_OPTIONS: BBListItem[] = [
  { value: TaskStatus.NotStarted, label: TASK_STATUS_LABELS[TaskStatus.NotStarted], color: 'error' },
  { value: TaskStatus.InProgress, label: TASK_STATUS_LABELS[TaskStatus.InProgress], color: 'primary' },
  { value: TaskStatus.Paused, label: TASK_STATUS_LABELS[TaskStatus.Paused], color: 'tertiary' },
  { value: TaskStatus.Completed, label: TASK_STATUS_LABELS[TaskStatus.Completed], color: 'secondary' },
  { value: TaskStatus.Archived, label: TASK_STATUS_LABELS[TaskStatus.Archived], color: 'outline' },
] as const;

export function getTaskStatusLabel(value: TaskStatus): string {
  return TASK_STATUS_LABELS[value] ?? '';
}

export type TaskFormData = {
  parentTaskId?: TaskId;
  name: string;
  description?: string;
  status?: TaskStatus;
  assignedToUserIds?: UserId[];
  dueDate?: Date;
  notes?: string;
};

export const TaskSchemaResolver = yupResolver(
  yup.object<TaskFormData>().shape({
    parentTaskId: yup.string<TaskId>().optional(),
    name: yup.string().required('Name is required').max(256, 'Name must be 256 characters or less'),
    description: yup.string().max(2048, 'Description must be 2048 characters or less'),
    status: yup.mixed<TaskStatus>().oneOf(Object.values(TaskStatus)),
    assignedToUserIds: yup.array().optional(),
    dueDate: yup.date().optional(),
    notes: yup.string().max(2048, 'Notes must be 2048 characters or less'),
  })
);

export function getDefaultTaskFormValues(users: UserOption[], task: Partial<Task>): TaskFormData {
  const defaultStatus = TASK_STATUS_OPTIONS.find((option) => option.value === task.status);

  return {
    parentTaskId: task.parentTaskId,
    name: task.name ? task.name : '',
    description: task.description ? task.description : '',
    status: defaultStatus?.value as TaskStatus,
    assignedToUserIds: task.assignedToUserIds,
    //Convert from string 'YYYY-MM-DD' to Date object
    dueDate: task.dueDate ? parse(task.dueDate, 'yyyy-MM-dd', new Date()) : undefined,
    notes: task.notes ? task.notes : '',
  };
}

export function getTaskSubmitData(data: TaskFormData): CreateTaskRequest | UpdateTaskRequest {
  return {
    //Send null to clear values
    parentTaskId: data.parentTaskId,
    name: data.name,
    description: data.description === '' ? null : data.description,
    status: data.status,
    assignedToUserIds: data.assignedToUserIds,
    //Convert from string 'YYYY-MM-DD' to Date object
    dueDate: data.dueDate ? dateToDateWithoutTime(data.dueDate) : undefined,
    notes: data.notes === '' ? null : data.notes,
  };
}
