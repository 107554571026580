import { FirebaseAuthTypes } from '@react-native-firebase/auth';
import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';
import { Auth } from 'firebase/auth';

import { User } from '@builder-bud/common';

import { api } from './api';

type AuthState = {
  auth: Auth | null;
  user: FirebaseAuthTypes.User | null;
  token: string | null;
  fcmToken: string | null;
  isOnboarding: boolean;
  me: User | null;
};

const initialState: AuthState = {
  auth: null,
  user: null,
  token: null,
  fcmToken: null,
  isOnboarding: false,
  me: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth: (state, action: PayloadAction<Auth | null>) => {
      state.auth = action.payload;
    },
    setAuthUser: (state, action: PayloadAction<FirebaseAuthTypes.User | null>) => {
      state.user = action.payload;
    },
    setAuthToken: (state, action: PayloadAction<string | null>) => {
      state.token = action.payload;
    },
    setFCMToken: (state, action: PayloadAction<string | null>) => {
      state.fcmToken = action.payload;
    },
    setIsOnboarding: (state, action: PayloadAction<boolean>) => {
      state.isOnboarding = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action): action is PayloadAction<User | null> => action.type === 'api/executeQuery/fulfilled',
      (state, action) => {
        if (action.payload?.id && action.payload.id.startsWith('user_')) {
          state.me = action.payload;
        }
      }
    );
  },
});

export const { setAuth, setAuthUser, setAuthToken, setFCMToken, setIsOnboarding } = authSlice.actions;
