import AspectRatio, { AspectRatioProps } from '@mui/joy/AspectRatio';

export default function BuilderBudIcon(props: AspectRatioProps) {
  const { sx, ...other } = props;
  return (
    <AspectRatio
      ratio="1"
      variant="plain"
      {...other}
      sx={[
        {
          width: 36,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <div>
        <img src="../../assets/builder-bud-icon.png" style={{ borderRadius: 8 }} alt="" />
      </div>
    </AspectRatio>
  );
}
