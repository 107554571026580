import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
  APP_ROLE_LABELS,
  AppRole,
  ProjectId,
  UpdateUserRequest,
  User,
  UserId,
  UserProjectRole,
} from '@builder-bud/common';

import { BBListItem } from './common-ui';

export const PHONE_REGEX =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const WEBSITE_REGEX =
  /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g;

export const APP_ROLE_OPTIONS: BBListItem[] = [
  { label: APP_ROLE_LABELS[AppRole.Contractor], value: AppRole.Contractor },
  { label: APP_ROLE_LABELS[AppRole.Homeowner], value: AppRole.Homeowner },
  { label: APP_ROLE_LABELS[AppRole.Subcontractor], value: AppRole.Subcontractor },
  { label: APP_ROLE_LABELS[AppRole.ProjectManager], value: AppRole.ProjectManager },
  { label: APP_ROLE_LABELS[AppRole.Other], value: AppRole.Other },
] as const;

export function getAppRoleLabel(value: AppRole): string {
  return APP_ROLE_LABELS[value] ?? '';
}

export function getUserProjectRole(
  projectId?: ProjectId,
  userProjects?: {
    id: ProjectId;
    projectRole: UserProjectRole;
    invitedByUserId?: UserId;
  }[]
) {
  return userProjects?.find((p) => p.id === projectId)?.projectRole;
}

export enum RegistrationIntent {
  ContractorSearches = 'CONTRACTOR_SEARCH',
  ReadyToBuild = 'READY_TO_BUILD',
  FindWork = 'FIND_WORK',
  ActiveProject = 'ACTIVE_PROJECT',
  ProjectInvitation = 'PROJECT_INVITATION',
  Unknown = 'UNKNOWN',
}

export const HOMEOWNER_REGISTRATION_INTENT_OPTIONS = [
  { label: 'Looking for a contractor', value: RegistrationIntent.ContractorSearches },
  { label: 'Ready to get building/active project', value: RegistrationIntent.ReadyToBuild },
  { label: 'Received a project invitation', value: RegistrationIntent.ProjectInvitation },
  { label: 'Just looking around', value: RegistrationIntent.Unknown },
] as const;

export const CONTRACTOR_REGISTRATION_INTENT_OPTIONS = [
  { label: 'Looking for clients', value: RegistrationIntent.FindWork },
  { label: 'Have an active project that needs managing', value: RegistrationIntent.ActiveProject },
  { label: 'Received a project invitation', value: RegistrationIntent.ProjectInvitation },
  { label: 'Something else', value: RegistrationIntent.Unknown },
] as const;

export type UpdateEmailFormData = {
  email: string;
  currentPassword: string;
};

export const UpdateEmailSchemaResolver = yupResolver(
  yup.object<UpdateEmailFormData>().shape({
    email: yup
      .string()
      .email('Please enter a valid email')
      .required('Email is required')
      .max(256, 'Email must be 256 character or less'),
    currentPassword: yup
      .string()
      .required('Current Password is required')
      .min(8, 'Password must be 8 characters or more')
      .max(256, 'Password must be 256 characters or less'),
  })
);

export type UpdatePhoneFormData = {
  phone: string;
};

export type UpdateCodeFormData = {
  code: string;
};

export const UpdatePhoneSchemaResolver = yupResolver(
  yup.object<UpdatePhoneFormData>().shape({
    phone: yup
      .string()
      .required('Phone Number is required')
      .min(10, 'Please enter a valid 10 digit phone number')
      .max(10, 'Please enter a valid 10 digit phone number')
      .matches(PHONE_REGEX, 'Please enter a valid 10 digit phone number'),
  })
);

export const UpdateCodeSchemaResolver = yupResolver(
  yup.object<UpdateCodeFormData>().shape({
    code: yup
      .string()
      .required('Code is required')
      .min(6, 'Please enter a valid 6 digit code')
      .max(6, 'Please enter a valid 6 digit code'),
  })
);

export type UpdatePasswordFormData = {
  password: string;
  currentPassword: string;
};

export const UpdatePasswordSchemaResolver = yupResolver(
  yup.object<UpdatePasswordFormData>().shape({
    currentPassword: yup
      .string()
      .required('Current Password is required')
      .min(8, 'Password must be 8 characters or more')
      .max(256, 'Password must be 256 characters or less'),
    password: yup
      .string()
      .required('Password is required')
      .min(8, 'Password must be 8 characters or more')
      .max(256, 'Password must be 256 characters or less'),
  })
);

export type UserFormData = {
  firstName: string;
  lastName: string;
  businessName?: string;
  displayName?: string;
  license?: string;
  bio?: string;
  website?: string;
};

export const UserSchemaResolver = yupResolver(
  yup.object<UserFormData>().shape({
    firstName: yup
      .string()
      .required('First Name is required')
      .min(1, 'First Name must be 1 character or more')
      .max(256, 'First Name must be 256 characters or less'),
    lastName: yup
      .string()
      .required('Last Name is required')
      .min(1, 'Last Name must be 1 character or more')
      .max(256, 'Last Name must be 256 characters or less'),
    businessName: yup.string().optional().max(128, 'Business Name must be 128 characters or less'),
    displayName: yup.string().optional().max(128, 'Display Name must be 128 characters or less'),
    license: yup.string().optional().max(128, 'License must be 128 characters or less'),
    bio: yup.string().optional().max(128, 'Bio must be 1024 characters or less'),
    website: yup
      .string()
      .optional()
      .matches(WEBSITE_REGEX, { message: 'Please enter a valid URL', excludeEmptyString: true }),
  })
);

export function getDefaultUserFormValues(user: User): UserFormData {
  return {
    firstName: user.firstName,
    lastName: user.lastName,
    businessName: user?.businessName,
    displayName: user?.displayName,
    license: user?.license,
    bio: user?.bio,
    website: user?.website,
  };
}

export function getUserSubmitData(data: UserFormData): UpdateUserRequest {
  return {
    firstName: data.firstName,
    lastName: data.lastName,
    businessName: data.businessName,
    displayName: data.displayName,
    license: data.license,
    bio: data.bio,
    website: data.website,
  };
}

export type DeleteAccountFormData = {
  currentPassword: string;
};

export const DeleteAccountSchemaResolver = yupResolver(
  yup.object<DeleteAccountFormData>().shape({
    currentPassword: yup
      .string()
      .required('Current Password is required')
      .min(8, 'Password must be 8 characters or more')
      .max(256, 'Password must be 256 characters or less'),
  })
);
