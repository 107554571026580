// Palettes generated from the following colors at the link below
// primary: #135450
// secondary: #3772FF
// tertiary: #F6A620
// neutral: #847979
// success: #135450
// danger: #AB3428
// warning: #F6A620
// http://mcg.mbitson.com/#!?primary=%23135450&secondary=%233772ff&tertiary=%23f6a620&neutral=%23847979&success=%23135450&error=%23ab3428&warning=%23f6a620&mcgpalette7=%23e1dbd4&themename=builderbud
import { extendTheme } from '@mui/joy/styles';
import type { PaletteRange } from '@mui/joy/styles';

declare module '@mui/joy/styles' {
  interface ColorPalettePropOverrides {
    secondary: true;
    tertiary: true;
    //Normalize mobile and web color variants
    error: true;
    outline: true;
  }

  interface Palette {
    secondary: PaletteRange;
    tertiary: PaletteRange;
    error: PaletteRange;
    outline: PaletteRange;
  }
}

export const theme = extendTheme({
  fontFamily: {
    display: 'Poppins', // applies to `h1`–`h4`
    body: 'Poppins', // applies to `title-*` and `body-*`
  },
  components: {
    JoyInput: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...((ownerState.variant === 'plain' || ownerState.variant === 'outlined') && {
            backgroundColor: 'var(--joy-palette-common-white)',
          }),
        }),
      },
    },
    JoySelect: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...((ownerState.variant === 'plain' || ownerState.variant === 'outlined') && {
            backgroundColor: 'var(--joy-palette-common-white)',
          }),
        }),
      },
    },
    JoyTextarea: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...((ownerState.variant === 'plain' || ownerState.variant === 'outlined') && {
            backgroundColor: 'var(--joy-palette-common-white)',
          }),
        }),
      },
    },
    JoyButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.color === 'secondary' &&
            ownerState.variant === 'solid' && {
              color: 'var(--joy-palette-common-white)',
            }),
        }),
      },
    },
    JoyMenuButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.color === 'secondary' &&
            ownerState.variant === 'solid' && {
              color: 'var(--joy-palette-common-white)',
            }),
        }),
      },
    },
    JoyMenu: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.color === 'secondary' &&
            ownerState.variant === 'solid' && {
              color: 'var(--joy-palette-common-white)',
            }),
        }),
      },
    },
  },

  colorSchemes: {
    light: {
      palette: {
        primary: {
          50: '#e3eaea',
          100: '#b8cccb',
          200: '#89aaa8',
          300: '#5a8785',
          400: '#366e6a',
          500: '#135450',
          600: '#114d49',
          700: '#0e4340',
          800: '#0b3a37',
          900: '#062927',
        },
        secondary: {
          50: '#e7eeff',
          100: '#c3d5ff',
          200: '#9bb9ff',
          300: '#739cff',
          400: '#5587ff',
          500: '#3772ff',
          600: '#316aff',
          700: '#2a5fff',
          800: '#2355ff',
          900: '#1642ff',
          solidBg: 'var(--joy-palette-secondary-400)',
          solidActiveBg: 'var(--joy-palette-secondary-500)',
          outlinedBorder: 'var(--joy-palette-secondary-500)',
          outlinedColor: 'var(--joy-palette-secondary-700)',
          outlinedActiveBg: 'var(--joy-palette-secondary-100)',
          softColor: 'var(--joy-palette-secondary-800)',
          softBg: 'var(--joy-palette-secondary-200)',
          softActiveBg: 'var(--joy-palette-secondary-300)',
          plainColor: 'var(--joy-palette-secondary-700)',
          plainActiveBg: 'var(--joy-palette-secondary-100)',
        },
        tertiary: {
          50: '#fef4e4',
          100: '#fce4bc',
          200: '#fbd390',
          300: '#f9c163',
          400: '#f7b341',
          500: '#f6a620',
          600: '#f59e1c',
          700: '#f39518',
          800: '#f28b13',
          900: '#ef7b0b',
          solidBg: 'var(--joy-palette-tertiary-400)',
          solidActiveBg: 'var(--joy-palette-tertiary-500)',
          outlinedBorder: 'var(--joy-palette-tertiary-500)',
          outlinedColor: 'var(--joy-palette-tertiary-700)',
          outlinedActiveBg: 'var(--joy-palette-tertiary-100)',
          softColor: 'var(--joy-palette-tertiary-800)',
          softBg: 'var(--joy-palette-tertiary-200)',
          softActiveBg: 'var(--joy-palette-tertiary-300)',
          plainColor: 'var(--joy-palette-tertiary-700)',
          plainActiveBg: 'var(--joy-palette-tertiary-100)',
        },
        neutral: {
          50: '#F6F0E7', //Custom change to match the preferred background color
          100: '#dad7d7',
          200: '#c2bcbc',
          300: '#a9a1a1',
          400: '#968d8d',
          500: '#847979',
          600: '#7c7171',
          700: '#716666',
          800: '#675c5c',
          900: '#544949',
        },

        success: {
          50: '#e3eaea',
          100: '#b8cccb',
          200: '#89aaa8',
          300: '#5a8785',
          400: '#366e6a',
          500: '#135450',
          600: '#114d49',
          700: '#0e4340',
          800: '#0b3a37',
          900: '#062927',
        },
        danger: {
          50: '#f5e7e5',
          100: '#e6c2bf',
          200: '#d59a94',
          300: '#c47169',
          400: '#b85248',
          500: '#ab3428',
          600: '#a42f24',
          700: '#9a271e',
          800: '#912118',
          900: '#80150f',
        },
        warning: {
          50: '#fef4e4',
          100: '#fce4bc',
          200: '#fbd390',
          300: '#f9c163',
          400: '#f7b341',
          500: '#f6a620',
          600: '#f59e1c',
          700: '#f39518',
          800: '#f28b13',
          900: '#ef7b0b',
        },

        error: {
          500: '#ab3428',
        },
        outline: {
          500: 'rgb(111, 121, 120)',
        },
      },
    },
  },
});
