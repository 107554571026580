import { Card, Divider, Stack, Typography } from '@mui/joy';
import { useState } from 'react';

import { ProjectId } from '@builder-bud/common';
import {
  AlertObject,
  InvitationsFormData,
  setIsOnboarding,
  showErrorAlert,
  showInfoAlert,
  useAppDispatch,
  useCreateInvitationMutation,
} from '@builder-bud/common-ui';

import CircularProgressComponent from '../../../components/circular-progress.component';
import InvitationsForm from '../../projects/invitations.form';

export default function HomeownerInvitePeopleStep({ projectId }: { projectId?: ProjectId }) {
  const dispatch = useAppDispatch();
  const [saving, setSaving] = useState(false);

  const [createInvitation] = useCreateInvitationMutation();

  async function sendInvitations(data: InvitationsFormData) {
    setSaving(true);
    try {
      const invites = data.invites ? data.invites : [];
      for (const invite of invites) {
        await createInvitation({
          projectId: projectId!,
          email: invite.email,
          projectRole: invite.projectRole,
          isConfirmed: true,
        }).unwrap();
      }
      dispatch(showInfoAlert('Successfully sent invitations'));
      dispatch(setIsOnboarding(false));
    } catch (error) {
      console.log(error);
      dispatch(showErrorAlert(error as AlertObject));
    } finally {
      setSaving(false);
    }
  }

  function skip() {
    dispatch(setIsOnboarding(false));
  }

  if (saving) return <CircularProgressComponent />;

  return (
    <Card style={{ backgroundColor: 'var(--joy-palette-common-white)' }}>
      <Stack sx={{ mb: 1 }}>
        <Typography level="title-md">Invite people to your project.</Typography>
      </Stack>
      <Divider />
      <InvitationsForm onSubmit={sendInvitations} onCancel={skip} cancelButtonLabel="Skip" />
    </Card>
  );
}
