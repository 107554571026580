import { Event, UserId, UserProjectRole, isNonHomeownerProjectRole } from '@builder-bud/common';

export function canUserCreateEvent(userProjectRole: UserProjectRole): boolean {
  return isNonHomeownerProjectRole(userProjectRole);
}

export function canUserReadEvent(event: Event, userId: UserId): boolean {
  const relatedUserIds = event.relatedUsers.map((u) => u.id);
  return [event.createdByUserId, ...relatedUserIds].includes(userId);
}

export function canUserUpdateEvent(event: Event, userProjectRole: UserProjectRole, userId: UserId): boolean {
  const relatedUserIds = event.relatedUsers.map((u) => u.id);
  return isNonHomeownerProjectRole(userProjectRole) && [event.createdByUserId, ...relatedUserIds].includes(userId);
}

export function canUserUpdateEventRelatedUsers(event: Event, userId: UserId): boolean {
  return event.createdByUserId === userId;
}

export function canUserDeleteEvent(event: Event, userId: UserId): boolean {
  return event.createdByUserId === userId;
}
