import { CheckRounded, KeyboardArrowDownRounded } from '@mui/icons-material';
import { Box, Step, StepIndicator, Stepper, Typography } from '@mui/joy';
import { useState } from 'react';

import { ProjectId } from '@builder-bud/common';
import { ContractorSteps, OnboardingStep, RegistrationIntent } from '@builder-bud/common-ui';

import BuilderBudIcon from '../../../components/builderbud-icon.component';
import ContractorContinueProfileStep from './contractor-continue-profile.step';
import ContractorCreateProfileStep from './contractor-create-profile.step';
import ContractorCreateProjectStep from './contractor-create-project.step';
import ContractorInvitePeopleStep from './contractor-invite-people-step';
import ContractorPaymentStep from './contractor-payment-step';
import ContractorWhyStep from './contractor-why.step';

export default function ContractorOnboardingScreen() {
  const [activeStep, setActiveStep] = useState<ContractorSteps>(ContractorSteps.contractorWhy);
  const [registrationIntent, setRegistrationIntent] = useState<RegistrationIntent>();
  const [projectId, setProjectId] = useState<ProjectId>();

  const steps = [{ index: ContractorSteps.contractorWhy, label: `Why You're Here` }];
  switch (registrationIntent) {
    case undefined:
      steps.push({ index: ContractorSteps.contractorEllipsis, label: '...' });
      break;
    case RegistrationIntent.FindWork:
      steps.push({ index: ContractorSteps.contractorCreateProfile, label: 'Create Profile' });
      steps.push({ index: ContractorSteps.contractorPayment, label: 'Payment' });
      steps.push({ index: ContractorSteps.contractorContinueProfile, label: 'Finish Profile' });
      break;
    case RegistrationIntent.ActiveProject:
      steps.push({ index: ContractorSteps.contractorCreateProject, label: 'Create Project' });
      steps.push({ index: ContractorSteps.contractorPayment, label: 'Payment' });
      steps.push({ index: ContractorSteps.contractorInvitePeople, label: 'Invite Others' });
      break;
  }

  const renderStep = (step: OnboardingStep) => {
    return (
      <Step
        key={step.index}
        active={activeStep === step.index ? true : false}
        completed={activeStep > step.index ? true : false}
        orientation="vertical"
        indicator={
          <StepIndicator color="secondary" variant={activeStep >= step.index ? 'solid' : 'outlined'}>
            {activeStep === step.index && <KeyboardArrowDownRounded sx={styles.stepIcon} />}
            {activeStep > step.index && <CheckRounded sx={styles.stepIcon} />}
          </StepIndicator>
        }
        sx={{ textAlign: 'center' }}
      >
        {step.label}
      </Step>
    );
  };

  return (
    <Box>
      <Box sx={styles.leftContainer} />
      <Box sx={styles.rightContainer}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100dvh',
            width: '100%',
            px: 2,
          }}
        >
          <Box
            component="header"
            sx={{
              py: 2,
              gap: 2,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box sx={{ gap: 2, display: 'flex', alignItems: 'center' }}>
              <BuilderBudIcon />
              <Typography level="title-md">Welcome to BuilderBud</Typography>
            </Box>
            <Typography level="title-md">Construction made easy</Typography>
          </Box>
          <Box component="main" sx={styles.onboardingContainer}>
            <Stepper size="md">{steps.map((step) => renderStep(step))}</Stepper>

            {activeStep === ContractorSteps.contractorWhy && (
              <ContractorWhyStep setStep={setActiveStep} setRegistrationIntent={setRegistrationIntent} />
            )}
            {activeStep === ContractorSteps.contractorCreateProfile && (
              <ContractorCreateProfileStep setStep={setActiveStep} />
            )}
            {activeStep === ContractorSteps.contractorCreateProject && (
              <ContractorCreateProjectStep setStep={setActiveStep} setProjectId={setProjectId} />
            )}
            {activeStep === ContractorSteps.contractorPayment && (
              <ContractorPaymentStep registrationIntent={registrationIntent} setStep={setActiveStep} />
            )}
            {activeStep === ContractorSteps.contractorContinueProfile && (
              <ContractorContinueProfileStep setStep={setActiveStep} />
            )}
            {activeStep === ContractorSteps.contractorInvitePeople && (
              <ContractorInvitePeopleStep projectId={projectId} />
            )}
          </Box>

          <Box component="footer" sx={{ py: 2 }}>
            <Typography level="body-xs" textAlign="center">
              © Builder Bud {new Date().getFullYear()}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

const styles = {
  leftContainer: {
    position: 'fixed',
    left: 0,
    top: 0,
    bottom: 0,
    right: { xs: 0, md: '70vw' },
    transition: 'background-image var(--Transition-duration), left var(--Transition-duration) !important',
    transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
    backgroundColor: 'var(--joy-palette-primary-500)',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundImage: 'url(../../assets/splash-screen.png)',
  },

  rightContainer: {
    position: 'fixed',
    right: 0,
    top: 0,
    bottom: 0,
    left: { xs: 0, md: '30vw' },
    transition: 'width var(--Transition-duration)',
    transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
    zIndex: 1,
    display: 'flex',
    overflowY: 'scroll',
    backgroundColor: 'var(--joy-palette-common-white)',
  },

  onboardingContainer: {
    my: 'auto',
    py: 2,
    pb: 5,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    maxWidth: '100%',
    mx: 'auto',
    borderRadius: 'sm',
  },

  stepIcon: {
    color: 'var(--joy-palette-common-white)',
  },
};
