/* eslint-disable jsx-a11y/anchor-is-valid */
import { ArrowDropDown, MoreHorizRounded, WarningRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Dropdown,
  FormControl,
  FormLabel,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  Modal,
  ModalDialog,
  Option,
  Select,
  Typography,
  useTheme,
} from '@mui/joy';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Task, TaskStatus, canUserDeleteTask } from '@builder-bud/common';
import {
  AlertObject,
  TASK_STATUS_OPTIONS,
  selectCurrentUserId,
  showErrorAlert,
  showInfoAlert,
  useAppDispatch,
  useAppSelector,
  useDeleteTaskMutation,
} from '@builder-bud/common-ui';

import CircularProgressComponent from '../../components/circular-progress.component';
import DateComponent from '../../components/date.component';
import MembersComponent from '../../components/members.component';
import ProjectNameComponent from '../../components/project-name';
import { Order, getComparator } from '../../components/table';
import TableComponent from '../../components/table.component';
import TaskStatusComponent from '../../components/task-status.component';

export default function TaskTableComponent({
  rows,
  showProject = false,
  hideSearch = false,
}: {
  rows: Task[];
  showProject?: boolean;
  hideSearch?: boolean;
}) {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const smallAndUp = useMediaQuery(theme.breakpoints.up('sm'));
  const [deleteTask] = useDeleteTaskMutation();

  const currentUserId = useAppSelector(selectCurrentUserId);

  const [order, setOrder] = useState<Order>('');
  const [statusFilter, setStatusFilter] = useState<string[]>([
    TaskStatus.NotStarted,
    TaskStatus.InProgress,
    TaskStatus.Paused,
    TaskStatus.Completed,
  ]);
  const [modalOpen, setModalOpen] = useState(false);
  const [rowToDelete, setRowToDelete] = useState<Task | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const filteredRows = statusFilter.length ? rows.filter((row) => statusFilter.includes(row.status)) : rows;

  async function handleDeleteTask() {
    if (rowToDelete) {
      setIsDeleting(true);
      try {
        await deleteTask({ projectId: rowToDelete.projectId, id: rowToDelete.id }).unwrap();
        dispatch(showInfoAlert('Successfully deleted Task'));
      } catch (error) {
        console.log(error);
        dispatch(showErrorAlert(error as AlertObject));
      } finally {
        setRowToDelete(null);
        setIsDeleting(false);
      }
    }
  }

  const renderFilters = () => (
    <FormControl size="sm">
      <FormLabel>Status</FormLabel>
      <Select
        value={statusFilter}
        onChange={(e, newValue) => setStatusFilter(newValue)}
        size="sm"
        placeholder="Filter by status"
        slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
        multiple={true}
      >
        {TASK_STATUS_OPTIONS.map((option, i) => (
          <Option key={option.value} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>
    </FormControl>
  );

  const renderTableHead = () => (
    <thead>
      <tr>
        <th style={{ padding: '12px 6px' }}>
          <Link
            underline="none"
            color="primary"
            component="button"
            onClick={() => setOrder(order === '' ? 'asc' : order === 'asc' ? 'desc' : '')}
            fontWeight="lg"
            endDecorator={<ArrowDropDown />}
            sx={{
              '& svg': {
                transition: '0.2s',
                transform: order === '' ? 'rotate(90deg)' : order === 'desc' ? 'rotate(0deg)' : 'rotate(180deg)',
              },
            }}
          >
            Task
          </Link>
        </th>
        {showProject && smallAndUp && <th style={{ padding: '12px 6px' }}>Project name</th>}
        <th style={{ padding: '12px 6px' }}>Status</th>
        {smallAndUp && (
          <>
            <th style={{ padding: '12px 6px' }}>Assigned To</th>
            <th style={{ padding: '12px 6px' }}>Due Date</th>
          </>
        )}
        <th style={{ width: 48, padding: '12px 6px' }}></th>
      </tr>
    </thead>
  );

  function TableRowMenu({ row }: { row: Task }) {
    const navigate = useNavigate();

    return (
      <Dropdown>
        <MenuButton
          slots={{ root: IconButton }}
          slotProps={{ root: { variant: 'plain', color: 'neutral', size: 'sm' } }}
        >
          <MoreHorizRounded />
        </MenuButton>
        <Menu size="sm" sx={{ minWidth: 140 }}>
          <MenuItem onClick={() => navigate(`/projects/${row.projectId}/tasks/${row.id}`)}>View</MenuItem>
          {currentUserId && canUserDeleteTask(row, currentUserId) && (
            <MenuItem
              color="danger"
              onClick={() => {
                setRowToDelete(row);
                setModalOpen(true);
              }}
            >
              Delete
            </MenuItem>
          )}
        </Menu>
      </Dropdown>
    );
  }

  function TableRow({ row }: { row: Task }) {
    const navigate = useNavigate();

    return (
      <tr key={row.id} style={{ backgroundColor: 'var(--joy-palette-common-white)' }}>
        <td>
          <Link onClick={() => navigate(`/projects/${row.projectId}/tasks/${row.id}`)}>
            <Typography level="body-xs">{row.name}</Typography>
          </Link>
        </td>
        {showProject && smallAndUp && (
          <td>
            <Typography>
              <ProjectNameComponent projectId={row.projectId} />
            </Typography>
          </td>
        )}
        <td>
          <TaskStatusComponent task={row} />
        </td>
        {smallAndUp && (
          <>
            <td>
              <MembersComponent userIds={row.assignedToUserIds} projectId={row.projectId} />
            </td>
            <td>
              <DateComponent date={row.dueDate} />
            </td>
          </>
        )}
        <td>
          <TableRowMenu row={row} />
        </td>
      </tr>
    );
  }

  if (isDeleting) return <CircularProgressComponent />;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <TableComponent
        renderFilters={renderFilters}
        renderTableHead={renderTableHead}
        TableRow={TableRow}
        rows={filteredRows}
        comparator={getComparator(order, 'name')}
        hideSearch={hideSearch}
      />
      <Modal
        open={modalOpen}
        onClose={() => {
          setRowToDelete(null);
          setModalOpen(false);
        }}
      >
        <ModalDialog variant="outlined" role="alertdialog">
          <DialogTitle>
            <WarningRounded />
            Confirmation
          </DialogTitle>
          <Divider />
          <DialogContent>Are you sure you want to delete this task?</DialogContent>
          <DialogActions>
            <Button variant="solid" color="danger" onClick={() => handleDeleteTask()}>
              Delete task
            </Button>
            <Button variant="plain" color="neutral" onClick={() => setModalOpen(false)}>
              Cancel
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </Box>
  );
}
