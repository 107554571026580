import { CloseRounded } from '@mui/icons-material';
import { IconButton, Snackbar, Stack, Typography } from '@mui/joy';
import { useNavigate } from 'react-router-dom';

import {
  AlertType,
  handleRemoteMessageWebClick,
  hideAlert,
  useAppDispatch,
  useAppSelector,
} from '@builder-bud/common-ui';

import MemberComponent from './member.component';

export default function AlertComponent() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isVisible, type, info, error, message, notification } = useAppSelector((state) => state.alerts);

  return (
    <Snackbar
      variant="soft"
      color={type === AlertType.Info ? 'secondary' : type === AlertType.Error ? 'danger' : 'neutral'}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={isVisible}
      onClose={() => dispatch(hideAlert())}
      endDecorator={
        <IconButton
          onClick={() => dispatch(hideAlert())}
          color={type === AlertType.Info ? 'secondary' : type === AlertType.Error ? 'danger' : 'neutral'}
          size="sm"
        >
          <CloseRounded />
        </IconButton>
      }
      autoHideDuration={type === AlertType.Message || type === AlertType.Notification ? null : 5000}
      sx={{ zIndex: 20002 }}
    >
      {type === AlertType.Info ? (
        info
      ) : type === AlertType.Error ? (
        error
      ) : type === AlertType.Message ? (
        <Stack
          style={{ flexDirection: 'row', columnGap: 8 }}
          onClick={() => {
            dispatch(hideAlert());
            navigate(`/projects/${message?.projectId}/chats/${message?.chatId}/messages`);
          }}
        >
          <Stack style={{ justifyContent: 'center' }}>
            <MemberComponent userId={message?.createdByUserId} projectId={message?.projectId} />
          </Stack>
          <Stack style={{ flex: 1, rowGap: 8 }}>
            <Typography level="body-lg">{message?.chatName}</Typography>
            <Typography level="body-md">{message?.projectName}</Typography>
            <Typography level="body-md">{message?.content}</Typography>
          </Stack>
        </Stack>
      ) : (
        <Stack
          style={{ flex: 1, rowGap: 8 }}
          onClick={() => {
            dispatch(hideAlert());
            handleRemoteMessageWebClick(notification, navigate);
          }}
        >
          <Typography level="body-lg">{notification?.title}</Typography>
          <Typography level="body-md">{notification?.projectName}</Typography>
          <Typography level="body-md">{notification?.body}</Typography>
        </Stack>
      )}
    </Snackbar>
  );
}
