import { ChevronRightRounded, HomeRounded } from '@mui/icons-material';
import { Box, Breadcrumbs, Link, Tab, TabList, TabPanel, Tabs, Typography, tabClasses } from '@mui/joy';

import { ChatId, ProjectId } from '@builder-bud/common';
import {
  getUserOptions,
  useAppSelector,
  useEditChatMutation,
  useGetChatQuery,
  useGetUsersQuery,
} from '@builder-bud/common-ui';

import CircularProgressComponent from '../../components/circular-progress.component';
import ErrorLoadingComponent from '../../components/error-loading.component';
import NotFoundComponent from '../../components/not-found.component';
import ProjectNameComponent from '../../components/project-name';
import { useRequiredParams } from '../../utils';
import ChatForm from './chat.form';

export default function ChatDetailsScreen() {
  const { projectId, chatId } = useRequiredParams<{ projectId: ProjectId; chatId: ChatId }>();
  const [updateChat] = useEditChatMutation();

  const me = useAppSelector((state) => state.auth.me);

  const {
    data: chat,
    isLoading: isChatLoading,
    isFetching: isChatFetching,
    isError: isChatError,
    error: chatError = {},
  } = useGetChatQuery({ projectId, chatId });

  const {
    data: users = [],
    isLoading: isUsersLoading,
    isFetching: isUsersFetching,
    isError: isUsersError,
    error: usersError = {},
  } = useGetUsersQuery();

  const isLoading = isChatLoading || isUsersLoading;
  const isFetching = isChatFetching || isUsersFetching;
  const isError = isChatError || isUsersError;
  const error = isChatError ? chatError : isUsersError ? usersError : {};

  if (isLoading || isFetching) return <CircularProgressComponent />;
  if (isError) return <ErrorLoadingComponent error={error} />;
  if (!chat) return <NotFoundComponent />;

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={<ChevronRightRounded fontSize="small" />}
          sx={{ pl: 0 }}
        >
          <Link underline="none" color="neutral" href="/" aria-label="Home">
            <HomeRounded />
          </Link>
          <Link underline="hover" color="neutral" href="/projects" fontSize={12} fontWeight={500}>
            Projects
          </Link>
          <Link underline="hover" color="neutral" href={`/projects/${chat.projectId}`} fontSize={12} fontWeight={500}>
            <ProjectNameComponent projectId={chat.projectId} />
          </Link>
          <Link
            underline="hover"
            color="neutral"
            href={`/projects/${chat.projectId}/chats`}
            fontSize={12}
            fontWeight={500}
          >
            Chats
          </Link>
          <Typography color="primary" fontWeight={500} fontSize={12}>
            {chat.name}
          </Typography>
        </Breadcrumbs>
      </Box>
      <Box
        sx={{
          display: 'flex',
          mb: 1,
          gap: 1,
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'start', sm: 'center' },
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Typography level="h2" component="h1">
          {chat.name}
        </Typography>
      </Box>

      <Tabs
        defaultValue={0}
        value={0}
        sx={{
          bgcolor: 'transparent',
        }}
      >
        <TabList
          size="sm"
          sx={{
            pl: { xs: 0, md: 4 },
            justifyContent: 'left',
            [`&& .${tabClasses.root}`]: {
              fontWeight: '600',
              //flex: 'initial',
              color: 'text.tertiary',
              [`&.${tabClasses.selected}`]: {
                bgcolor: 'transparent',
                color: 'text.primary',
                '&::after': {
                  height: '2px',
                  bgcolor: 'primary.500',
                },
              },
            },
            overflow: 'auto',
            scrollSnapType: 'x mandatory',
            '&::-webkit-scrollbar': { display: 'none' },
          }}
        >
          <Tab sx={{ flex: 'none', scrollSnapAlign: 'start', borderRadius: '6px 6px 0 0' }} value={0}>
            Details
          </Tab>
        </TabList>

        <TabPanel value={0}>
          <ChatForm
            chat={chat}
            saveChat={updateChat}
            users={getUserOptions(users, chat.projectId, me ? [me.id] : [])}
          />
        </TabPanel>
      </Tabs>
    </>
  );
}
