import {
  Box,
  Button,
  Card,
  CardActions,
  CardOverflow,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Input,
  Option,
  Select,
  Stack,
  Typography,
} from '@mui/joy';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Chat, canUserUpdateChat } from '@builder-bud/common';
import {
  AlertObject,
  ChatFormData,
  ChatSchemaResolver,
  UserOption,
  getChatSubmitData,
  getDefaultChatFormValues,
  showErrorAlert,
  showInfoAlert,
  useAppDispatch,
  useAppSelector,
} from '@builder-bud/common-ui';

export default function ChatForm({
  chat,
  saveChat,
  users,
}: {
  chat: Partial<Chat>;
  saveChat: any;
  users: UserOption[];
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const me = useAppSelector((state) => state.auth.me);
  const isAllowedUpdateChat = !chat.id || (me && canUserUpdateChat(chat as Chat, me.id));

  const [saving, setSaving] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<ChatFormData>({
    defaultValues: getDefaultChatFormValues(chat, me?.id),
    resolver: ChatSchemaResolver,
  });

  async function onSubmit(data: ChatFormData) {
    setSaving(true);
    try {
      const chatData = getChatSubmitData(data);
      const response = await saveChat({ ...chatData, chatId: chat.id, projectId: chat.projectId }).unwrap();
      dispatch(showInfoAlert(chat.id ? 'Successfully updated chat' : 'Successfully created chat'));
      navigate(`/projects/${response.projectId}/chats/${response.id}/messages`, { replace: true });
    } catch (error) {
      console.log(error);
      dispatch(showErrorAlert(error as AlertObject));
    } finally {
      setSaving(false);
    }
  }

  return (
    <Card style={{ backgroundColor: 'var(--joy-palette-common-white)' }}>
      <Box sx={{ mb: 1 }}>
        <Typography level="title-md">Chat info</Typography>
      </Box>
      <Divider />
      <Stack direction="row" spacing={3} sx={{ display: { xs: 'flex' }, my: 1 }}>
        <Stack spacing={2} sx={{ flexGrow: 1, display: 'grid' }}>
          <Grid container spacing={2}>
            <Grid xs={12} md={6}>
              <Controller
                control={control}
                name="name"
                render={({ field: { onChange, onBlur, value: controlValue } }) => (
                  <FormControl error={!!errors.name} required>
                    <FormLabel>Chat name</FormLabel>
                    <Input value={controlValue} onBlur={onBlur} onChange={onChange} disabled={!isAllowedUpdateChat} />
                    {errors.name && <FormHelperText>{errors.name.message}</FormHelperText>}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid xs={12}>
              <Controller
                control={control}
                name="additionalUserIds"
                render={({ field: { onChange, onBlur, value: controlValue } }) => (
                  <FormControl error={!!errors.additionalUserIds}>
                    <FormLabel>Additional users</FormLabel>
                    <Select
                      value={controlValue}
                      onChange={(e, newValue) => {
                        onChange(newValue);
                      }}
                      onBlur={onBlur}
                      multiple={true}
                      disabled={!isAllowedUpdateChat}
                    >
                      {users.map((option) => (
                        <Option key={option.value} value={option.value}>
                          {option.label}
                        </Option>
                      ))}
                    </Select>
                    {errors.additionalUserIds && <FormHelperText>{errors.additionalUserIds.message}</FormHelperText>}
                  </FormControl>
                )}
              />
            </Grid>
          </Grid>
        </Stack>
      </Stack>
      {isAllowedUpdateChat && (
        <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
          <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
            <Button
              size="sm"
              variant="outlined"
              onClick={() => (chat.id ? reset() : navigate(-1))}
              disabled={!isDirty && !!chat.id}
            >
              Cancel
            </Button>
            <Button size="sm" variant="solid" onClick={handleSubmit(onSubmit)} disabled={!isDirty || saving}>
              Save
            </Button>
          </CardActions>
        </CardOverflow>
      )}
    </Card>
  );
}
