import { Checkbox, Stack } from '@mui/joy';
import { useState } from 'react';

import { Task, TaskStatus } from '@builder-bud/common';
import { AlertObject, showErrorAlert, useAppDispatch, useEditTaskMutation } from '@builder-bud/common-ui';

import CircularProgressComponent from './circular-progress.component';

function PunchlistStatusComponent({
  task,
  allowUpdate = false,
  showTaskName = true,
}: {
  task: Task;
  allowUpdate?: boolean;
  showTaskName?: boolean;
}) {
  const dispatch = useAppDispatch();

  const [editTask] = useEditTaskMutation();

  const [saving, setSaving] = useState(false);

  async function updateStatus(status: TaskStatus) {
    setSaving(true);
    try {
      await editTask({ projectId: task.projectId, id: task.id, parentTaskId: task.parentTaskId, status }).unwrap();
    } catch (error) {
      console.log(error);
      dispatch(showErrorAlert(error as AlertObject));
    } finally {
      setSaving(false);
    }
  }

  if (saving) return <CircularProgressComponent />;

  return (
    <Stack>
      <Checkbox
        label={showTaskName ? task.name : 'Completed'}
        checked={task.status === TaskStatus.Completed}
        onChange={() => {
          updateStatus(task.status === TaskStatus.Completed ? TaskStatus.NotStarted : TaskStatus.Completed);
        }}
        disabled={!allowUpdate}
      />
    </Stack>
  );
}

export default PunchlistStatusComponent;
