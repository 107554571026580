import {
  Button,
  Card,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Option,
  Select,
  Stack,
  Typography,
} from '@mui/joy';
import { Dispatch, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import {
  AlertObject,
  ContractorContinueFormData,
  ContractorContinueSchemaResolver,
  ContractorSteps,
  PROJECT_TYPE_OPTIONS,
  getContractorContinueSubmitData,
  getDefaultContractorContinueFormValues,
  showErrorAlert,
  useAppDispatch,
  useUpdateMeMutation,
} from '@builder-bud/common-ui';

import GooglePlacesAutocomplete from '../../../components/google-places-autocomplete.component';

export default function ContractorContinueProfileStep({ setStep }: { setStep: Dispatch<ContractorSteps> }) {
  const dispatch = useAppDispatch();
  const [updateMe] = useUpdateMeMutation();
  const [saving, setSaving] = useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ContractorContinueFormData>({
    defaultValues: getDefaultContractorContinueFormValues(),
    resolver: ContractorContinueSchemaResolver,
  });

  async function onSubmit(data: ContractorContinueFormData) {
    setSaving(true);
    try {
      await updateMe(getContractorContinueSubmitData(data)).unwrap();
      setStep(ContractorSteps.contractorInvitePeople);
    } catch (error) {
      console.log(error);
      dispatch(showErrorAlert(error as AlertObject));
    } finally {
      setSaving(false);
    }
  }

  return (
    <Card style={{ backgroundColor: 'var(--joy-palette-common-white)' }}>
      <Stack sx={{ mb: 1 }}>
        <Typography level="title-md">Continue filling out your profile.</Typography>
        <Typography level="title-sm">
          Provide more information to increase your chances of finding new clients.
        </Typography>
      </Stack>
      <Divider />
      <Stack rowGap={2}>
        <Controller
          control={control}
          name="displayAddress"
          render={({ field: { onChange, onBlur, value: controlValue } }) => (
            <FormControl error={!!errors.displayAddress} required>
              <FormLabel>Address</FormLabel>
              <GooglePlacesAutocomplete value={controlValue} setValue={setValue} onChange={onChange} />
              {errors.displayAddress && <FormHelperText>{errors.displayAddress.message}</FormHelperText>}
            </FormControl>
          )}
        />

        <Controller
          control={control}
          name="projectTypes"
          render={({ field: { onChange, value: controlValue } }) => (
            <FormControl error={!!errors.projectTypes} required>
              <FormLabel>Project Type</FormLabel>
              <Select
                value={controlValue}
                onChange={(e, newValue) => {
                  onChange(newValue);
                }}
                multiple={true}
              >
                {PROJECT_TYPE_OPTIONS.map((option, i) => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
              {errors.projectTypes && <FormHelperText>{errors.projectTypes.message}</FormHelperText>}
            </FormControl>
          )}
        />

        <Controller
          control={control}
          name="license"
          render={({ field: { onChange, onBlur, value: controlValue } }) => (
            <FormControl error={!!errors.license}>
              <FormLabel>License #</FormLabel>
              <Input value={controlValue} onBlur={onBlur} onChange={onChange} />
              {errors.license && <FormHelperText>{errors.license.message}</FormHelperText>}
            </FormControl>
          )}
        />
      </Stack>
      <Button variant="solid" onClick={handleSubmit(onSubmit)} style={styles.button} disabled={saving}>
        Next
      </Button>
    </Card>
  );
}

const styles = {
  button: {
    width: 100,
    alignSelf: 'flex-end',
  },
};
