import { SearchResults, SearchResultsResponse } from '@builder-bud/common';

import { api } from './api';

export const dashboardSlice = api.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    search: builder.query<SearchResults, { q: String }>({
      query: (body) => ({
        url: `/dashboard/search?q=${body.q}`,
        method: 'GET',
      }),
      transformResponse: (response: SearchResultsResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to get search results');
      },
    }),
  }),
});

export const { useLazySearchQuery } = dashboardSlice;
