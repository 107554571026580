import { EmailAuthProvider, reauthenticateWithCredential, updateEmail } from '@firebase/auth';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Button, FormControl, FormHelperText, FormLabel, Grid, Input, Stack } from '@mui/joy';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import {
  AlertObject,
  UpdateEmailFormData,
  UpdateEmailSchemaResolver,
  showErrorAlert,
  showInfoAlert,
  useAppDispatch,
  useSynchUserMutation,
} from '@builder-bud/common-ui';

import CircularProgressComponent from '../../components/circular-progress.component';
import { auth } from '../login/firebase';

export default function UpdateEmailForm({ hideForm }: { hideForm: React.Dispatch<void> }) {
  const dispatch = useAppDispatch();

  const [synchUser] = useSynchUserMutation();

  const [isSaving, setSaving] = useState(false);
  const [secureTextEntry, setSecureTextEntry] = useState(true);
  const toggleSecureTextEntry = () => setSecureTextEntry(!secureTextEntry);

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty, errors },
  } = useForm<UpdateEmailFormData>({
    defaultValues: {
      email: '',
    },
    resolver: UpdateEmailSchemaResolver,
  });

  if (isSaving) return <CircularProgressComponent />;

  async function onSubmit(data: UpdateEmailFormData) {
    try {
      console.log('Updating email');
      setSaving(true);

      const currentUser = auth.currentUser;
      if (!currentUser || !currentUser.email) throw new Error('Current user not found');
      const credential = EmailAuthProvider.credential(currentUser.email, data.currentPassword);

      await reauthenticateWithCredential(currentUser, credential);
      await updateEmail(currentUser, data.email);
      reset();
      console.log('Email is updated in Firebase!');
      await synchUser().unwrap();
      console.log('User is synced');
      dispatch(showInfoAlert('Successfully updated email'));
      hideForm();
    } catch (error) {
      console.log(error);
      dispatch(showErrorAlert(error as AlertObject));
    } finally {
      setSaving(false);
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid xs={12}>
        <Controller
          control={control}
          name="email"
          render={({ field: { onChange, onBlur, value } }) => (
            <FormControl error={!!errors.email}>
              <FormLabel>New Email</FormLabel>
              <Input value={value} onBlur={onBlur} onChange={onChange} />
              {errors.email && <FormHelperText>{errors.email.message}</FormHelperText>}
            </FormControl>
          )}
        />
      </Grid>
      <Grid xs={12}>
        <Controller
          control={control}
          name="currentPassword"
          render={({ field: { onChange, onBlur, value } }) => (
            <FormControl error={!!errors.currentPassword}>
              <FormLabel>Current Password</FormLabel>
              <Input
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                type={secureTextEntry ? 'password' : ''}
                endDecorator={
                  secureTextEntry ? (
                    <Visibility
                      onClick={() => {
                        toggleSecureTextEntry();
                      }}
                    />
                  ) : (
                    <VisibilityOff
                      onClick={() => {
                        toggleSecureTextEntry();
                      }}
                    />
                  )
                }
              />
              {errors.currentPassword && <FormHelperText>{errors.currentPassword.message}</FormHelperText>}
            </FormControl>
          )}
        />
      </Grid>
      <Grid xs={12}>
        <Stack direction="row" sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }} spacing={1}>
          <Button variant="outlined" onClick={() => hideForm()}>
            Cancel
          </Button>
          <Button onClick={handleSubmit(onSubmit)} disabled={!isDirty}>
            Update email
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
}
