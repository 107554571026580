import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { UpdateFileRequest } from '@builder-bud/common';

export type MobileFile = {
  uri: string;
  type?: string;
  fileName?: string;
  fileSize?: number;
};

export type FileWithDescription = {
  name: string;
  type: string;
  size: number;
  lastModified: number;
  description?: string;
  tags: string[];
  webFile: File; //File object provided by HTML file input
  webFileUrl?: string; //String url generated by createObjectURL
  mobileFile: MobileFile; //File object provided by react-native-image-picker
};

export const MAX_IMAGE_FILE_SIZE = {
  maxFileSize: 15728640,
  warning: 'Max file size is 15 MB',
};

export const enum FileUploadStatus {
  InProgress = 'IN_PROGRESS',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
}

export const IMAGE_TYPE = 'image';
export const VIDEO_TYPE = 'video';

export type FileFormData = {
  name: string;
  description?: string;
  tags: string[];
};

export const FileSchemaResolver = yupResolver(
  yup.object<FileFormData>().shape({
    name: yup.string().required('Name is required').max(256, 'Name must be 256 characters or less'),
    description: yup.string().max(2048, 'Description must be 2048 characters or less'),
    tags: yup.array().required(),
  })
);

export function getDefaultFileFormValues(file: Partial<FileWithDescription>): FileFormData {
  return {
    name: file.name ? file.name : '',
    description: file.description ? file.description : '',
    tags: file.tags ? file.tags : new Array<string>(),
  };
}

export function getFileSubmitData(data: FileFormData): UpdateFileRequest {
  return {
    name: data.name,
    description: data.description === '' ? undefined : data.description,
    tags: data.tags,
  };
}

//There is special handling for these file tags
export const SPECIFICATIONS_FILE_TAG = 'Specifications';
export const PLANS_FILE_TAG = 'Plans';
