import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  ChatId,
  EventId,
  Message,
  NotificationType,
  ProjectId,
  TaskId,
  User,
  UserNotification,
} from '@builder-bud/common';

export const enum AlertType {
  Info = 'INFO',
  Error = 'ERROR',
  Message = 'MESSAGE',
  Notification = 'NOTIFICATION',
}

type AlertsState = {
  isVisible: boolean;
  type: AlertType;
  info: string | null;
  error: string | null;
  message: (Message & { chatName: string; chatId: ChatId; projectName: string; projectId: ProjectId }) | null;
  notification:
    | (UserNotification & {
        type: NotificationType;
        projectName?: string;
        projectId?: ProjectId;
        taskId?: TaskId;
        eventId?: EventId;
      })
    | null;
};

//TODO - consider renaming this to ErrorAlert
export type AlertObject = {
  error?: string;
  message?: string;
  data?: {
    message: string[] | string;
    statusCode: number;
  };
  notification?: UserNotification;
  status?: number;
  user?: User;
};

const initialState: AlertsState = {
  isVisible: false,
  type: AlertType.Message,
  info: null,
  error: null,
  message: null,
  notification: null,
};

export const alertsSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    showInfoAlert: (state, action: PayloadAction<string>) => {
      state.info = action.payload;
      (state.type = AlertType.Info), (state.isVisible = true);
    },
    showErrorAlert: (state, action: PayloadAction<AlertObject>) => {
      const payload = action.payload;

      if (payload.message) {
        state.error = payload.message;
      } else if (payload.error) {
        state.error = payload.error.toString();
      } else if (payload.data && payload.data.message) {
        state.error = payload.data.message.toString();
      } else {
        state.error = 'An unexpected error occurred';
      }
      state.type = AlertType.Error;
      state.isVisible = true;
    },
    showMessageAlert: (
      state,
      action: PayloadAction<Message & { chatName: string; projectName: string; projectId: ProjectId }>
    ) => {
      state.message = action.payload;
      state.type = AlertType.Message;
      state.isVisible = true;
    },
    showNotificationAlert: (
      state,
      action: PayloadAction<
        UserNotification & {
          type: NotificationType;
          projectName?: string;
          projectId?: ProjectId;
          taskId?: TaskId;
          eventId?: EventId;
        }
      >
    ) => {
      state.notification = action.payload;
      state.type = AlertType.Notification;
      state.isVisible = true;
    },
    hideAlert: () => {
      return initialState;
    },
  },
});

export const { showInfoAlert, showErrorAlert, showMessageAlert, showNotificationAlert, hideAlert } =
  alertsSlice.actions;
