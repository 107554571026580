export function convertFileTypeToIcon(fileType: string): string {
  let iconName = 'FilePresent';
  switch (fileType) {
    case 'text/csv':
    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      iconName = 'TableChart';
      break;
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      iconName = 'Article';
      break;
    case 'application/pdf':
      iconName = 'PictureAsPdf';
      break;
    case 'application/vnd.ms-powerpoint':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      iconName = 'CoPresent';
      break;
    case 'video/mp4':
    case 'video/quicktime':
      iconName = 'VideoFile';
      break;
    case 'application/zip':
      iconName = 'FolderZip';
      break;
    //case 'audio/*': iconName = ''; break;
    //case 'image/jpg': iconName = ''; break;
    //case 'image/png': iconName = ''; break;
    //case 'application/json': iconName = ''; break;
    //case 'text/plain': iconName = ''; break;
  }

  return iconName;
}
