import { BaseResponse, DateWithoutTime, WithTimestamps } from './common';
import { FileWithUrl } from './file';
import { ProjectId } from './project';
import { UserId } from './user';

export const DAILY_LOG_ID_PREFIX = 'dlog_';

export type DailyLogId = `${typeof DAILY_LOG_ID_PREFIX}${string}`;

export function isDailyLogId(id: string): id is DailyLogId {
  return id.startsWith(DAILY_LOG_ID_PREFIX);
}

export type DailyLog = WithTimestamps<{
  id: DailyLogId;
  projectId: ProjectId;
  type: DailyLogType;
  notes: string;
  createdByUserId: UserId;
  staffOnsiteUserIds: UserId[];
  logDate: DateWithoutTime;
}>;

export type DailyLogWithFiles = DailyLog & {
  files: FileWithUrl[];
};

export enum DailyLogType {
  HomeownerLog = 'HOMEOWNER_LOG',
  HomeownerUpdate = 'HOMEOWNER_UPDATE',
  CrewLog = 'CREW_LOG',
  ForecastLog = 'FORECAST_LOG',
  SupervisorLog = 'SUPERVISOR_LOG',
  GeneralLog = 'GENERAL_LOG',
  DesignerLog = 'DESIGNER_LOG',
}

export const DAILY_LOG_TYPE_LABELS: Record<DailyLogType, string> = {
  [DailyLogType.HomeownerLog]: 'Homeowner Log',
  [DailyLogType.HomeownerUpdate]: 'Homeowner Update',
  [DailyLogType.CrewLog]: 'Crew Log',
  [DailyLogType.ForecastLog]: 'Forecast Log',
  [DailyLogType.SupervisorLog]: 'Supervisor Log',
  [DailyLogType.GeneralLog]: 'General Log',
  [DailyLogType.DesignerLog]: 'Designer Log',
};

export type CreateDailyLogRequest = {
  type: DailyLogType;
  notes: string;
  staffOnsiteUserIds?: UserId[];
  logDate: DateWithoutTime;
};

export type CreateDailyLogResponse = BaseResponse<DailyLog>;

export type GetDailyLogResponse = BaseResponse<DailyLogWithFiles>;

export type GetDailyLogsResponse = BaseResponse<DailyLog[]>;

export type GetUserDailyLogsResponse = BaseResponse<DailyLogWithFiles[]>;

export type UpdateDailyLogRequest = {
  type?: DailyLogType;
  notes?: string;
  staffOnsiteUserIds?: UserId[] | null;
  logDate?: DateWithoutTime;
};

export type UpdateDailyLogResponse = BaseResponse<DailyLog>;

export type DeleteDailyLogResponse = BaseResponse<void>;
