import { Chat, Message, UserId, UserProjectRole } from '@builder-bud/common';

export function canUserCreateChat(userProjectRole: UserProjectRole): boolean {
  return [UserProjectRole.Contractor, UserProjectRole.Homeowner].includes(userProjectRole);
}

export function canUserReadChat(chat: Chat, userId: UserId): boolean {
  return chat.userIds.includes(userId);
}

export function canUserUpdateChat(chat: Chat, userId: UserId): boolean {
  return chat.createdByUserId === userId;
}

export function canUserDeleteChat(chat: Chat, userId: UserId): boolean {
  return chat.createdByUserId === userId;
}

export function canUserUpdateMessage(message: Message, userId: UserId): boolean {
  return message.createdByUserId === userId;
}

export function canUserDeleteMessage(message: Message, userId: UserId): boolean {
  return message.createdByUserId === userId;
}
