import { Button, Stack, Typography } from '@mui/joy';
import { useState } from 'react';

import { ProjectId, ProjectNotificationType } from '@builder-bud/common';
import {
  AlertObject,
  ON_MY_WAY_OPTIONS,
  closeModal,
  showErrorAlert,
  useAppDispatch,
  useSendProjectNotificationMutation,
} from '@builder-bud/common-ui';

export default function OnMyWayUploadModal({ projectId }: { projectId: ProjectId }) {
  const dispatch = useAppDispatch();
  const [sendProjectNotification] = useSendProjectNotificationMutation();
  const [isSendingNotification, setIsSendingNotification] = useState(false);

  async function handleSendProjectNotification(timeframe: string) {
    setIsSendingNotification(true);
    try {
      await sendProjectNotification({
        id: projectId,
        type: ProjectNotificationType.OnMyWay,
        timeframe,
      });
    } catch (error) {
      console.log(error);
      dispatch(showErrorAlert(error as AlertObject));
    } finally {
      dispatch(closeModal());
      setIsSendingNotification(false);
    }
  }

  return (
    <Stack sx={{ margin: 2, gap: 2 }}>
      <Typography level="title-lg" style={{ textAlign: 'center' }}>
        Let us know when you are on your way
      </Typography>
      {ON_MY_WAY_OPTIONS.map((option) => (
        <Button
          key={option.value}
          variant="solid"
          color="tertiary"
          disabled={isSendingNotification}
          onClick={() => {
            handleSendProjectNotification(option.value);
          }}
        >
          {option.label}
        </Button>
      ))}
    </Stack>
  );
}
