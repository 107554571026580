import { Link, Stack, Typography } from '@mui/joy';

import { Project, ProjectId, UserProjectRole } from '@builder-bud/common';
import { useGetProjectsQuery, useGetUsersQuery } from '@builder-bud/common-ui';

function HomeownersComponent({ projectId }: { projectId: ProjectId }) {
  const { data: users = [] } = useGetUsersQuery();
  const { data: projects = [] } = useGetProjectsQuery();
  const project = projects.find((project: Project) => project.id === projectId);

  const homeowners = users.flatMap((user) => {
    if (project?.userIds.includes(user.id)) {
      const userWithProjectRole = {
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phone: user.phone,
        projectRole: user.projects?.find((project) => project.id === projectId)?.projectRole,
      };

      if (userWithProjectRole.projectRole === UserProjectRole.Homeowner) {
        return [userWithProjectRole];
      }
    }
    return [];
  });

  return (
    <Stack gap={1}>
      <Typography level="body-md">{project?.name}</Typography>
      <Typography level="body-md">{project?.displayAddress ? project.displayAddress.join(', ') : ''}</Typography>
      {homeowners.map((user) => (
        <Stack key={user.id}>
          <Typography level="body-sm">
            {user.firstName} {user.lastName}
          </Typography>
          <Link level="body-sm" href={`mailto:${user.email}`}>
            {user.email}
          </Link>
          <Link level="body-sm" href={`tel:${user.phone}`}>
            {user.phone}
          </Link>
        </Stack>
      ))}
    </Stack>
  );
}

export default HomeownersComponent;
