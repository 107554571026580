import { Button, Card, Divider, FormControl, FormHelperText, Radio, RadioGroup, Stack, Typography } from '@mui/joy';
import { Dispatch } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import {
  HOMEOWNER_REGISTRATION_INTENT_OPTIONS,
  HomeownerSteps,
  RegistrationIntent,
  WhyFormData,
  WhySchemaResolver,
  setIsOnboarding,
  useAppDispatch,
} from '@builder-bud/common-ui';

export default function HomeownerWhyStep({
  setStep,
  setRegistrationIntent,
}: {
  setStep: Dispatch<HomeownerSteps>;
  setRegistrationIntent: Dispatch<RegistrationIntent>;
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<WhyFormData>({
    defaultValues: {
      registrationIntent: RegistrationIntent.ContractorSearches,
    },
    resolver: WhySchemaResolver,
  });

  async function onSubmit(data: WhyFormData) {
    setRegistrationIntent(data.registrationIntent);
    switch (data.registrationIntent) {
      case RegistrationIntent.ContractorSearches:
        setStep(HomeownerSteps.homeownerGiveContractorInfo);
        break;
      case RegistrationIntent.ReadyToBuild:
        setStep(HomeownerSteps.homeownerCreateProject);
        break;
      case RegistrationIntent.ProjectInvitation:
        dispatch(setIsOnboarding(false));
        navigate(`/settings/invitations`);
        break;
      case RegistrationIntent.Unknown: {
        dispatch(setIsOnboarding(false));
        break;
      }
    }
  }

  return (
    <Card style={{ backgroundColor: 'var(--joy-palette-common-white)' }}>
      <Stack sx={{ mb: 1 }}>
        <Typography level="title-md">Tell us a bit more about why you're here.</Typography>
      </Stack>
      <Divider />
      <Stack rowGap={2}>
        <Controller
          control={control}
          name="registrationIntent"
          render={({ field: { onChange, onBlur, value: controlValue } }) => (
            <FormControl error={!!errors.registrationIntent}>
              <RadioGroup onChange={onChange} value={controlValue}>
                {HOMEOWNER_REGISTRATION_INTENT_OPTIONS.map((option) => (
                  <Radio key={option.value} label={option.label} value={option.value} />
                ))}
              </RadioGroup>
              {errors.registrationIntent && <FormHelperText>{errors.registrationIntent.message}</FormHelperText>}
            </FormControl>
          )}
        />
      </Stack>
      <Button variant="solid" onClick={handleSubmit(onSubmit)} style={styles.button}>
        Next
      </Button>
    </Card>
  );
}

const styles = {
  button: {
    width: 100,
    alignSelf: 'flex-end',
  },
};
