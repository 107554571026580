import { addDays, subDays } from 'date-fns';

export type ErrorResponse = {
  statusCode: number;
  message: string | string[];
};

export type SuccessResponse<T> = {
  data: T;
  message?: string;
};

export type BaseResponse<T> = ErrorResponse | SuccessResponse<T>;

// yyyy-mm-dd
export type DateWithoutTime = `${string}-${string}-${string}`;

export type DateRange = { startDate: DateWithoutTime; endDate: DateWithoutTime };

export type WithTimestamps<T> = T & {
  createdAt: string;
  updatedAt: string;
};

export function getNewest<T>(list: WithTimestamps<T>[]): WithTimestamps<T> {
  return list.reduce((prev, current) => (prev.createdAt > current.createdAt ? prev : current), list[0]);
}

export function getMostRecentlyUpdated<T>(list: WithTimestamps<T>[]): WithTimestamps<T> {
  return list.reduce((prev, current) => (prev.updatedAt > current.updatedAt ? prev : current), list[0]);
}

export function dateToDateWithoutTime(date: Date | string): DateWithoutTime {
  const dateObj: Date = typeof date === 'string' ? new Date(date) : date;
  return dateObj.toJSON().split('T')[0] as DateWithoutTime;
}

export function roundUpToTwoDecimals(value: number): number {
  return Math.ceil(value * 100) / 100;
}

export function getDateRangeFromToday(numDays = 1): DateRange {
  const now = new Date();
  const startDate = dateToDateWithoutTime(subDays(now, numDays));
  const endDate = dateToDateWithoutTime(addDays(now, numDays));

  return { startDate, endDate };
}
