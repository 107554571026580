import { AvatarProps, Link, Stack, Tooltip, Typography } from '@mui/joy';

import { ProjectId, User, UserId } from '@builder-bud/common';
import {
  getUserProjectRole,
  getUserProjectRoleLabel,
  selectIsContractorAppRole,
  selectIsSubcontractorAppRole,
  useAppSelector,
  useGetUsersQuery,
} from '@builder-bud/common-ui';

import AvatarComponent from './avatar.component';

function MemberComponent({
  userId,
  projectId,
  size = 'sm',
  showName = false,
}: {
  userId?: UserId;
  projectId?: ProjectId;
  size?: AvatarProps['size'];
  showName?: boolean;
}) {
  const isContractor = useAppSelector(selectIsContractorAppRole);
  const isSubcontractor = useAppSelector(selectIsSubcontractorAppRole);
  const { data: users = [] } = useGetUsersQuery();
  const user = users.find((user: User) => user.id === userId);
  const projectRole = getUserProjectRole(projectId, user?.projects);

  return (
    <Tooltip
      variant="outlined"
      arrow
      disableHoverListener={!user}
      title={
        <Stack flexDirection="row" columnGap={2}>
          <AvatarComponent user={user} size="lg" />
          <Stack style={{ rowGap: 4 }}>
            <Typography level="body-md">
              {user?.firstName} {user?.lastName}
            </Typography>
            {projectRole && <Typography>{getUserProjectRoleLabel(projectRole)}</Typography>}
            <Link href={`mailto:${user?.email}`}>{user?.email}</Link>
            <Link href={`tel:${user?.phone}`}>{user?.phone}</Link>
            {(isContractor || isSubcontractor) && (
              <Stack>
                {user?.displayName && <Typography>{user.displayName}</Typography>}
                {user?.website && (
                  <Link href={user.website} target="_blank" rel="noopener">
                    {user.website}
                  </Link>
                )}
              </Stack>
            )}
          </Stack>
        </Stack>
      }
    >
      {/* Todo wire this up when there is no user to take them to the appropriate edit screen ( project, task, event, etc) */}
      <Link /*onClick={!user ? () => navigate(`/projects/${projectId}/InvitePeople`) : undefined*/>
        <AvatarComponent user={user} size={size} showName={showName} />
      </Link>
    </Tooltip>
  );
}

export default MemberComponent;
