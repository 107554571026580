import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Stack } from '@mui/joy';
import { Dispatch } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { HomeownerSteps, useAppSelector } from '@builder-bud/common-ui';

import SubscriptionsForm from '../../settings/subscriptions.form';

type OnboardingFormData = Record<string, never>;

export default function HomeownerPaymentStep({ setStep }: { setStep: Dispatch<HomeownerSteps> }) {
  const {
    //control,
    handleSubmit,
    //formState: { errors },
  } = useForm<OnboardingFormData>({
    defaultValues: {},
    resolver: userSchemaResolver,
  });

  async function onSubmit(data: OnboardingFormData) {
    setStep(HomeownerSteps.homeownerInvitePeople);
  }

  const me = useAppSelector((state) => state.auth.me);
  if (!me) return null;

  return (
    <Stack>
      <SubscriptionsForm me={me} />
      {me.stripeProductId && (
        <Button variant="solid" onClick={handleSubmit(onSubmit)} style={styles.button}>
          Next
        </Button>
      )}
    </Stack>
  );
}

const styles = {
  button: {
    width: 100,
    alignSelf: 'flex-end',
  },
};

const userSchemaResolver = yupResolver(yup.object<OnboardingFormData>().shape({}));
