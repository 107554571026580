import {
  CreateSubscriptionRequest,
  CreateSubscriptionResponse,
  CreateSubscriptionResponseData,
  DeleteSubscriptionResponse,
  GetPaymentConfigResponse,
  GetProductsResponse,
  PaymentConfig,
  Product,
} from '@builder-bud/common';

import { api } from './api';

export const paymentsSlice = api.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getPaymentConfig: builder.query<PaymentConfig, void>({
      query: (body) => ({
        url: 'payments/config',
        method: 'GET',
      }),
      transformResponse: (response: GetPaymentConfigResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to get payment config');
      },
    }),
    getProducts: builder.query<Product[], void>({
      query: (body) => ({
        url: 'products',
        method: 'GET',
      }),
      transformResponse: (response: GetProductsResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to get products');
      },
    }),
    createSubscription: builder.mutation<CreateSubscriptionResponseData, CreateSubscriptionRequest>({
      query: (body) => ({
        url: 'subscriptions',
        method: 'POST',
        body,
      }),
      transformResponse: (response: CreateSubscriptionResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to create subscription');
      },
    }),
    deleteSubscription: builder.mutation<DeleteSubscriptionResponse, void>({
      query: (body) => ({
        url: 'subscriptions',
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetPaymentConfigQuery,
  useGetProductsQuery,
  useCreateSubscriptionMutation,
  useDeleteSubscriptionMutation,
} = paymentsSlice;
