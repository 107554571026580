import { Typography, TypographySystem } from '@mui/joy';

import { DateWithoutTime } from '@builder-bud/common';
import { formatDateWithoutTime } from '@builder-bud/common-ui';

export default function DateComponent({
  date,
  level = 'body-xs',
}: {
  date?: DateWithoutTime;
  level?: keyof TypographySystem | 'inherit' | undefined;
}) {
  let dateText = 'TBD';
  if (date) {
    dateText = formatDateWithoutTime(date);
  }
  return <Typography level={level}>{dateText}</Typography>;
}
