/* eslint-disable jsx-a11y/anchor-is-valid */
import { ArrowDropDown, MoreHorizRounded, WarningRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Dropdown,
  FormControl,
  FormLabel,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  Modal,
  ModalDialog,
  Option,
  Select,
  Typography,
  useTheme,
} from '@mui/joy';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';

import { DailyLog, DailyLogWithFiles, canUserDeleteDailyLog, dateToDateWithoutTime } from '@builder-bud/common';
import {
  AlertObject,
  DAILY_LOG_OPTIONS,
  IMAGE_TYPE,
  getDailyLogTypeLabel,
  getUserProjectRole,
  showErrorAlert,
  showInfoAlert,
  useAppDispatch,
  useAppSelector,
  useDeleteDailyLogMutation,
} from '@builder-bud/common-ui';

import CircularProgressComponent from '../../components/circular-progress.component';
import DateComponent from '../../components/date.component';
import MemberComponent from '../../components/member.component';
import MembersComponent from '../../components/members.component';
import ProjectNameComponent from '../../components/project-name';
import { Order, getComparator } from '../../components/table';
import TableComponent from '../../components/table.component';
import DailyLogPhotoCardComponent from '../files/daily-log-photo.card.componen';

export default function DailyLogTableComponent({
  rows,
  showProject = false,
  showPhotos = false,
  hideSearch = false,
}: {
  rows: DailyLog[] | DailyLogWithFiles[];
  showProject?: boolean;
  showPhotos?: boolean;
  hideSearch?: boolean;
}) {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const smallAndUp = useMediaQuery(theme.breakpoints.up('sm'));
  const matchesXS = useMediaQuery(theme.breakpoints.only('xs'));
  const matchesSM = useMediaQuery(theme.breakpoints.only('sm'));
  const slidesToShow = matchesXS ? 1 : matchesSM ? 2 : 3;

  const me = useAppSelector((state) => state.auth.me);

  const [deleteDailyLog] = useDeleteDailyLogMutation();

  const [order, setOrder] = useState<Order>('');
  const [typeFilter, setTypeFilter] = useState<string[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [rowToDelete, setRowToDelete] = useState<DailyLogWithFiles | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const filteredRows = typeFilter.length ? rows.filter((row) => typeFilter.includes(row.type)) : rows;

  async function handleDeleteDailyLog() {
    if (rowToDelete) {
      setIsDeleting(true);
      try {
        await deleteDailyLog({ projectId: rowToDelete.projectId, id: rowToDelete.id }).unwrap();
        dispatch(showInfoAlert('Successfully deleted Daily Log'));
      } catch (error) {
        console.log(error);
        dispatch(showErrorAlert(error as AlertObject));
      } finally {
        setRowToDelete(null);
        setIsDeleting(false);
      }
    }
  }

  const renderFilters = () => (
    <FormControl size="sm">
      <FormLabel>Log Type</FormLabel>
      <Select
        value={typeFilter}
        onChange={(e, newValue) => setTypeFilter(newValue)}
        size="sm"
        placeholder="Filter by log type"
        slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
        multiple={true}
      >
        {DAILY_LOG_OPTIONS.map((option) => (
          <Option key={option.value} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>
    </FormControl>
  );

  const renderTableHead = () => (
    <thead>
      <tr>
        <th style={{ padding: '12px 6px' }}>
          <Link
            underline="none"
            color="primary"
            component="button"
            onClick={() => setOrder(order === '' ? 'asc' : order === 'asc' ? 'desc' : '')}
            fontWeight="lg"
            endDecorator={<ArrowDropDown />}
            sx={{
              '& svg': {
                transition: '0.2s',
                transform: order === '' ? 'rotate(90deg)' : order === 'desc' ? 'rotate(0deg)' : 'rotate(180deg)',
              },
            }}
          >
            Log type
          </Link>
        </th>
        {showProject && smallAndUp && <th style={{ padding: '12px 6px' }}>Project</th>}
        <th style={{ padding: '12px 6px' }}>Created by</th>
        <th style={{ padding: '12px 6px' }}>Date</th>
        {smallAndUp && (
          <>
            <th style={{ padding: '12px 6px' }}>Notes</th>
            <th style={{ padding: '12px 6px' }}>Staff onsite</th>
          </>
        )}
        <th style={{ width: 48, padding: '12px 6px' }}></th>
      </tr>
    </thead>
  );

  function TableRowMenu({ row }: { row: DailyLogWithFiles }) {
    const navigate = useNavigate();
    const userProjectRole = getUserProjectRole(row.projectId, me?.projects);

    return (
      <Dropdown>
        <MenuButton
          slots={{ root: IconButton }}
          slotProps={{ root: { variant: 'plain', color: 'neutral', size: 'sm' } }}
        >
          <MoreHorizRounded />
        </MenuButton>
        <Menu size="sm" sx={{ minWidth: 140 }}>
          <MenuItem onClick={() => navigate(`/projects/${row.projectId}/dailyLogs/${row.id}`)}>View</MenuItem>
          {me && userProjectRole && canUserDeleteDailyLog(row, userProjectRole, me.id) && (
            <MenuItem
              color="danger"
              onClick={() => {
                setRowToDelete(row);
                setModalOpen(true);
              }}
            >
              Delete
            </MenuItem>
          )}
        </Menu>
      </Dropdown>
    );
  }

  function TableRow({ row }: { row: DailyLogWithFiles }) {
    const navigate = useNavigate();

    return (
      <>
        <tr key={row.id} style={{ backgroundColor: 'var(--joy-palette-common-white)' }}>
          <td>
            <Link onClick={() => navigate(`/projects/${row.projectId}/dailyLogs/${row.id}`)}>
              <Typography level="body-xs">{getDailyLogTypeLabel(row.type)}</Typography>
            </Link>
          </td>
          {showProject && smallAndUp && (
            <td>
              <Typography>
                <ProjectNameComponent projectId={row.projectId} />
              </Typography>
            </td>
          )}
          <td>
            <MemberComponent userId={row.createdByUserId} projectId={row.projectId} />
          </td>
          <td>
            <DateComponent date={dateToDateWithoutTime(row.logDate)} />
          </td>
          {smallAndUp && (
            <>
              <td>
                <Typography level="body-xs">{row.notes}</Typography>
              </td>
              <td>
                <MembersComponent userIds={row.staffOnsiteUserIds} projectId={row.projectId} />
              </td>
            </>
          )}
          <td>
            <TableRowMenu row={row} />
          </td>
        </tr>
        {showPhotos && row.files && row.files.length > 0 && (
          <tr>
            <td colSpan={7} style={{ paddingInline: 28 }}>
              <Box sx={{ marginTop: 2 }}>
                <Slider
                  {...{
                    draggable: false,
                    infinite: false,
                    slidesToShow: slidesToShow,
                    slidesToScroll: 1,
                  }}
                >
                  {row.files
                    .filter((file) => file.contentType.startsWith(IMAGE_TYPE))
                    .map((file, i) => (
                      <Box
                        key={file.id}
                        sx={{ paddingLeft: i > 0 ? 2 : 3, paddingRight: i < row.files!.length - 1 ? 2 : 3 }}
                      >
                        <DailyLogPhotoCardComponent file={file} projectId={row.projectId} dailyLogId={row.id} />
                      </Box>
                    ))}
                </Slider>
              </Box>
            </td>
          </tr>
        )}
      </>
    );
  }

  if (isDeleting) return <CircularProgressComponent />;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <TableComponent
        renderFilters={renderFilters}
        renderTableHead={renderTableHead}
        TableRow={TableRow}
        rows={filteredRows}
        comparator={getComparator(order, 'type')}
        hideSearch={hideSearch}
        searchField="notes"
      />
      <Modal
        open={modalOpen}
        onClose={() => {
          setRowToDelete(null);
          setModalOpen(false);
        }}
      >
        <ModalDialog variant="outlined" role="alertdialog">
          <DialogTitle>
            <WarningRounded />
            Confirmation
          </DialogTitle>
          <Divider />
          <DialogContent>Are you sure you want to delete this daily log?</DialogContent>
          <DialogActions>
            <Button variant="solid" color="danger" onClick={() => handleDeleteDailyLog()}>
              Delete dailyLog
            </Button>
            <Button variant="plain" color="neutral" onClick={() => setModalOpen(false)}>
              Cancel
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </Box>
  );
}
