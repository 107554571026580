import {
  Box,
  Button,
  Card,
  CardActions,
  CardOverflow,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Input,
  Option,
  Select,
  Stack,
  Textarea,
  Typography,
} from '@mui/joy';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Task, canUserAssignTask, canUserUpdateTask } from '@builder-bud/common';
import {
  AlertObject,
  TaskFormData,
  TaskSchemaResolver,
  UserOption,
  closeModal,
  getDefaultTaskFormValues,
  getTaskSubmitData,
  showErrorAlert,
  showInfoAlert,
  useAppDispatch,
  useAppSelector,
} from '@builder-bud/common-ui';

import PunchlistStatusComponent from '../../../components/punchlist-status.component';

export default function TaskForm({
  task,
  saveTask,
  users,
}: {
  task: Partial<Task>;
  saveTask: any;
  users: UserOption[];
}) {
  const dispatch = useAppDispatch();

  const me = useAppSelector((state) => state.auth.me);
  const isAllowedUpdateTask = !task.id || (me && canUserUpdateTask(task as Task, me.id));
  const isAllowedAssignTask = !task.id || (me && canUserAssignTask(task as Task, me.id));

  const [saving, setSaving] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<TaskFormData>({
    defaultValues: getDefaultTaskFormValues(users, task),
    resolver: TaskSchemaResolver,
  });

  async function onSubmit(data: TaskFormData) {
    setSaving(true);
    try {
      const taskData = getTaskSubmitData(data);
      if (!isAllowedAssignTask) {
        delete taskData.assignedToUserIds;
      }
      await saveTask({ ...taskData, ...{ id: task.id, projectId: task.projectId } }).unwrap();
      dispatch(closeModal());
      dispatch(showInfoAlert(task.id ? 'Successfully updated punchlist item' : 'Successfully created punchlist item'));
    } catch (error) {
      console.log(error);
      dispatch(showErrorAlert(error as AlertObject));
    } finally {
      setSaving(false);
    }
  }

  return (
    <Card sx={{ margin: 2, backgroundColor: 'var(--joy-palette-common-white)' }}>
      <Box sx={{ mb: 1 }}>
        <Typography level="title-md">Punchlist item info</Typography>
      </Box>
      <Divider />
      <Stack direction="row" spacing={3} sx={{ display: { xs: 'flex' }, my: 1 }}>
        <Stack spacing={2} sx={{ flexGrow: 1, display: 'grid' }}>
          <Grid container spacing={2}>
            <Grid xs={12} md={6}>
              <Controller
                control={control}
                name="name"
                render={({ field: { onChange, onBlur, value: controlValue } }) => (
                  <FormControl error={!!errors.name} required>
                    <FormLabel>Punchlist item name</FormLabel>
                    <Input value={controlValue} onBlur={onBlur} onChange={onChange} disabled={!isAllowedUpdateTask} />
                    {errors.name && <FormHelperText>{errors.name.message}</FormHelperText>}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid xs={12} md={6}>
              <Controller
                control={control}
                name="assignedToUserIds"
                render={({ field: { onChange, onBlur, value: controlValue } }) => (
                  <FormControl error={!!errors.assignedToUserIds}>
                    <FormLabel>Assigned To</FormLabel>
                    <Select
                      value={controlValue}
                      onChange={(e, newValue) => {
                        onChange(newValue);
                      }}
                      onBlur={onBlur}
                      multiple={true}
                      disabled={!isAllowedAssignTask}
                      slotProps={{
                        listbox: {
                          sx: {
                            zIndex: 20001,
                          },
                        },
                      }}
                    >
                      {users.map((option, i) => (
                        <Option key={option.value} value={option.value}>
                          {option.label}
                        </Option>
                      ))}
                    </Select>
                    {errors.assignedToUserIds && <FormHelperText>{errors.assignedToUserIds.message}</FormHelperText>}
                  </FormControl>
                )}
              />
            </Grid>
            {task.id && (
              <Grid xs={12}>
                <PunchlistStatusComponent
                  task={task as Task}
                  showTaskName={false}
                  allowUpdate={!!isAllowedUpdateTask}
                />
              </Grid>
            )}
            <Grid xs={12}>
              <Controller
                control={control}
                name="notes"
                render={({ field: { onChange, onBlur, value: controlValue } }) => (
                  <FormControl error={!!errors.notes}>
                    <FormLabel>Notes</FormLabel>
                    <Textarea
                      value={controlValue}
                      onBlur={onBlur}
                      onChange={onChange}
                      minRows={4}
                      disabled={!isAllowedUpdateTask}
                    />
                    {errors.notes && <FormHelperText>{errors.notes.message}</FormHelperText>}
                  </FormControl>
                )}
              />
            </Grid>
          </Grid>
        </Stack>
      </Stack>
      {isAllowedUpdateTask && (
        <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
          <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
            <Button size="sm" variant="outlined" onClick={() => dispatch(closeModal())}>
              Cancel
            </Button>
            <Button size="sm" variant="solid" onClick={handleSubmit(onSubmit)} disabled={!isDirty || saving}>
              Save
            </Button>
          </CardActions>
        </CardOverflow>
      )}
    </Card>
  );
}
