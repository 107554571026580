import { Add, ChevronRightRounded, HomeRounded } from '@mui/icons-material';
import {
  Box,
  Breadcrumbs,
  Dropdown,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Typography,
  tabClasses,
} from '@mui/joy';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

import { ProjectId, TaskId, TaskWithFiles, canUserCreateTask } from '@builder-bud/common';
import {
  getUserOptions,
  getUserProjectRole,
  isActiveProject,
  openModal,
  useAppDispatch,
  useAppSelector,
  useCreateTaskMutation,
  useEditTaskMutation,
  useGetProjectQuery,
  useGetTaskQuery,
  useGetUsersQuery,
} from '@builder-bud/common-ui';

import CircularProgressComponent from '../../components/circular-progress.component';
import ErrorLoadingComponent from '../../components/error-loading.component';
import NotFoundComponent from '../../components/not-found.component';
import ProjectNameComponent from '../../components/project-name';
import { useRequiredParams } from '../../utils';
import FilesTableComponent from '../files/files-table.component';
import FileUploadModal from '../files/project-file-upload.modal';
import PunchlistTableComponent from './punchlists/punchlist-table.component';
import PunchlistForm from './punchlists/punchlist.form';
import TaskForm from './task.form';

export default function TaskDetailsScreen() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { projectId, taskId } = useRequiredParams<{ projectId: ProjectId; taskId: TaskId }>();
  const [createTask] = useCreateTaskMutation();
  const [updateTask] = useEditTaskMutation();

  const modalContent = <FileUploadModal projectId={projectId} taskId={taskId} />;

  const me = useAppSelector((state) => state.auth.me);
  const { data: project } = useGetProjectQuery(projectId);

  const {
    data: task,
    isLoading: isTaskLoading,
    isFetching: isTaskFetching,
    isError: isTaskError,
    error: taskError = {},
  } = useGetTaskQuery({ projectId, taskId });

  const {
    data: users = [],
    isLoading: isUsersLoading,
    isFetching: isUsersFetching,
    isError: isUsersError,
    error: usersError = {},
  } = useGetUsersQuery();

  const isLoading = isTaskLoading || isUsersLoading;
  const isFetching = isTaskFetching || isUsersFetching;
  const isError = isTaskError || isUsersError;
  const error = isTaskError ? taskError : isUsersError ? usersError : {};

  if (isLoading || isFetching) return <CircularProgressComponent />;
  if (isError) return <ErrorLoadingComponent error={error} />;
  if (!task) return <NotFoundComponent />;

  const userProjectRole = getUserProjectRole(projectId, me?.projects);

  const getDefaultTabValue = () => {
    return matchPath('projects/:projectId/tasks/:taskId/punchlist', location.pathname)
      ? 1
      : matchPath('projects/:projectId/tasks/:taskId/files', location.pathname)
      ? 2
      : 0;
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={<ChevronRightRounded fontSize="small" />}
          sx={{ pl: 0 }}
        >
          <Link underline="none" color="neutral" href="/" aria-label="Home">
            <HomeRounded />
          </Link>
          <Link underline="hover" color="neutral" href="/projects" fontSize={12} fontWeight={500}>
            Projects
          </Link>
          <Link underline="hover" color="neutral" href={`/projects/${task.projectId}`} fontSize={12} fontWeight={500}>
            <ProjectNameComponent projectId={task.projectId} />
          </Link>
          <Link
            underline="hover"
            color="neutral"
            href={`/projects/${task.projectId}/tasks`}
            fontSize={12}
            fontWeight={500}
          >
            Tasks
          </Link>
          <Typography color="primary" fontWeight={500} fontSize={12}>
            {task.name}
          </Typography>
        </Breadcrumbs>
      </Box>
      <Box
        sx={{
          display: 'flex',
          mb: 1,
          gap: 1,
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'start', sm: 'center' },
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Typography level="h2" component="h1">
          {task.name}
        </Typography>
        {isActiveProject(project) && userProjectRole && canUserCreateTask(userProjectRole) && (
          <Dropdown>
            <MenuButton color="secondary" startDecorator={<Add />}>
              Add to Task
            </MenuButton>
            <Menu>
              <MenuItem
                color="secondary"
                onClick={() => {
                  const punchlistModal = (
                    <PunchlistForm
                      task={{ projectId: projectId, parentTaskId: taskId }}
                      saveTask={createTask}
                      users={getUserOptions(users, task.projectId)}
                    />
                  );
                  dispatch(openModal(punchlistModal));
                }}
              >
                Add punchlist item
              </MenuItem>
              <MenuItem
                color="secondary"
                onClick={() => {
                  dispatch(openModal(modalContent));
                }}
              >
                Add File
              </MenuItem>
            </Menu>
          </Dropdown>
        )}
      </Box>

      <Tabs
        defaultValue={0}
        value={getDefaultTabValue()}
        onChange={(e, selectedTab) => {
          selectedTab === 0 && navigate(`/projects/${projectId}/tasks/${task.id}`, { replace: true });
          selectedTab === 1 && navigate(`/projects/${projectId}/tasks/${task.id}/punchlist`, { replace: true });
          selectedTab === 2 && navigate(`/projects/${projectId}/tasks/${task.id}/files`, { replace: true });
        }}
        sx={{
          bgcolor: 'transparent',
        }}
      >
        <TabList
          size="sm"
          sx={{
            pl: { xs: 0, md: 4 },
            justifyContent: 'left',
            [`&& .${tabClasses.root}`]: {
              fontWeight: '600',
              //flex: 'initial',
              color: 'text.tertiary',
              [`&.${tabClasses.selected}`]: {
                bgcolor: 'transparent',
                color: 'text.primary',
                '&::after': {
                  height: '2px',
                  bgcolor: 'primary.500',
                },
              },
            },
            overflow: 'auto',
            scrollSnapType: 'x mandatory',
            '&::-webkit-scrollbar': { display: 'none' },
          }}
        >
          <Tab sx={{ flex: 'none', scrollSnapAlign: 'start', borderRadius: '6px 6px 0 0' }} value={0}>
            Details
          </Tab>
          <Tab sx={{ flex: 'none', scrollSnapAlign: 'start', borderRadius: '6px 6px 0 0' }} value={1}>
            Punchlist
          </Tab>
          <Tab sx={{ flex: 'none', scrollSnapAlign: 'start', borderRadius: '6px 6px 0 0' }} value={2}>
            Files
          </Tab>
        </TabList>

        <TabPanel value={0}>
          <TaskForm task={task} saveTask={updateTask} users={getUserOptions(users, task.projectId)} />
        </TabPanel>
        <TabPanel value={1}>
          <PunchlistTableComponent rows={task.subTasks ? task.subTasks : []} users={users} />
        </TabPanel>
        <TabPanel value={2}>
          <FilesTableComponent rows={(task as TaskWithFiles).files} />
        </TabPanel>
      </Tabs>
    </>
  );
}
