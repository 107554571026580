import { CameraAlt, Upload } from '@mui/icons-material';
import { Avatar, Box, Card, CardCover, Dropdown, Menu, MenuButton, MenuItem } from '@mui/joy';
import { ReactElement } from 'react';

import { File, FileType, FileWithUrl, getNewest } from '@builder-bud/common';
import { openModal, useAppDispatch } from '@builder-bud/common-ui';

import ProfilePhotoUploadModal from './profile-photo-upload.modal';

export default function ProfilePhotoUploadComponent({
  placeHolder,
  fileType,
  files,
}: {
  placeHolder: ReactElement;
  fileType: string;
  files: File[];
}) {
  const dispatch = useAppDispatch();

  //Filter by fileType and then find the newest created file of that type
  const file = getNewest(files.filter((f: File) => f.type === fileType)) as FileWithUrl;

  const uploadProfilePhotoModal = <ProfilePhotoUploadModal fileType={fileType} />;
  const viewProfilePhotoModal = <img src={file?.url} loading="lazy" alt="" style={{ margin: 16 }} />;

  return (
    <Dropdown>
      <MenuButton sx={{ border: 'none', p: 0 }}>
        {fileType === FileType.ProfileImage ? (
          <Box sx={{ alignItems: 'center' }}>
            {file?.url ? <Avatar sx={styles.avatar} src={file.url} /> : placeHolder}
            <Avatar size="sm" color="neutral" sx={styles.cameraIcon}>
              <CameraAlt />
            </Avatar>
          </Box>
        ) : (
          <Card sx={{ display: 'flex', flex: 1, minHeight: 256 }}>
            {file?.url ? (
              <CardCover>
                <img src={file.url} alt="" />
              </CardCover>
            ) : (
              placeHolder
            )}
            <Avatar size="sm" color="neutral" sx={styles.coverIcon}>
              <Upload />
            </Avatar>
          </Card>
        )}
      </MenuButton>
      <Menu>
        {file?.url && <MenuItem onClick={() => dispatch(openModal(viewProfilePhotoModal))}>View Photo</MenuItem>}
        <MenuItem onClick={() => dispatch(openModal(uploadProfilePhotoModal))}>Upload Photo</MenuItem>
      </Menu>
    </Dropdown>
  );
}

const styles = {
  avatar: {
    height: 200,
    width: 200,
  },
  cameraIcon: {
    position: 'relative',
    bottom: 48,
    left: 160,
  },
  coverIcon: {
    position: 'absolute',
    right: 16,
    bottom: 16,
  },
};
