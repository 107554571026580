/* eslint-disable jsx-a11y/anchor-is-valid */
import { WarningRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardCover,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Dropdown,
  Menu,
  MenuButton,
  MenuItem,
  Modal,
  ModalDialog,
} from '@mui/joy';
import { useEffect, useState } from 'react';

import { DailyLogId, File, ProjectId } from '@builder-bud/common';
import {
  AlertObject,
  IMAGE_TYPE,
  openModal,
  showErrorAlert,
  showInfoAlert,
  useAppDispatch,
  useDeleteDailyLogFileMutation,
  useGetDailyLogFileDownloadUrlMutation,
} from '@builder-bud/common-ui';

import CircularProgressComponent from '../../components/circular-progress.component';

export default function DailyLogPhotoCardComponent({
  file,
  projectId,
  dailyLogId,
}: {
  file: File;
  projectId: ProjectId;
  dailyLogId: DailyLogId;
}) {
  const dispatch = useAppDispatch();

  const [deleteDailyLogFile] = useDeleteDailyLogFileMutation();
  const [getDailyLogFileDownloadUrl] = useGetDailyLogFileDownloadUrlMutation();

  const [isFileDownloading, setIsFileDownloading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [fileDownloadUrl, setFileDownloadUrl] = useState('');

  const viewFile = async (row: File) => {
    setIsFileDownloading(true);

    try {
      const fileDownloadUrlResult = await getDailyLogFileDownloadUrl({
        projectId,
        dailyLogId,
        fileId: file.id,
      }).unwrap();

      if (row.contentType.startsWith(IMAGE_TYPE)) {
        const viewPhotoModal = <img src={fileDownloadUrlResult.url} loading="lazy" alt="" style={{ margin: 16 }} />;
        dispatch(openModal(viewPhotoModal));
      } else {
        window.open(fileDownloadUrlResult.url, '_blank');
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsFileDownloading(false);
    }
  };

  const downloadFile = async function (file: File) {
    setIsFileDownloading(true);

    try {
      if (!projectId) {
        throw new Error('Project not found');
      }

      const fileDownloadUrlResult = await getDailyLogFileDownloadUrl({
        projectId,
        dailyLogId,
        fileId: file.id,
      }).unwrap();
      //console.log(fileDownloadUrlResult);

      const response = await fetch(fileDownloadUrlResult.url);
      if (!response.ok) {
        throw new Error('Failed to download file');
      }

      const blob = await response.blob();
      const objectUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');

      link.href = objectUrl;
      link.download = file.name;
      link.click();

      URL.revokeObjectURL(objectUrl);
    } catch (error) {
      console.log(error);
    } finally {
      setIsFileDownloading(false);
    }
  };

  async function handleDeleteFile() {
    setIsDeleting(true);
    try {
      if (!projectId) {
        throw new Error('Project not found');
      }
      await deleteDailyLogFile({ projectId, dailyLogId, fileId: file.id }).unwrap();
      dispatch(showInfoAlert('Successfully deleted File'));
    } catch (error) {
      console.log(error);
      dispatch(showErrorAlert(error as AlertObject));
    } finally {
      setIsDeleting(false);
    }
  }

  useEffect(() => {
    async function getFileDownloadUrl(file: File) {
      try {
        setIsFileDownloading(true);
        const { data } = await getDailyLogFileDownloadUrl({ projectId, dailyLogId, fileId: file?.id });
        if (data) {
          setFileDownloadUrl(data.url);
        }
      } catch (error) {
        console.log(error);
        dispatch(showErrorAlert(error as AlertObject));
      } finally {
        setIsFileDownloading(false);
      }
    }

    getFileDownloadUrl(file);
  }, [dispatch, getDailyLogFileDownloadUrl, setFileDownloadUrl, dailyLogId, file, projectId]);

  if (isFileDownloading || isDeleting) return <CircularProgressComponent />;

  return (
    <Box>
      <Dropdown>
        <MenuButton slots={{ root: Box }}>
          <Card sx={{ display: 'flex', flex: 1, minHeight: 256 }}>
            <CardCover>
              <img src={fileDownloadUrl} alt="" width="100%" />
            </CardCover>
          </Card>
        </MenuButton>

        <Menu size="sm">
          <MenuItem
            onClick={() => {
              viewFile(file);
            }}
          >
            View
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() => {
              downloadFile(file);
            }}
          >
            Download
          </MenuItem>
          <Divider />
          <MenuItem
            color="danger"
            onClick={() => {
              setModalOpen(true);
            }}
          >
            Delete
          </MenuItem>
        </Menu>
      </Dropdown>

      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
      >
        <ModalDialog variant="outlined" role="alertdialog">
          <DialogTitle>
            <WarningRounded />
            Confirmation
          </DialogTitle>
          <Divider />
          <DialogContent>Are you sure you want to delete this file?</DialogContent>
          <DialogActions>
            <Button variant="solid" color="danger" onClick={() => handleDeleteFile()}>
              Delete file
            </Button>
            <Button variant="plain" color="neutral" onClick={() => setModalOpen(false)}>
              Cancel
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </Box>
  );
}
