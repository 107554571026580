import { ChevronRightRounded, HomeRounded } from '@mui/icons-material';
import { Box, Breadcrumbs, Link, Typography } from '@mui/joy';

import { useGetMeChatsQuery, useGetProjectsQuery } from '@builder-bud/common-ui';

import CircularProgressComponent from '../../components/circular-progress.component';
import ErrorLoadingComponent from '../../components/error-loading.component';
import ChatTableComponent from './chats-table.component';

export default function ChatsScreen() {
  const {
    data: chats = [],
    isLoading: isChatsLoading,
    isFetching: isChatsFetching,
    isError: isChatsError,
    error: chatsError,
  } = useGetMeChatsQuery();
  const {
    data: projects = [],
    isLoading: isProjectsLoading,
    isFetching: isProjectsFetching,
    isError: isProjectsError,
    error: projectsError,
  } = useGetProjectsQuery();

  const isLoading = isChatsLoading || isProjectsLoading;
  const isFetching = isChatsFetching || isProjectsFetching;
  const isError = isChatsError || isProjectsError;
  const error = isChatsError ? chatsError : isProjectsError ? projectsError : {};

  if (isLoading || isFetching) return <CircularProgressComponent />;
  if (isError) return <ErrorLoadingComponent error={error} />;

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={<ChevronRightRounded fontSize="small" />}
          sx={{ pl: 0 }}
        >
          <Link underline="none" color="neutral" href="/" aria-label="Home">
            <HomeRounded />
          </Link>
          <Typography color="primary" fontWeight={500} fontSize={12}>
            Chats
          </Typography>
        </Breadcrumbs>
      </Box>
      <Box
        sx={{
          display: 'flex',
          mb: 1,
          gap: 1,
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'start', sm: 'center' },
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Typography level="h2" component="h1">
          Chats
        </Typography>
      </Box>
      <ChatTableComponent rows={chats} showProject={true} projects={projects} />
    </>
  );
}
