import { format, parse } from 'date-fns';

import { DateWithoutTime } from '@builder-bud/common';

export function commonUi(): string {
  return 'common-ui';
}

export interface ListItem {
  _id: string;
  value: string;
  disabled?: boolean;
}

export interface BBListItem {
  value: string;
  label: string;
  color?: string;
  disabled?: boolean;
}

export interface BBSelectOption {
  _id: string;
  value: string;
  selectedList: ListItem[];
}

export function formatDateWithoutTime(dateWithoutTime: DateWithoutTime): string {
  return format(parse(dateWithoutTime, 'yyyy-MM-dd', new Date()), 'MMM dd yyyy');
}
