import { Add, ChevronRightRounded, HomeRounded } from '@mui/icons-material';
import {
  Box,
  Breadcrumbs,
  Dropdown,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Typography,
  tabClasses,
} from '@mui/joy';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

import { ContractId, ContractStatus, ContractWithFiles, ProjectId, UserProjectRole } from '@builder-bud/common';
import {
  getUserProjectRole,
  isActiveProject,
  openModal,
  useAppDispatch,
  useAppSelector,
  useEditContractMutation,
  useGetContractQuery,
  useGetProjectQuery,
} from '@builder-bud/common-ui';

import CircularProgressComponent from '../../components/circular-progress.component';
import ErrorLoadingComponent from '../../components/error-loading.component';
import NotFoundComponent from '../../components/not-found.component';
import ProjectNameComponent from '../../components/project-name';
import { useRequiredParams } from '../../utils';
import ProjectFileUploadModal from '../files/project-file-upload.modal';
import ContractForm from './contract.form';
import ContractView from './contract.view';

export default function ContractEditScreen() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { projectId, contractId } = useRequiredParams<{ projectId: ProjectId; contractId: ContractId }>();
  const [updateContract] = useEditContractMutation();

  const me = useAppSelector((state) => state.auth.me);
  const userProjectRole = getUserProjectRole(projectId, me?.projects);

  const fileUploadModal = <ProjectFileUploadModal projectId={projectId} contractId={contractId} />;

  const { data: project } = useGetProjectQuery(projectId);

  const { data: contract, isLoading, isFetching, isError, error = {} } = useGetContractQuery({ projectId, contractId });

  if (isLoading || isFetching) return <CircularProgressComponent />;
  if (isError) return <ErrorLoadingComponent error={error} />;
  if (!contract) return <NotFoundComponent />;

  const getDefaultTabValue = () => {
    return userProjectRole === UserProjectRole.Homeowner ||
      matchPath('projects/:projectId/contracts/:contractId/view', location.pathname)
      ? 1
      : 0;
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={<ChevronRightRounded fontSize="small" />}
          sx={{ pl: 0 }}
        >
          <Link underline="none" color="neutral" href="/" aria-label="Home">
            <HomeRounded />
          </Link>
          <Link underline="hover" color="neutral" href="/projects" fontSize={12} fontWeight={500}>
            Projects
          </Link>
          <Link
            underline="hover"
            color="neutral"
            href={`/projects/${contract.projectId}`}
            fontSize={12}
            fontWeight={500}
          >
            <ProjectNameComponent projectId={contract.projectId} />
          </Link>
          <Link
            underline="hover"
            color="neutral"
            href={`/projects/${contract.projectId}/contracts`}
            fontSize={12}
            fontWeight={500}
          >
            Work orders
          </Link>
          <Typography color="primary" fontWeight={500} fontSize={12}>
            {contract.name}
          </Typography>
        </Breadcrumbs>
      </Box>
      <Box
        sx={{
          display: 'flex',
          mb: 1,
          gap: 1,
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'start', sm: 'center' },
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Typography level="h2" component="h1">
          {contract.name}
        </Typography>
        {isActiveProject(project) && contract.status === ContractStatus.Draft && (
          <Dropdown>
            <MenuButton color="secondary" startDecorator={<Add />}>
              Add to Work order
            </MenuButton>
            <Menu>
              <MenuItem
                color="secondary"
                onClick={() => {
                  dispatch(openModal(fileUploadModal));
                }}
              >
                Add File
              </MenuItem>
            </Menu>
          </Dropdown>
        )}
      </Box>

      <Tabs
        defaultValue={0}
        value={getDefaultTabValue()}
        onChange={(e, selectedTab) => {
          selectedTab === 0 && navigate(`/projects/${projectId}/contracts/${contract.id}`, { replace: true });
          selectedTab === 1 && navigate(`/projects/${projectId}/contracts/${contract.id}/view`, { replace: true });
        }}
        sx={{
          bgcolor: 'transparent',
        }}
      >
        <TabList
          size="sm"
          sx={{
            pl: { xs: 0, md: 4 },
            justifyContent: 'left',
            [`&& .${tabClasses.root}`]: {
              fontWeight: '600',
              //flex: 'initial',
              color: 'text.tertiary',
              [`&.${tabClasses.selected}`]: {
                bgcolor: 'transparent',
                color: 'text.primary',
                '&::after': {
                  height: '2px',
                  bgcolor: 'primary.500',
                },
              },
            },
            overflow: 'auto',
            scrollSnapType: 'x mandatory',
            '&::-webkit-scrollbar': { display: 'none' },
          }}
        >
          {userProjectRole === UserProjectRole.Contractor && (
            <Tab sx={{ flex: 'none', scrollSnapAlign: 'start', borderRadius: '6px 6px 0 0' }} value={0}>
              Details
            </Tab>
          )}
          <Tab sx={{ flex: 'none', scrollSnapAlign: 'start', borderRadius: '6px 6px 0 0' }} value={1}>
            {userProjectRole === UserProjectRole.Contractor && 'Homeowner '}View
          </Tab>
        </TabList>

        {userProjectRole === UserProjectRole.Contractor && (
          <TabPanel value={0}>
            <ContractForm
              contract={contract}
              files={(contract as ContractWithFiles).files}
              saveContract={updateContract}
            />
          </TabPanel>
        )}
        <TabPanel value={1}>
          <ContractView contract={contract} files={(contract as ContractWithFiles).files} />
        </TabPanel>
      </Tabs>
    </>
  );
}
