import {
  CreateInvitationRequest,
  CreateInvitationResponse,
  Invitation,
  InvitationId,
  InvitationNeedsConfirmation,
  ProjectId,
  UpdateInvitationRequest,
  UpdateInvitationResponse,
} from '@builder-bud/common';

import { api } from './api';

export const invitationsSlice = api.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    createInvitation: builder.mutation<
      Invitation | InvitationNeedsConfirmation,
      CreateInvitationRequest & { projectId: ProjectId }
    >({
      query: (body) => ({
        url: `/projects/${body.projectId}/invitations`,
        method: 'POST',
        body,
      }),
      transformResponse: (response: CreateInvitationResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to create invitation');
      },
      invalidatesTags: ['invitations'],
    }),
    editInvitation: builder.mutation<
      Invitation,
      UpdateInvitationRequest & { projectId: ProjectId; invitationId: InvitationId }
    >({
      query: (body) => ({
        url: `/projects/${body.projectId}/invitations/${body.invitationId}`,
        method: 'PATCH',
        body,
      }),
      transformResponse: (response: UpdateInvitationResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to edit invititation');
      },
      invalidatesTags: ['invitations', 'users', 'projects', 'me'],
    }),
    deleteInvitation: builder.mutation<Invitation, { projectId: ProjectId; invitationId: InvitationId }>({
      query: (body) => ({
        url: `/projects/${body.projectId}/invitations/${body.invitationId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['invitations'],
    }),
  }),
});

export const { useCreateInvitationMutation, useEditInvitationMutation, useDeleteInvitationMutation } = invitationsSlice;
