import { yupResolver } from '@hookform/resolvers/yup';
import { parse } from 'date-fns';
import * as yup from 'yup';

import {
  CreateEventRequest,
  Event,
  EventRelationship,
  UpdateEventRequest,
  UserId,
  dateToDateWithoutTime,
} from '@builder-bud/common';

import { UserOption } from './users.slice';

export type EventFormData = {
  name: string;
  notes?: string;
  startDate: Date;
  endDate: Date;
  assignedToUserIds: UserId[];
  interestedUserIds: UserId[];
};

export const EventSchemaResolver = yupResolver(
  yup.object<EventFormData>().shape({
    name: yup.string().required('Name is required').max(256, 'Name must be 256 characters or less'),
    notes: yup.string().max(2048, 'Notes must be 2048 characters or less'),
    startDate: yup
      .date()
      .required('Start date is required')
      .max(yup.ref('endDate'), "Start date can't be after End date"),
    endDate: yup
      .date()
      .required('End date is required')
      .min(yup.ref('startDate'), "End date can't be before Start date"),
    assignedToUserIds: yup.array().required().min(1, 'Assigned to is required'),
    interestedUserIds: yup.array().required(),
  })
);

export function getDefaultEventFormValues(users: UserOption[], event: Partial<Event>): EventFormData {
  const assignedToUsers = event.relatedUsers
    ? event.relatedUsers?.filter((relatedUser) => relatedUser.relationship === EventRelationship.Assigned)
    : [];
  const interestedUsers = event.relatedUsers
    ? event.relatedUsers?.filter((relatedUser) => relatedUser.relationship === EventRelationship.Interested)
    : [];

  return {
    name: event.name ? event.name : '',
    notes: event.notes ? event.notes : '',
    //Convert from string 'YYYY-MM-DD' to Date object
    startDate: event.startDate ? parse(event.startDate, 'yyyy-MM-dd', new Date()) : new Date(),
    endDate: event.endDate ? parse(event.endDate, 'yyyy-MM-dd', new Date()) : new Date(),
    assignedToUserIds: assignedToUsers.map((user) => user.id),
    interestedUserIds: interestedUsers.map((user) => user.id),
  };
}

export function getEventSubmitData(data: EventFormData): CreateEventRequest | UpdateEventRequest {
  const assignedToUsers = data.assignedToUserIds.map((userId) => {
    return { id: userId, relationship: EventRelationship.Assigned };
  });
  const interestedUsers = data.interestedUserIds.map((userId) => {
    return { id: userId, relationship: EventRelationship.Interested };
  });

  return {
    //Send null to clear values
    name: data.name,
    notes: data.notes === '' ? null : data.notes,
    //Convert from string 'YYYY-MM-DD' to Date object
    startDate: data.startDate ? dateToDateWithoutTime(data.startDate) : undefined,
    endDate: data.endDate ? dateToDateWithoutTime(data.endDate) : undefined,
    relatedUsers: assignedToUsers.concat(interestedUsers),
  };
}
