import {
  Box,
  Button,
  Card,
  CardActions,
  CardOverflow,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Input,
  Option,
  Select,
  Stack,
  Textarea,
  Typography,
} from '@mui/joy';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

import { Event, canUserUpdateEvent, canUserUpdateEventRelatedUsers } from '@builder-bud/common';
import {
  AlertObject,
  EventFormData,
  EventSchemaResolver,
  UserOption,
  getDefaultEventFormValues,
  getEventSubmitData,
  getUserProjectRole,
  showErrorAlert,
  showInfoAlert,
  useAppDispatch,
  useAppSelector,
} from '@builder-bud/common-ui';

import { DatePickerComponent } from '../../components/date-picker.component';

export default function EventForm({
  event,
  saveEvent,
  users,
}: {
  event: Partial<Event>;
  saveEvent: any;
  users: UserOption[];
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const me = useAppSelector((state) => state.auth.me);
  const userProjectRole = getUserProjectRole(event.projectId, me?.projects);
  const isAllowedUpdateEvent =
    !event.id || (me && event.id && userProjectRole && canUserUpdateEvent(event as Event, userProjectRole, me.id));
  const isAllowedUpdateEventRelatedUsers =
    !event.id || (me && event.id && userProjectRole && canUserUpdateEventRelatedUsers(event as Event, me.id));

  const [saving, setSaving] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<EventFormData>({
    defaultValues: getDefaultEventFormValues(users, event),
    resolver: EventSchemaResolver,
  });

  async function onSubmit(data: EventFormData) {
    setSaving(true);
    try {
      const eventData = getEventSubmitData(data);
      if (isAllowedUpdateEvent && !isAllowedUpdateEventRelatedUsers) {
        delete eventData.relatedUsers;
      }
      const response = await saveEvent({ ...eventData, ...{ id: event.id, projectId: event.projectId } }).unwrap();
      dispatch(showInfoAlert(event.id ? 'Successfully updated event' : 'Successfully created event'));
      if (matchPath(`/projects/${response.projectId}/events/new`, location.pathname)) {
        navigate(`/projects/${response.projectId}/events/${response.id}`, { replace: true });
      }
    } catch (error) {
      console.log(error);
      dispatch(showErrorAlert(error as AlertObject));
    } finally {
      setSaving(false);
    }
  }

  return (
    <Card style={{ backgroundColor: 'var(--joy-palette-common-white)' }}>
      <Box sx={{ mb: 1 }}>
        <Typography level="title-md">Event info</Typography>
      </Box>
      <Divider />
      <Stack direction="row" spacing={3} sx={{ display: { xs: 'flex' }, my: 1 }}>
        <Stack spacing={2} sx={{ flexGrow: 1, display: 'grid' }}>
          <Grid container spacing={2}>
            <Grid xs={12} md={6}>
              <Controller
                control={control}
                name="name"
                render={({ field: { onChange, onBlur, value: controlValue } }) => (
                  <FormControl error={!!errors.name} required>
                    <FormLabel>Event name</FormLabel>
                    <Input value={controlValue} onBlur={onBlur} onChange={onChange} disabled={!isAllowedUpdateEvent} />
                    {errors.name && <FormHelperText>{errors.name.message}</FormHelperText>}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid xs={6} md={3}>
              <Controller
                control={control}
                name="startDate"
                render={({ field: { onChange, onBlur, value: controlValue } }) => (
                  <FormControl error={!!errors.startDate}>
                    <FormLabel>Start date</FormLabel>
                    <DatePickerComponent value={controlValue} onChange={onChange} disabled={!isAllowedUpdateEvent} />
                    {errors.startDate && <FormHelperText>{errors.startDate.message}</FormHelperText>}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid xs={6} md={3}>
              <Controller
                control={control}
                name="endDate"
                render={({ field: { onChange, onBlur, value: controlValue } }) => (
                  <FormControl error={!!errors.endDate}>
                    <FormLabel>End date</FormLabel>
                    <DatePickerComponent value={controlValue} onChange={onChange} disabled={!isAllowedUpdateEvent} />
                    {errors.endDate && <FormHelperText>{errors.endDate.message}</FormHelperText>}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid xs={12} md={6}>
              <Controller
                control={control}
                name="assignedToUserIds"
                render={({ field: { onChange, onBlur, value: controlValue } }) => (
                  <FormControl error={!!errors.assignedToUserIds}>
                    <FormLabel>Assigned To</FormLabel>
                    <Select
                      value={controlValue}
                      onChange={(e, newValue) => {
                        onChange(newValue);
                      }}
                      onBlur={onBlur}
                      multiple={true}
                      disabled={!isAllowedUpdateEventRelatedUsers}
                    >
                      {users.map((option, i) => (
                        <Option key={option.value} value={option.value}>
                          {option.label}
                        </Option>
                      ))}
                    </Select>
                    {errors.assignedToUserIds && <FormHelperText>{errors.assignedToUserIds.message}</FormHelperText>}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid xs={12} md={6}>
              <Controller
                control={control}
                name="interestedUserIds"
                render={({ field: { onChange, onBlur, value: controlValue } }) => (
                  <FormControl error={!!errors.interestedUserIds}>
                    <FormLabel>Interested</FormLabel>
                    <Select
                      value={controlValue}
                      onChange={(e, newValue) => {
                        onChange(newValue);
                      }}
                      onBlur={onBlur}
                      multiple={true}
                      disabled={!isAllowedUpdateEventRelatedUsers}
                    >
                      {users.map((option, i) => (
                        <Option key={option.value} value={option.value}>
                          {option.label}
                        </Option>
                      ))}
                    </Select>
                    {errors.interestedUserIds && <FormHelperText>{errors.interestedUserIds.message}</FormHelperText>}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid xs={12}>
              <Controller
                control={control}
                name="notes"
                render={({ field: { onChange, onBlur, value: controlValue } }) => (
                  <FormControl error={!!errors.notes}>
                    <FormLabel>Notes</FormLabel>
                    <Textarea
                      value={controlValue}
                      onBlur={onBlur}
                      onChange={onChange}
                      minRows={4}
                      disabled={!isAllowedUpdateEvent}
                    />
                    {errors.notes && <FormHelperText>{errors.notes.message}</FormHelperText>}
                  </FormControl>
                )}
              />
            </Grid>
          </Grid>
        </Stack>
      </Stack>
      {isAllowedUpdateEvent && (
        <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
          <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
            <Button
              size="sm"
              variant="outlined"
              onClick={() => (event.id ? reset() : navigate(-1))}
              disabled={!isDirty && !!event.id}
            >
              Cancel
            </Button>
            <Button size="sm" variant="solid" onClick={handleSubmit(onSubmit)} disabled={!isDirty || saving}>
              Save
            </Button>
          </CardActions>
        </CardOverflow>
      )}
    </Card>
  );
}
