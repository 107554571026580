import { Add, SmartphoneRounded } from '@mui/icons-material';
import { Button, Card, Tooltip, Typography } from '@mui/joy';
import { useNavigate } from 'react-router-dom';

import { canUserCreateProject } from '@builder-bud/common';
import { useAppSelector, useGetProjectsQuery } from '@builder-bud/common-ui';

function NoProjectsComponent() {
  const navigate = useNavigate();

  const me = useAppSelector((state) => state.auth.me);
  const { data: projects = [] } = useGetProjectsQuery();
  const isAllowedCreateProject = me && canUserCreateProject(me, projects.length);

  return (
    <Card sx={styles.card}>
      <Typography level="h3">Hi {me?.firstName},</Typography>
      <img src="../../assets/line-house-hand.png" style={styles.image} alt="" />
      <Typography level="h3" textAlign="center">
        You are in good hands.
      </Typography>
      <Typography level="h4" textAlign="center">
        Hit the Add project button in the top right to get started with your first project.
      </Typography>
      <Tooltip
        title={
          isAllowedCreateProject
            ? ''
            : 'You have used the max projects for your current subscription. Please go to Settings > Subscriptions Management'
        }
        variant="solid"
        placement="top"
        arrow
        onClick={() => {
          navigate('settings/subscriptions');
        }}
      >
        <Button
          color="secondary"
          variant="solid"
          startDecorator={<Add />}
          onClick={() => navigate('/projects/new')}
          disabled={!isAllowedCreateProject}
        >
          Add project
        </Button>
      </Tooltip>
      <Typography level="h4" textAlign="center">
        Or you can download the mobile app to get started.
      </Typography>
      <Button
        color="neutral"
        variant="outlined"
        startDecorator={<SmartphoneRounded />}
        onClick={() => navigate('/appDownload')}
        style={{ backgroundColor: 'var(--joy-palette-common-white)' }}
      >
        App Download
      </Button>
    </Card>
  );
}

export default NoProjectsComponent;

const styles = {
  card: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    height: 320,
  },
};
