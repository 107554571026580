import { Add, ChevronRightRounded, DriveEta, HomeRounded } from '@mui/icons-material';
import {
  Badge,
  Box,
  Breadcrumbs,
  Button,
  Dropdown,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  Stack,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Typography,
  tabClasses,
} from '@mui/joy';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

import {
  Contract,
  File,
  ProjectId,
  ProjectWithFiles,
  UserProjectRole,
  canUserCreateChat,
  canUserCreateContract,
  canUserCreateEvent,
  canUserCreateInvitation,
  canUserCreateProjectNotification,
  canUserCreateTask,
  canUserReadContract,
} from '@builder-bud/common';
import {
  PLANS_FILE_TAG,
  SPECIFICATIONS_FILE_TAG,
  getContractsBadge,
  getUserProjectRole,
  isActiveProject,
  openModal,
  useAppDispatch,
  useEditProjectMutation,
  useGetChatsQuery,
  useGetContractsQuery,
  useGetDailyLogsQuery,
  useGetEventsQuery,
  useGetMeQuery,
  useGetProjectQuery,
  useGetTasksQuery,
} from '@builder-bud/common-ui';

import CircularProgressComponent from '../../components/circular-progress.component';
import ErrorLoadingComponent from '../../components/error-loading.component';
import NotFoundComponent from '../../components/not-found.component';
import { useRequiredParams } from '../../utils';
import ChatsTableComponent from '../chats/chats-table.component';
import ContractsTableComponent from '../contracts/contracts-table.component';
import DailyLogsTableComponent from '../daily-logs/daily-logs-table.component';
import EventsTableComponent from '../events/events-table.component';
import FilesTableComponent from '../files/files-table.component';
import FileUploadModal from '../files/project-file-upload.modal';
import TasksTableComponent from '../tasks/tasks-table.component';
import MembersTableComponent from './members-table.component';
import OnMyWayUploadModal from './on-my-way.modal';
import ProjectForm from './project.form';

export default function ProjectDetailsScreen() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { projectId } = useRequiredParams<{ projectId: ProjectId }>();

  const [editProject] = useEditProjectMutation();

  const fileUploadModal = (defaultFileTag?: string) => (
    <FileUploadModal projectId={projectId} defaultFileTag={defaultFileTag} />
  );
  const onMyWayModal = <OnMyWayUploadModal projectId={projectId} />;

  const {
    data: me,
    isLoading: isMeLoading,
    isFetching: isMeFetching,
    isError: isMeError,
    error: meError = {},
  } = useGetMeQuery();
  const {
    data: project,
    isLoading: isProjectLoading,
    isFetching: isProjectFetching,
    isError: isProjectError,
    error: projectsError = {},
  } = useGetProjectQuery(projectId);
  const {
    data: tasks = [],
    isLoading: isTasksLoading,
    isFetching: isTasksFetching,
    isError: isTasksError,
    error: tasksError = {},
  } = useGetTasksQuery({ projectId });

  const {
    data: events = [],
    isLoading: isEventsLoading,
    isFetching: isEventsFetching,
    isError: isEventsError,
    error: eventsError = {},
  } = useGetEventsQuery({ projectId });

  const {
    data: chats = [],
    isLoading: isChatsLoading,
    isFetching: isChatsFetching,
    isError: isChatsError,
    error: chatsError = {},
  } = useGetChatsQuery({ projectId });

  const {
    data: dailyLogs = [],
    isLoading: isDailyLogsLoading,
    isFetching: isDailyLogsFetching,
    isError: isDailyLogsError,
    error: dailyLogsError = {},
  } = useGetDailyLogsQuery({ projectId });

  const {
    data: contracts = [],
    isLoading: isContractsLoading,
    isFetching: isContractsFetching,
    isError: isContractsError,
    error: contractsError = {},
  } = useGetContractsQuery({ projectId });

  const isLoading =
    isMeLoading ||
    isProjectLoading ||
    isTasksLoading ||
    isEventsLoading ||
    isChatsLoading ||
    isDailyLogsLoading ||
    isContractsLoading;
  const isFetching =
    isMeFetching ||
    isProjectFetching ||
    isTasksFetching ||
    isEventsFetching ||
    isChatsFetching ||
    isDailyLogsFetching ||
    isContractsFetching;
  const isError =
    isMeError ||
    isProjectError ||
    isTasksError ||
    isEventsError ||
    isChatsError ||
    isDailyLogsError ||
    isContractsError;
  const error = isMeError
    ? meError
    : isProjectError
    ? projectsError
    : isTasksError
    ? tasksError
    : isEventsError
    ? eventsError
    : isChatsError
    ? chatsError
    : isDailyLogsError
    ? dailyLogsError
    : isContractsError
    ? contractsError
    : {};

  if (isLoading || isFetching) return <CircularProgressComponent />;
  if (isError) return <ErrorLoadingComponent error={error} />;
  if (!project) return <NotFoundComponent />;

  const contractsBadge = getContractsBadge(contracts);
  const userProjectRole = getUserProjectRole(project.id, me?.projects);
  const isAllowedReadContracts =
    userProjectRole &&
    contracts.some((contract: Contract) => {
      return canUserReadContract(userProjectRole, contract);
    });

  const isAllowedCreateProjectNotification = userProjectRole && canUserCreateProjectNotification(userProjectRole);

  const getDefaultTabValue = () => {
    return matchPath('projects/:projectId/contracts', location.pathname)
      ? 1
      : matchPath('projects/:projectId/members', location.pathname)
      ? 2
      : matchPath('projects/:projectId/tasks', location.pathname)
      ? 3
      : matchPath('projects/:projectId/chats', location.pathname)
      ? 4
      : matchPath('projects/:projectId/dailyLogs', location.pathname)
      ? 5
      : matchPath('projects/:projectId/events', location.pathname)
      ? 6
      : matchPath('projects/:projectId/plans', location.pathname)
      ? 7
      : matchPath('projects/:projectId/specs', location.pathname)
      ? 8
      : matchPath('projects/:projectId/files', location.pathname)
      ? 9
      : 0;
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={<ChevronRightRounded fontSize="small" />}
          sx={{ pl: 0 }}
        >
          <Link underline="none" color="neutral" href="/" aria-label="Home">
            <HomeRounded />
          </Link>
          <Link underline="hover" color="neutral" href="/projects" fontSize={12} fontWeight={500}>
            Projects
          </Link>
          <Typography color="primary" fontWeight={500} fontSize={12}>
            {project.name}
          </Typography>
        </Breadcrumbs>
      </Box>
      <Box
        sx={{
          display: 'flex',
          mb: 1,
          gap: 1,
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'start', sm: 'center' },
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Typography level="h2" component="h1">
          {project.name}
        </Typography>
        <Stack sx={{ flexDirection: 'row', columnGap: 1 }}>
          {isAllowedCreateProjectNotification && isActiveProject(project) && (
            <Button
              color="tertiary"
              onClick={() => {
                dispatch(openModal(onMyWayModal));
              }}
            >
              <DriveEta />
              On my way
            </Button>
          )}
          {isActiveProject(project) && userProjectRole && (
            <Dropdown>
              <MenuButton color="secondary" variant="solid" startDecorator={<Add />}>
                Add to project
              </MenuButton>
              <Menu color="secondary" variant="solid">
                {canUserCreateInvitation(userProjectRole) && (
                  <MenuItem onClick={() => navigate(`/projects/${project.id}/invitePeople`)}>Invite People</MenuItem>
                )}
                {canUserCreateTask(userProjectRole) && (
                  <MenuItem color="secondary" onClick={() => navigate(`/projects/${project.id}/tasks/new`)}>
                    Add Task
                  </MenuItem>
                )}
                {canUserCreateEvent(userProjectRole) && (
                  <MenuItem color="secondary" onClick={() => navigate(`/projects/${project.id}/events/new`)}>
                    Add Event
                  </MenuItem>
                )}
                {canUserCreateChat(userProjectRole) && (
                  <MenuItem color="secondary" onClick={() => navigate(`/projects/${project.id}/chats/new`)}>
                    Add Chat
                  </MenuItem>
                )}
                {/* All roles can create at least one daily log type */}
                <MenuItem color="secondary" onClick={() => navigate(`/projects/${project.id}/dailyLogs/new`)}>
                  Add Daily log
                </MenuItem>
                {canUserCreateContract(userProjectRole) && (
                  <MenuItem color="secondary" onClick={() => navigate(`/projects/${project.id}/contracts/new`)}>
                    Add Work order
                  </MenuItem>
                )}
                <MenuItem
                  color="secondary"
                  onClick={() => {
                    dispatch(openModal(fileUploadModal()));
                  }}
                >
                  Add Files
                </MenuItem>
                <MenuItem
                  color="secondary"
                  onClick={() => {
                    dispatch(openModal(fileUploadModal(PLANS_FILE_TAG)));
                  }}
                >
                  Add Plans
                </MenuItem>
                <MenuItem
                  color="secondary"
                  onClick={() => {
                    dispatch(openModal(fileUploadModal(SPECIFICATIONS_FILE_TAG)));
                  }}
                >
                  Add Specs
                </MenuItem>
              </Menu>
            </Dropdown>
          )}
        </Stack>
      </Box>
      <Tabs
        defaultValue={0}
        value={getDefaultTabValue()}
        onChange={(e, selectedTab) => {
          selectedTab === 0 && navigate(`/projects/${projectId}`, { replace: true });
          selectedTab === 1 && navigate(`/projects/${projectId}/contracts`, { replace: true });
          selectedTab === 2 && navigate(`/projects/${projectId}/members`, { replace: true });
          selectedTab === 3 && navigate(`/projects/${projectId}/tasks`, { replace: true });
          selectedTab === 4 && navigate(`/projects/${projectId}/chats`, { replace: true });
          selectedTab === 5 && navigate(`/projects/${projectId}/dailyLogs`, { replace: true });
          selectedTab === 6 && navigate(`/projects/${projectId}/events`, { replace: true });
          selectedTab === 7 && navigate(`/projects/${projectId}/plans`, { replace: true });
          selectedTab === 8 && navigate(`/projects/${projectId}/specs`, { replace: true });
          selectedTab === 9 && navigate(`/projects/${projectId}/files`, { replace: true });
        }}
        sx={{
          bgcolor: 'transparent',
        }}
      >
        <TabList
          size="sm"
          sx={{
            pl: { xs: 0, md: 4 },
            justifyContent: 'left',
            [`&& .${tabClasses.root}`]: {
              fontWeight: '600',
              //flex: 'initial',
              color: 'text.tertiary',
              [`&.${tabClasses.selected}`]: {
                bgcolor: 'transparent',
                color: 'text.primary',
                '&::after': {
                  height: '2px',
                  bgcolor: 'primary.500',
                },
              },
            },
            overflow: 'auto',
            scrollSnapType: 'x mandatory',
            '&::-webkit-scrollbar': { display: 'none' },
          }}
        >
          <Tab sx={{ flex: 'none', scrollSnapAlign: 'start', borderRadius: '6px 6px 0 0' }} value={0}>
            Details
          </Tab>
          {isAllowedReadContracts && (
            <Tab sx={{ flex: 'none', scrollSnapAlign: 'start', borderRadius: '6px 6px 0 0' }} value={1}>
              Work orders
              {userProjectRole === UserProjectRole.Homeowner && contractsBadge > 0 && (
                <Badge badgeContent={contractsBadge} sx={{ marginLeft: 1 }} />
              )}
            </Tab>
          )}
          <Tab sx={{ flex: 'none', scrollSnapAlign: 'start', borderRadius: '6px 6px 0 0' }} value={2}>
            Members
          </Tab>
          <Tab sx={{ flex: 'none', scrollSnapAlign: 'start', borderRadius: '6px 6px 0 0' }} value={3}>
            Tasks
          </Tab>
          <Tab sx={{ flex: 'none', scrollSnapAlign: 'start', borderRadius: '6px 6px 0 0' }} value={4}>
            Chats
          </Tab>
          <Tab sx={{ flex: 'none', scrollSnapAlign: 'start', borderRadius: '6px 6px 0 0' }} value={5}>
            Daily logs
          </Tab>
          <Tab sx={{ flex: 'none', scrollSnapAlign: 'start', borderRadius: '6px 6px 0 0' }} value={6}>
            Events
          </Tab>
          <Tab sx={{ flex: 'none', scrollSnapAlign: 'start', borderRadius: '6px 6px 0 0' }} value={7}>
            Plans
          </Tab>
          <Tab sx={{ flex: 'none', scrollSnapAlign: 'start', borderRadius: '6px 6px 0 0' }} value={8}>
            Specs
          </Tab>
          <Tab sx={{ flex: 'none', scrollSnapAlign: 'start', borderRadius: '6px 6px 0 0' }} value={9}>
            Files
          </Tab>
        </TabList>
        <TabPanel value={0}>
          <ProjectForm project={project} saveProject={editProject} />
        </TabPanel>
        {isAllowedReadContracts && (
          <TabPanel value={1}>
            <ContractsTableComponent rows={contracts} />
          </TabPanel>
        )}
        <TabPanel value={2}>
          <MembersTableComponent rows={project.userIds} projectId={project.id} />
        </TabPanel>
        <TabPanel value={3}>
          <TasksTableComponent rows={tasks} />
        </TabPanel>
        <TabPanel value={4}>
          <ChatsTableComponent rows={chats} hideSearch={true} />
        </TabPanel>
        <TabPanel value={5}>
          <DailyLogsTableComponent rows={dailyLogs} />
        </TabPanel>
        <TabPanel value={6}>
          <EventsTableComponent rows={events} />
        </TabPanel>
        <TabPanel value={7}>
          <FilesTableComponent
            rows={(project as ProjectWithFiles).files.filter((file: File) => file.tags.includes(PLANS_FILE_TAG))}
          />
        </TabPanel>
        <TabPanel value={8}>
          <FilesTableComponent
            rows={(project as ProjectWithFiles).files.filter((file: File) =>
              file.tags.includes(SPECIFICATIONS_FILE_TAG)
            )}
          />
        </TabPanel>
        <TabPanel value={9}>
          <FilesTableComponent rows={(project as ProjectWithFiles).files} />
        </TabPanel>
      </Tabs>
    </>
  );
}
