import { Box, Card, Typography } from '@mui/joy';

function NotFoundComponent() {
  return (
    <Box sx={styles.container}>
      <Card sx={styles.card}>
        <Typography level="h2">Oops,</Typography>
        <Typography level="h2">item Not Found</Typography>
        <img src={'../../assets/line-hardhat.png'} style={styles.image} alt="" />
      </Card>
    </Box>
  );
}

export default NotFoundComponent;

const styles = {
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  card: {
    maxWidth: 800,
    maxHeight: 800,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    width: '100%',
    maxWidth: 800,
  },
};
