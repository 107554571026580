import {
  Chat,
  CreateDeviceTokenRequest,
  CreateFileRequest,
  CreateFileResponse,
  DailyLogWithFiles,
  DateWithoutTime,
  Event,
  File,
  FileId,
  GetChatsResponse,
  GetEventsResponse,
  GetFilesResponse,
  GetInvitationsResponse,
  GetSignedDownloadUrlResponse,
  GetSignedUploadUrlResponse,
  GetTasksResponse,
  GetUserDailyLogsResponse,
  GetUserResponse,
  ReceivedInvitation,
  SentInvitation,
  SignedDownloadUrlData,
  SignedUploadUrlData,
  Task,
  UpdateUserRequest,
  UpdateUserResponse,
  User,
} from '@builder-bud/common';

import { api } from './api';

export const meSlice = api.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getMe: builder.query<User, void>({
      query: () => ({
        url: '/users/me',
        method: 'GET',
      }),
      transformResponse: (response: GetUserResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to get me');
      },
      providesTags: ['me'],
    }),
    updateMe: builder.mutation<User, UpdateUserRequest>({
      query: (body) => ({
        url: '/users/me',
        method: 'PATCH',
        body,
      }),
      transformResponse: (response: UpdateUserResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to update me');
      },
      // We can't invalidate this tag automatically since it will reset the onboarding flow
      // So instead invalidate the tag manually when updating profile
      //invalidatesTags: ['me']
    }),

    synchUser: builder.mutation<User, void>({
      query: () => ({
        url: '/users/me/sync',
        method: 'POST',
      }),
      transformResponse: (response: UpdateUserResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to sync me');
      },
      invalidatesTags: ['me'],
    }),

    getMeFiles: builder.query<File[], void>({
      query: () => ({
        url: '/users/me/files',
        method: 'GET',
      }),
      transformResponse: (response: GetFilesResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to get files');
      },
      providesTags: ['me_files'],
    }),
    getMeFileUploadUrl: builder.mutation<SignedUploadUrlData, { contentType?: string }>({
      query: (body) => ({
        url: `/users/me/files/upload-url?contentType=${body.contentType}`,
        method: 'GET',
      }),
      transformResponse: (response: GetSignedUploadUrlResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to get file upload url');
      },
    }),
    createMeFile: builder.mutation<File, CreateFileRequest>({
      query: (body) => ({
        url: 'users/me/files',
        method: 'POST',
        body,
      }),
      transformResponse: (response: CreateFileResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to create file');
      },
      invalidatesTags: ['me', 'me_files'],
    }),
    getMeFileDownloadUrl: builder.mutation<SignedDownloadUrlData, { fileId: FileId }>({
      query: (body) => ({
        url: `users/me/files/${body.fileId}/download-url`,
        method: 'GET',
      }),
      transformResponse: (response: GetSignedDownloadUrlResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to get file download url');
      },
    }),

    createDeviceToken: builder.mutation<void, CreateDeviceTokenRequest>({
      query: (body) => ({
        url: 'users/me/device-tokens',
        method: 'POST',
        body,
      }),
    }),

    deleteDeviceToken: builder.mutation<void, { token: string }>({
      query: (body) => ({
        url: `users/me/device-tokens?token=${body.token}`,
        method: 'DELETE',
      }),
    }),

    getMeInvitations: builder.query<{ sent: SentInvitation[]; received: ReceivedInvitation[] }, void>({
      query: () => ({
        url: '/users/me/invitations',
        method: 'GET',
      }),
      transformResponse: (response: GetInvitationsResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to get invitations');
      },
      providesTags: ['invitations'],
    }),

    getMeChats: builder.query<Chat[], void>({
      query: () => ({
        url: '/users/me/chats',
        method: 'GET',
      }),
      transformResponse: (response: GetChatsResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to get chats');
      },
      providesTags: ['me_chats'],
    }),

    getMeTasks: builder.query<Task[], { startDate: DateWithoutTime; endDate: DateWithoutTime }>({
      query: (body) => ({
        url: `/users/me/tasks?startDate=${body.startDate}&endDate=${body.endDate}`,
        method: 'GET',
      }),
      transformResponse: (response: GetTasksResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to get tasks');
      },
      providesTags: ['me_tasks'],
    }),

    getMeEvents: builder.query<Event[], { startDate: DateWithoutTime; endDate: DateWithoutTime }>({
      query: (body) => ({
        url: `/users/me/events?startDate=${body.startDate}&endDate=${body.endDate}`,
        method: 'GET',
      }),
      transformResponse: (response: GetEventsResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to get events');
      },
      providesTags: ['me_events'],
    }),
    getMeDailyLogs: builder.query<DailyLogWithFiles[], { startDate: DateWithoutTime; endDate: DateWithoutTime }>({
      query: (body) => ({
        url: `/users/me/daily-logs?startDate=${body.startDate}&endDate=${body.endDate}`,
        method: 'GET',
      }),
      transformResponse: (response: GetUserDailyLogsResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to get daily logs');
      },
      providesTags: ['me_daily_logs'],
    }),
  }),
});

export const {
  useGetMeQuery,
  useLazyGetMeQuery,
  useUpdateMeMutation,
  useSynchUserMutation,
  useGetMeFilesQuery,
  useGetMeFileUploadUrlMutation,
  useCreateMeFileMutation,
  useGetMeFileDownloadUrlMutation,
  useGetMeInvitationsQuery,
  useCreateDeviceTokenMutation,
  useDeleteDeviceTokenMutation,
  useGetMeChatsQuery,
  useLazyGetMeChatsQuery,
  useGetMeTasksQuery,
  useGetMeEventsQuery,
  useGetMeDailyLogsQuery,
} = meSlice;
