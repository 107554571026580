import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { useDispatch, useSelector } from 'react-redux';

import { alertsSlice } from './alerts.slice';
import { api } from './api';
import { authSlice } from './auth.slice';
import { chatsSlice } from './chats.slice';
import { eventsSlice } from './events.slice';
import { filesSlice } from './files.slice';
import { giftedChatSlice } from './giftedChat.slice';
import { layoutSlice } from './layout.slice';
import { meSlice } from './me.slice';
import { messagesSlice } from './messages.slice';
import { projectsSlice } from './projects.slice';
import { tasksSlice } from './tasks.slice';
import { usersSlice } from './users.slice';

export const store = configureStore({
  reducer: {
    api: api.reducer,
    auth: authSlice.reducer,
    me: meSlice.reducer,
    layout: layoutSlice.reducer,
    alerts: alertsSlice.reducer,
    users: usersSlice.reducer,
    projects: projectsSlice.reducer,
    tasks: tasksSlice.reducer,
    files: filesSlice.reducer,
    chats: chatsSlice.reducer,
    messages: messagesSlice.reducer,
    giftedChat: giftedChatSlice.reducer,
    events: eventsSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(api.middleware),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<RootState>();
