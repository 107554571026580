/* eslint-disable jsx-a11y/anchor-is-valid */
import { Close, FilterAlt, Search } from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Modal,
  ModalClose,
  ModalDialog,
  Sheet,
  Table,
  Typography,
} from '@mui/joy';
import { useState } from 'react';

import EmptyListComponent from './empty-list.component';

export default function TableComponent({
  rows,
  renderFilters,
  renderTableHead,
  TableRow,
  comparator,
  hideSearch = false,
  hideEmptyList = false,
  searchField = 'name',
}: {
  rows: any[];
  renderFilters: () => JSX.Element;
  renderTableHead: () => JSX.Element;
  TableRow: any; //TODO Fix any
  comparator: (a: any, b: any) => number;
  hideSearch?: boolean;
  hideEmptyList?: boolean;
  searchField?: string;
}) {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');

  const searchRows =
    search !== '' ? rows.filter((row) => row[searchField].toLowerCase().includes(search.toLowerCase())) : rows;

  return (
    <>
      {!hideSearch && (
        <>
          <Sheet
            sx={{
              display: { xs: 'flex', sm: 'none' },
              my: 1,
              gap: 1,
            }}
          >
            <Input
              size="sm"
              placeholder={`Filter by ${searchField.charAt(0).toUpperCase() + searchField.slice(1)}`}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              startDecorator={<Search />}
              endDecorator={search !== '' ? <Close onClick={() => setSearch('')} /> : null}
              sx={{ flexGrow: 1 }}
            />
            <IconButton size="sm" variant="outlined" color="neutral" onClick={() => setOpen(true)}>
              <FilterAlt />
            </IconButton>
            <Modal open={open} onClose={() => setOpen(false)}>
              <ModalDialog aria-labelledby="filter-modal" layout="fullscreen" sx={{ mt: 'var(--Header-height)' }}>
                <ModalClose />
                <Typography id="filter-modal" level="h2">
                  Filters
                </Typography>
                <Divider sx={{ my: 2 }} />
                <Sheet sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  {renderFilters()}
                  <Button color="primary" onClick={() => setOpen(false)}>
                    Submit
                  </Button>
                </Sheet>
              </ModalDialog>
            </Modal>
          </Sheet>

          <Box
            sx={{
              borderRadius: 'sm',
              py: 2,
              display: { xs: 'none', sm: 'flex' },
              flexWrap: 'wrap',
              gap: 1.5,
              '& > *': {
                minWidth: { xs: '120px', md: '160px' },
              },
            }}
          >
            <FormControl sx={{ flex: 1 }} size="sm">
              <FormLabel>{searchField.charAt(0).toUpperCase() + searchField.slice(1)}</FormLabel>
              <Input
                size="sm"
                placeholder={`Filter by ${searchField.charAt(0).toUpperCase() + searchField.slice(1)}`}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                startDecorator={<Search />}
                endDecorator={search !== '' ? <Close onClick={() => setSearch('')} /> : null}
              />
            </FormControl>
            {renderFilters()}
          </Box>
        </>
      )}
      <Sheet
        variant="outlined"
        sx={{
          width: '100%',
          borderRadius: 'sm',
          flexShrink: 1,
          overflow: 'auto',
          minHeight: 0,
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          hoverRow
          sx={{
            '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
            '--Table-headerUnderlineThickness': '1px',
            '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
            '--TableCell-paddingY': '4px',
            '--TableCell-paddingX': '8px',
          }}
        >
          {searchRows.length === 0 && !hideEmptyList ? (
            <caption>
              <EmptyListComponent />
            </caption>
          ) : (
            <>
              {renderTableHead()}
              <tbody>
                {[...searchRows].sort(comparator).map((row, i) => (
                  <TableRow key={i} row={row} />
                ))}
              </tbody>
            </>
          )}
        </Table>
      </Sheet>
    </>
  );
}
