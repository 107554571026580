import { yupResolver } from '@hookform/resolvers/yup';
import { parse } from 'date-fns';
import * as yup from 'yup';

import {
  CONTRACT_STATUS_LABELS,
  CONTRACT_TERMS_LABELS,
  CONTRACT_TYPE_LABELS,
  Contract,
  ContractId,
  ContractLineItem,
  ContractStatus,
  ContractTerms,
  ContractType,
  CreateContractLineItemRequest,
  CreateContractRequest,
  UpdateContractLineItemRequest,
  UpdateContractRequest,
  dateToDateWithoutTime,
} from '@builder-bud/common';

import { BBListItem } from './common-ui';

export const CONTRACT_STATUS_OPTIONS: BBListItem[] = [
  { value: ContractStatus.Draft, label: CONTRACT_STATUS_LABELS[ContractStatus.Draft], color: 'outline' },
  { value: ContractStatus.Published, label: CONTRACT_STATUS_LABELS[ContractStatus.Published], color: 'tertiary' },
  { value: ContractStatus.Approved, label: CONTRACT_STATUS_LABELS[ContractStatus.Approved], color: 'secondary' },
  { value: ContractStatus.Paid, label: CONTRACT_STATUS_LABELS[ContractStatus.Paid], color: 'primary' },
  { value: ContractStatus.Declined, label: CONTRACT_STATUS_LABELS[ContractStatus.Declined], color: 'error' },
] as const;

export const CONTRACT_TYPE_OPTIONS: BBListItem[] = [
  { value: ContractType.Estimate, label: CONTRACT_TYPE_LABELS[ContractType.Estimate] },
  //{ value: ContractType.Contract, label: CONTRACT_TYPE_LABELS[ContractType.Contract] },
  { value: ContractType.ChangeOrder, label: CONTRACT_TYPE_LABELS[ContractType.ChangeOrder] },
  { value: ContractType.ProgressInvoice, label: CONTRACT_TYPE_LABELS[ContractType.ProgressInvoice] },
  { value: ContractType.Invoice, label: CONTRACT_TYPE_LABELS[ContractType.Invoice] },
] as const;

export const CONTRACT_TERMS_OPTIONS: BBListItem[] = [
  { value: ContractTerms.DueOnReceipt, label: CONTRACT_TERMS_LABELS[ContractTerms.DueOnReceipt] },
  { value: ContractTerms.Net15, label: CONTRACT_TERMS_LABELS[ContractTerms.Net15] },
  { value: ContractTerms.Net30, label: CONTRACT_TERMS_LABELS[ContractTerms.Net30] },
  { value: ContractTerms.Net60, label: CONTRACT_TERMS_LABELS[ContractTerms.Net60] },
  { value: ContractTerms.Net90, label: CONTRACT_TERMS_LABELS[ContractTerms.Net90] },
] as const;

export function getContractStatusLabel(value: ContractStatus): string {
  return CONTRACT_STATUS_LABELS[value] ?? '';
}

export function getContractTypeLabel(value: ContractType): string {
  return CONTRACT_TYPE_LABELS[value] ?? '';
}

export function getContractTermsLabel(value: ContractTerms): string {
  return CONTRACT_TERMS_LABELS[value] ?? '';
}

export type ContractFormData = {
  type: ContractType;
  name: string;
  description?: string;
  reference?: string;
  terms?: ContractTerms;
  invoiceDate?: Date;
  dueDate?: Date;
  signatureRequired: boolean;
};

export const ContractSchemaResolver = yupResolver(
  yup.object<ContractFormData>().shape({
    type: yup.mixed<ContractType>().oneOf(Object.values(ContractType)).required('Contract Type is required'),
    name: yup.string().required('Name is required').max(256, 'Name must be 256 characters or less'),
    description: yup.string().max(2048, 'Description must be 2048 characters or less'),
    reference: yup.string().max(2048, 'Reference must be 2048 characters or less'),
    terms: yup.mixed<ContractTerms>().oneOf(Object.values(ContractTerms)).optional(),
    invoiceDate: yup.date().optional(),
    dueDate: yup.date().optional(),
    signatureRequired: yup.boolean().required(),
  })
);

export function getDefaultContractFormValues(contract: Partial<Contract>): ContractFormData {
  const defaultType = CONTRACT_TYPE_OPTIONS.find((option) => option.value === contract.type);
  const defaultTerm = CONTRACT_TERMS_OPTIONS.find((option) => option.value === contract.terms);

  return {
    type: defaultType?.value as ContractType,
    name: contract.name ? contract.name : '',
    description: contract.description ? contract.description : '',
    reference: contract.reference ? contract.reference : '',
    terms: defaultTerm?.value as ContractTerms,
    invoiceDate: contract.invoiceDate ? parse(contract.invoiceDate, 'yyyy-MM-dd', new Date()) : undefined,
    dueDate: contract.dueDate ? parse(contract.dueDate, 'yyyy-MM-dd', new Date()) : undefined,
    signatureRequired: !!contract.signatureRequired,
  };
}

export function getContractSubmitData(data: ContractFormData): CreateContractRequest | UpdateContractRequest {
  return {
    //Send null to clear values
    type: data.type,
    name: data.name,
    description: data.description === '' ? null : data.description,
    reference: data.reference === '' ? null : data.reference,
    terms: data.terms,
    invoiceDate: data.invoiceDate ? dateToDateWithoutTime(data.invoiceDate) : undefined,
    dueDate: data.dueDate ? dateToDateWithoutTime(data.dueDate) : undefined,
    signatureRequired: data.signatureRequired,
  };
}

export function getIsContract(type: ContractType) {
  return type === ContractType.Estimate || type === ContractType.Contract || type === ContractType.ChangeOrder;
}

export function getIsInvoice(type: ContractType) {
  return type === ContractType.ProgressInvoice || type === ContractType.Invoice;
}

export type LineItemFormData = {
  contractId?: ContractId;
  name: string;
  description?: string | undefined;
  date?: Date;
  quantity: number;
  margin?: number;
  cost?: number;
  amount?: number;
  calculatedRate: number;
  calculatedAmount: number;
};

export const LineItemSchemaResolver = yupResolver(
  yup.object({
    contractId: yup.string<ContractId>().optional(),
    name: yup.string().required('Name is required').max(256, 'Name must be 256 characters or less'),
    description: yup.string().max(2048, 'Description must be 2048 characters or less').optional(),
    date: yup.date().optional(),
    quantity: yup.number().integer().typeError('Quantity must be a number').required('Quantity is required'),
    margin: yup.number().typeError('Margin must be a percentage').optional(),
    cost: yup.number().typeError('Cost must be a number').optional(),
    amount: yup.number().typeError('Amount must be a number').optional(),
    calculatedRate: yup.number().typeError('Rate must be a number').required('Rate is required'),
    calculatedAmount: yup.number().typeError('Amount must be a number').required('Amount is required'),
  })
);

export function getDefaultLineItemFormValues(lineItem: Partial<ContractLineItem>): LineItemFormData {
  return {
    contractId: lineItem.contractId,
    name: lineItem.name ? lineItem.name : '',
    description: lineItem.description ? lineItem.description : '',
    date: lineItem.date ? parse(lineItem.date, 'yyyy-MM-dd', new Date()) : undefined,
    quantity: lineItem.quantity ? lineItem.quantity : 1,
    cost: lineItem.cost ? lineItem.cost : 0,
    amount: lineItem.amount ? lineItem.amount : 0,
    margin: lineItem.margin ? Math.ceil((lineItem.margin - 1) * 100) : 0,
    calculatedRate: lineItem.calculatedRate ? lineItem.calculatedRate : 0,
    calculatedAmount: lineItem.calculatedAmount ? lineItem.calculatedAmount : 0,
  };
}

export function getLineItemSubmitData(
  data: LineItemFormData
):
  | (CreateContractLineItemRequest & { contractId?: ContractId })
  | (UpdateContractLineItemRequest & { contractId?: ContractId }) {
  return {
    //Send null to clear values
    contractId: data.contractId,
    name: data.name,
    description: data.description === '' ? null : data.description,
    date: data.date ? dateToDateWithoutTime(data.date) : undefined,
    quantity: data.quantity,
    cost: data.cost,
    amount: data.amount,
    margin: data.margin ? data.margin / 100 + 1 : 1,
  };
}

export function getContractTotalAmount(lineItems: ContractLineItem[], type: ContractType) {
  const isContract = getIsContract(type);

  return lineItems?.reduce((accumulator, lineItem) => {
    const amount = isContract ? lineItem.calculatedAmount : lineItem.amount;
    return accumulator + (amount ? amount : 0);
  }, 0);
}

export function getContractsBadge(contracts: Contract[] = []): number {
  const publishedContracts = contracts.filter((contract) => contract.status === ContractStatus.Published);
  return publishedContracts.length;
}
