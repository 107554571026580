import { EmailAuthProvider, reauthenticateWithCredential, updatePassword } from '@firebase/auth';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Button, FormControl, FormHelperText, FormLabel, Grid, Input, Stack } from '@mui/joy';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import {
  AlertObject,
  UpdatePasswordFormData,
  UpdatePasswordSchemaResolver,
  showErrorAlert,
  showInfoAlert,
  useAppDispatch,
} from '@builder-bud/common-ui';

import CircularProgressComponent from '../../components/circular-progress.component';
import { auth } from '../login/firebase';

export default function UpdatePasswordForm({ hideForm }: { hideForm: React.Dispatch<void> }) {
  const dispatch = useAppDispatch();

  const [isSaving, setSaving] = useState(false);
  const [secureCurrentPasswordTextEntry, setSecureCurrentPasswordTextEntry] = useState(true);
  const toggleSecureCurrentPasswordTextEntry = () => setSecureCurrentPasswordTextEntry(!secureCurrentPasswordTextEntry);
  const [securePasswordTextEntry, setSecurePasswordTextEntry] = useState(true);
  const toggleSecurePasswordTextEntry = () => setSecurePasswordTextEntry(!securePasswordTextEntry);

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty, errors },
  } = useForm<UpdatePasswordFormData>({
    defaultValues: {
      currentPassword: '',
      password: '',
    },
    resolver: UpdatePasswordSchemaResolver,
  });

  if (isSaving) return <CircularProgressComponent />;

  async function onSubmit(data: UpdatePasswordFormData) {
    try {
      console.log('Updating password');
      setSaving(true);

      const currentUser = auth.currentUser;
      if (!currentUser || !currentUser.email) throw new Error('Current user not found');
      const credential = EmailAuthProvider.credential(currentUser.email, data.currentPassword);

      await reauthenticateWithCredential(currentUser, credential);
      await updatePassword(currentUser, data.password);
      reset();
      console.log('Password is updated!');
      dispatch(showInfoAlert('Successfully updated password'));
      hideForm();
    } catch (error) {
      console.log(error);
      dispatch(showErrorAlert(error as AlertObject));
    } finally {
      setSaving(false);
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid xs={12}>
        <Controller
          control={control}
          name="currentPassword"
          render={({ field: { onChange, onBlur, value } }) => (
            <FormControl error={!!errors.currentPassword}>
              <FormLabel>Current Password</FormLabel>
              <Input
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                type={secureCurrentPasswordTextEntry ? 'password' : ''}
                endDecorator={
                  secureCurrentPasswordTextEntry ? (
                    <Visibility
                      onClick={() => {
                        toggleSecureCurrentPasswordTextEntry();
                      }}
                    />
                  ) : (
                    <VisibilityOff
                      onClick={() => {
                        toggleSecureCurrentPasswordTextEntry();
                      }}
                    />
                  )
                }
              />
              {errors.currentPassword && <FormHelperText>{errors.currentPassword.message}</FormHelperText>}
            </FormControl>
          )}
        />
      </Grid>
      <Grid xs={12}>
        <Controller
          control={control}
          name="password"
          render={({ field: { onChange, onBlur, value } }) => (
            <FormControl error={!!errors.password}>
              <FormLabel>New Password</FormLabel>
              <Input
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                type={securePasswordTextEntry ? 'password' : ''}
                endDecorator={
                  securePasswordTextEntry ? (
                    <Visibility
                      onClick={() => {
                        toggleSecurePasswordTextEntry();
                      }}
                    />
                  ) : (
                    <VisibilityOff
                      onClick={() => {
                        toggleSecurePasswordTextEntry();
                      }}
                    />
                  )
                }
              />
              {errors.password && <FormHelperText>{errors.password.message}</FormHelperText>}
            </FormControl>
          )}
        />
      </Grid>

      <Grid xs={12}>
        <Stack direction="row" sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }} spacing={1}>
          <Button variant="outlined" onClick={() => hideForm()}>
            Cancel
          </Button>
          <Button onClick={handleSubmit(onSubmit)} disabled={!isDirty}>
            Update password
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
}
