import { Box, CircularProgress } from '@mui/joy';

function CircularProgressComponent({ size = 'lg' }: { size?: 'lg' | 'sm' | 'md' | undefined }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flex: '1',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress size={size} />
    </Box>
  );
}

export default CircularProgressComponent;
