import { yupResolver } from '@hookform/resolvers/yup';
import { parse } from 'date-fns';
import * as yup from 'yup';

import {
  CreateProjectRequest,
  PROJECT_STATUS_LABELS,
  PROJECT_TYPE_LABELS,
  Project,
  ProjectStatus,
  ProjectType,
  USER_PROJECT_ROLE_LABELS,
  UpdateProjectRequest,
  User,
  UserProjectRole,
  dateToDateWithoutTime,
} from '@builder-bud/common';

import { BBListItem } from './common-ui';

export const PROJECT_STATUS_OPTIONS: BBListItem[] = [
  { value: ProjectStatus.NotStarted, label: PROJECT_STATUS_LABELS[ProjectStatus.NotStarted], color: 'error' },
  { value: ProjectStatus.InProgress, label: PROJECT_STATUS_LABELS[ProjectStatus.InProgress], color: 'primary' },
  { value: ProjectStatus.Paused, label: PROJECT_STATUS_LABELS[ProjectStatus.Paused], color: 'tertiary' },
  { value: ProjectStatus.Completed, label: PROJECT_STATUS_LABELS[ProjectStatus.Completed], color: 'secondary' },
  { value: ProjectStatus.Archived, label: PROJECT_STATUS_LABELS[ProjectStatus.Archived], color: 'outline' },
] as const;

export function getProjectStatusLabel(value: ProjectStatus): string {
  return PROJECT_STATUS_LABELS[value] ?? '';
}

export const USER_PROJECT_ROLE_OPTIONS: BBListItem[] = [
  { value: UserProjectRole.Homeowner, label: USER_PROJECT_ROLE_LABELS[UserProjectRole.Homeowner] },
  { value: UserProjectRole.Contractor, label: USER_PROJECT_ROLE_LABELS[UserProjectRole.Contractor] },
  { value: UserProjectRole.Subcontractor, label: USER_PROJECT_ROLE_LABELS[UserProjectRole.Subcontractor] },
  { value: UserProjectRole.ProjectManager, label: USER_PROJECT_ROLE_LABELS[UserProjectRole.ProjectManager] },
] as const;

export function getUserProjectRoleLabel(value: UserProjectRole): string {
  return USER_PROJECT_ROLE_LABELS[value] ?? '';
}

export const PROJECT_TYPE_OPTIONS: BBListItem[] = [
  { value: ProjectType.ADU, label: PROJECT_TYPE_LABELS[ProjectType.ADU] },
  { value: ProjectType.FullGutRemodel, label: PROJECT_TYPE_LABELS[ProjectType.FullGutRemodel] },
  { value: ProjectType.Bathroom, label: PROJECT_TYPE_LABELS[ProjectType.Bathroom] },
  { value: ProjectType.Kitchen, label: PROJECT_TYPE_LABELS[ProjectType.Kitchen] },
  { value: ProjectType.NewBuild, label: PROJECT_TYPE_LABELS[ProjectType.NewBuild] },
  { value: ProjectType.Other, label: PROJECT_TYPE_LABELS[ProjectType.Other] },
] as const;

export function getProjectTypeLabel(value: ProjectType): string {
  return PROJECT_TYPE_LABELS[value] ?? '';
}

export const ON_MY_WAY_OPTIONS: BBListItem[] = [
  { value: 'within the hour', label: 'Within the hour' },
  { value: '1-2 hours', label: '1-2 hours' },
  { value: '2-4 hours', label: '2-4 hours' },
  { value: 'this morning', label: 'This morning' },
  { value: 'this afternoon', label: 'This afternoon' },
];

export type ProjectFormData = {
  name: string;
  description?: string;
  status?: ProjectStatus;
  displayAddress: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  stateCode?: string;
  zipCode?: string;
  permit?: string;
  homeDetails?: string;
  startDate?: Date;
  notes?: string;
};

export const ProjectSchemaResolver = yupResolver(
  yup.object<ProjectFormData>().shape({
    id: yup.string().optional(),
    name: yup.string().required('Name is required').max(256, 'Name must be 256 characters or less'),
    description: yup.string().max(2048, 'Description must be 2048 characters or less'),
    displayAddress: yup.string().required('Address is required'),
    status: yup.mixed<ProjectStatus>().oneOf(Object.values(ProjectStatus)).optional(),
    addressLine1: yup.string().optional(),
    addressLine2: yup.string().optional(),
    city: yup.string().optional(),
    stateCode: yup.string().optional(),
    zipCode: yup.string().optional(),
    permit: yup.string().optional(),
    homeDetails: yup.string().optional(),
    startDate: yup.date().optional(),
    notes: yup.string().max(2048, 'Notes must be 2048 characters or less'),
  })
);

export function getDefaultProjectFormValues(project: Partial<Project>): ProjectFormData {
  const defaultStatus = PROJECT_STATUS_OPTIONS.find((option) => option.value === project.status);

  return {
    name: project.name ? project.name : '',
    description: project.description ? project.description : '',
    status: defaultStatus?.value as ProjectStatus,
    displayAddress: project.displayAddress ? project.displayAddress.join(', ') : '',
    addressLine1: project.addressLine1 ? project.addressLine1 : '',
    addressLine2: project.addressLine2 ? project.addressLine2 : '',
    city: project.city ? project.city : '',
    stateCode: project.stateCode ? project.stateCode : '',
    zipCode: project.zipCode ? project.zipCode : '',
    permit: project.permit ? project.permit : '',
    homeDetails: project.homeDetails ? project.homeDetails : '',
    //Convert from string 'YYYY-MM-DD' to Date object
    startDate: project.startDate ? parse(project.startDate, 'yyyy-MM-dd', new Date()) : undefined,
    notes: project.notes ? project.notes : '',
  };
}

export function getProjectSubmitData(data: ProjectFormData): CreateProjectRequest | UpdateProjectRequest {
  return {
    name: data.name,
    //Send null to clear values
    description: data.description === '' ? null : data.description,
    addressLine1: data.addressLine1,
    addressLine2: data.addressLine2 === '' ? null : data.addressLine2,
    city: data.city,
    stateCode: data.stateCode,
    zipCode: data.zipCode,
    status: data.status,
    permit: data.permit === '' ? null : data.permit,
    homeDetails: data.homeDetails === '' ? null : data.homeDetails,
    //Convert from string 'YYYY-MM-DD' to Date object
    startDate: data.startDate ? dateToDateWithoutTime(data.startDate) : undefined,
    notes: data.notes === '' ? null : data.notes,
  };
}

export function isActiveProject(project?: Project): boolean {
  return (
    !!project &&
    (project.status === ProjectStatus.NotStarted ||
      project.status === ProjectStatus.InProgress ||
      project.status === ProjectStatus.Paused)
  );
}

export function countActiveProjects(projects: Project[], me?: User | null): number {
  return projects.filter((project) => me?.id === project.createdByUserId && isActiveProject(project)).length;
}

export const projectDetailListSize = 5;
