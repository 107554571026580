import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ReactElement } from 'react';

import {
  Project,
  User,
  canUserCreateChat,
  canUserCreateContract,
  canUserCreateEvent,
  canUserCreateInvitation,
  canUserCreateProject,
  canUserCreateTask,
} from '@builder-bud/common';

import { BBListItem } from './common-ui';
import { countActiveProjects, isActiveProject } from './projects';
import { RootState } from './store';
import { getUserProjectRole } from './users';

type PlusMenuItem = {
  icon: string;
  label: string;
  screenName: string;
  params: any; //TODO: Figure out how to type this ReactNavigation.RootParamList
};

type LayoutState = {
  plusMenuOpen: boolean;
  plusMenuItems: PlusMenuItem[];
  settingsDrawerOpen: boolean;
  modalOpen: boolean;
  modalContent: ReactElement | null;
  filterValue: string[] | null;
  filterOptions: BBListItem[] | null;
};

const initialState: LayoutState = {
  plusMenuOpen: false,
  plusMenuItems: [],
  settingsDrawerOpen: false,
  modalOpen: false,
  modalContent: null,
  filterValue: null,
  filterOptions: null,
};

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    togglePlusMenuOpen: (state) => {
      state.plusMenuOpen = !state.plusMenuOpen;
    },
    setPlusMenuOpen: (state, action: PayloadAction<boolean>) => {
      state.plusMenuOpen = action.payload;
    },
    setEmptyPlusMenu: (state) => {
      state.plusMenuItems = [];
    },

    setPlusMenuItems: (
      state,
      action: PayloadAction<{ me?: User; projects: Project[]; plusMenuItems: PlusMenuItem[] }>
    ) => {
      //Filter out any actions that the user doesn't have permission
      const me = action.payload.me;
      const projects = action.payload.projects;

      const plusMenuItems = action.payload.plusMenuItems
        ? action.payload.plusMenuItems.filter((item) => {
            if (!me) {
              return false;
            }

            const screenName = item.screenName !== 'ProjectsStack' ? item.screenName : item.params.screen;

            switch (screenName) {
              case 'ProjectCreate':
                return canUserCreateProject(me, countActiveProjects(projects));
              case 'TaskCreate':
              case 'PunchlistCreate':
                return projects.some((project) => {
                  const userProjectRole = getUserProjectRole(project.id, me.projects);
                  return isActiveProject(project) && userProjectRole ? canUserCreateTask(userProjectRole) : false;
                });
              case 'EventCreate':
                return projects.some((project) => {
                  const userProjectRole = getUserProjectRole(project.id, me.projects);
                  return isActiveProject(project) && userProjectRole ? canUserCreateEvent(userProjectRole) : false;
                });
              case 'ChatCreate':
                return projects.some((project) => {
                  const userProjectRole = getUserProjectRole(project.id, me.projects);
                  return isActiveProject(project) && userProjectRole ? canUserCreateChat(userProjectRole) : false;
                });
              case 'DailyLogCreate':
                return projects.some((project) => {
                  const userProjectRole = getUserProjectRole(project.id, me.projects);
                  return isActiveProject(project) && userProjectRole ? true : false; //All roles can create at least one daily log type
                });
              case 'ContractCreate':
                return projects.some((project) => {
                  const userProjectRole = getUserProjectRole(project.id, me.projects);
                  return isActiveProject(project) && userProjectRole ? canUserCreateContract(userProjectRole) : false;
                });
              case 'ProjectFileUpload':
              case 'DailyLogFileUpload':
                return projects.some((project) => {
                  return isActiveProject(project);
                });
              case 'InvitePeople':
                return projects.some((project) => {
                  const userProjectRole = getUserProjectRole(project.id, me.projects);
                  return isActiveProject(project) && userProjectRole ? canUserCreateInvitation(userProjectRole) : false;
                });
              default:
                return false;
            }
          })
        : [];

      state.plusMenuItems = plusMenuItems;
    },
    setSettingsDrawerOpen: (state, action: PayloadAction<boolean>) => {
      state.settingsDrawerOpen = action.payload;
    },
    openModal: (state, action: PayloadAction<ReactElement>) => {
      state.modalContent = action.payload;
      state.modalOpen = true;
    },
    closeModal: (state) => {
      state.modalContent = null;
      state.modalOpen = false;
    },
    setFilterValue: (state, action: PayloadAction<string[] | null>) => {
      state.filterValue = action.payload;
    },
    addFilterValue: (state, action: PayloadAction<string>) => {
      state.filterValue = state.filterValue ? state.filterValue.concat(action.payload) : [action.payload];
    },
    removeFilterValue: (state, action: PayloadAction<string>) => {
      const filterValue = state.filterValue?.filter((value) => value !== action.payload);
      state.filterValue = filterValue?.length ? filterValue : null;
    },
    setFilterOptions: (state, action: PayloadAction<BBListItem[] | null>) => {
      state.filterOptions = action.payload;
    },
  },
});

export const {
  togglePlusMenuOpen,
  setPlusMenuOpen,
  setEmptyPlusMenu,
  setPlusMenuItems,
  setSettingsDrawerOpen,
  openModal,
  closeModal,
  setFilterValue,
  addFilterValue,
  removeFilterValue,
  setFilterOptions,
} = layoutSlice.actions;
