import {
  Button,
  Card,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Stack,
  Textarea,
  Typography,
} from '@mui/joy';
import { Dispatch, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import {
  AlertObject,
  ContractorProfileFormData,
  ContractorProfileSchemaResolver,
  ContractorSteps,
  getContractorProfileSubmitData,
  getDefaultContractorProfileFormValues,
  showErrorAlert,
  useAppDispatch,
  useUpdateMeMutation,
} from '@builder-bud/common-ui';

export default function ContractorCreateProfileStep({ setStep }: { setStep: Dispatch<ContractorSteps> }) {
  const dispatch = useAppDispatch();
  const [updateMe] = useUpdateMeMutation();
  const [saving, setSaving] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ContractorProfileFormData>({
    defaultValues: getDefaultContractorProfileFormValues(),
    resolver: ContractorProfileSchemaResolver,
  });

  async function onSubmit(data: ContractorProfileFormData) {
    setSaving(true);
    try {
      await updateMe(getContractorProfileSubmitData(data)).unwrap();
      setStep(ContractorSteps.contractorPayment);
    } catch (error) {
      console.log(error);
      dispatch(showErrorAlert(error as AlertObject));
    } finally {
      setSaving(false);
    }
  }

  return (
    <Card style={{ backgroundColor: 'var(--joy-palette-common-white)' }}>
      <Stack sx={{ mb: 1 }}>
        <Typography level="title-md">Create your profile.</Typography>
        <Typography level="title-sm">
          This information will be shown on your public profile. Make it fun & snappy!
        </Typography>
      </Stack>
      <Divider />
      <Stack rowGap={2}>
        <Controller
          control={control}
          name="businessName"
          render={({ field: { onChange, onBlur, value: controlValue } }) => (
            <FormControl error={!!errors.businessName}>
              <FormLabel>Business name</FormLabel>
              <Input value={controlValue} onBlur={onBlur} onChange={onChange} />
              {errors.businessName && <FormHelperText>{errors.businessName.message}</FormHelperText>}
            </FormControl>
          )}
        />

        <Controller
          control={control}
          name="bio"
          render={({ field: { onChange, onBlur, value: controlValue } }) => (
            <FormControl error={!!errors.bio}>
              <FormLabel>Bio</FormLabel>
              <Textarea value={controlValue} onBlur={onBlur} onChange={onChange} minRows={2} />
              {errors.bio && <FormHelperText>{errors.bio.message}</FormHelperText>}
            </FormControl>
          )}
        />

        <Controller
          control={control}
          name="website"
          render={({ field: { onChange, onBlur, value: controlValue } }) => (
            <FormControl error={!!errors.website}>
              <FormLabel>Website</FormLabel>
              <Input value={controlValue} onBlur={onBlur} onChange={onChange} />
              {errors.website && <FormHelperText>{errors.website.message}</FormHelperText>}
            </FormControl>
          )}
        />
      </Stack>
      <Button variant="solid" onClick={handleSubmit(onSubmit)} style={styles.button} disabled={saving}>
        Next
      </Button>
    </Card>
  );
}

const styles = {
  button: {
    width: 100,
    alignSelf: 'flex-end',
  },
};
