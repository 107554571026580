import { CalendarToday } from '@mui/icons-material';
import { Input } from '@mui/joy';
import { format, isValid, parse } from 'date-fns';
import { useId, useState } from 'react';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

export function DatePickerComponent({
  value,
  onChange,
  disabled = false,
}: {
  value: Date | undefined;
  onChange: (...event: any[]) => void;
  disabled?: boolean;
}) {
  const inputId = useId();

  const [visible, setVisible] = useState(false);
  const [month, setMonth] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(value);
  const [inputValue, setInputValue] = useState(value ? format(value, 'MM/dd/yyyy') : undefined);

  const handleDayPickerSelect = (date: Date | undefined) => {
    if (!date) {
      setInputValue('');
      setSelectedDate(undefined);
      onChange(undefined);
    } else {
      setSelectedDate(date);
      setMonth(date);
      setInputValue(format(date, 'MM/dd/yyyy'));
      onChange(date);
    }
    setVisible(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value); // keep the input value in sync

    const parsedDate = parse(e.target.value, 'MM/dd/yyyy', new Date());

    if (isValid(parsedDate)) {
      setSelectedDate(parsedDate);
      setMonth(parsedDate);
    } else {
      setSelectedDate(undefined);
    }
  };

  return (
    <>
      <Input
        id={inputId}
        value={inputValue}
        onClick={() => setVisible(!visible)}
        onChange={handleInputChange}
        placeholder="MM/DD/YYYY"
        endDecorator={<CalendarToday />}
        disabled={disabled}
      />
      {visible && (
        <DayPicker
          month={month}
          onMonthChange={setMonth}
          mode="single"
          selected={selectedDate}
          onSelect={handleDayPickerSelect}
        />
      )}
    </>
  );
}
