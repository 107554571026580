import { PhoneAuthProvider, RecaptchaVerifier, updatePhoneNumber } from '@firebase/auth';
import { Button, FormControl, FormHelperText, FormLabel, Grid, Input, Stack } from '@mui/joy';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import {
  AlertObject,
  UpdateCodeFormData,
  UpdateCodeSchemaResolver,
  UpdatePhoneFormData,
  UpdatePhoneSchemaResolver,
  showErrorAlert,
  showInfoAlert,
  useAppDispatch,
  useSynchUserMutation,
} from '@builder-bud/common-ui';

import CircularProgressComponent from '../../components/circular-progress.component';
import { auth } from '../login/firebase';

export default function UpdatePhoneForm({ hideForm }: { hideForm: React.Dispatch<void> }) {
  const dispatch = useAppDispatch();

  const [synchUser] = useSynchUserMutation();

  const [recaptchaVerifier, setRecaptchaVerifier] = useState<RecaptchaVerifier | null>(null);
  const [verificationId, setVerificationId] = useState<string | null>(null);
  const [isSaving, setSaving] = useState(false);

  const {
    control: phoneControl,
    handleSubmit: handlePhoneSubmit,
    reset: resetPhone,
    formState: { isDirty: isPhoneDirty, errors: phoneErrors },
  } = useForm<UpdatePhoneFormData>({
    defaultValues: {
      phone: '',
    },
    resolver: UpdatePhoneSchemaResolver,
  });

  const {
    control: codeControl,
    handleSubmit: handleCodeSubmit,
    reset: resetCode,
    formState: { errors: codeErrors },
  } = useForm<UpdateCodeFormData>({
    defaultValues: {
      code: '',
    },
    resolver: UpdateCodeSchemaResolver,
  });

  async function onPhoneSubmit(data: UpdatePhoneFormData) {
    try {
      console.log('Verifying phone number');
      setSaving(true);
      if (!recaptchaVerifier) throw new Error('Recaptcha Failed');
      const provider = new PhoneAuthProvider(auth);
      console.log(provider);
      const verificationId = await provider.verifyPhoneNumber(`+1${data.phone}`, recaptchaVerifier);
      console.log(verificationId);
      setVerificationId(verificationId);
    } catch (error) {
      console.log(error);
      dispatch(showErrorAlert(error as AlertObject));
    } finally {
      setSaving(false);
    }
  }

  async function onCodeSubmit(data: UpdateCodeFormData) {
    try {
      console.log('Updating phone number');
      setSaving(true);

      const currentUser = auth.currentUser;
      if (!currentUser || !verificationId) throw new Error('Current user or email is null');

      // Obtain the verificationCode from the user.
      const phoneCredential = PhoneAuthProvider.credential(verificationId, data.code);
      await updatePhoneNumber(currentUser, phoneCredential);
      resetPhone();
      resetCode();
      setVerificationId(null);
      console.log('Phone number is updated in Firebase!');
      await synchUser().unwrap();
      console.log('User is synced');
      dispatch(showInfoAlert('Successfully updated phone number'));
      hideForm();
    } catch (error) {
      console.log(error);
      dispatch(showErrorAlert(error as AlertObject));
    } finally {
      setSaving(false);
    }
  }

  useEffect(() => {
    const recaptchaVerifier = new RecaptchaVerifier(auth, 'update-phone-number-button', {
      size: 'invisible',
      callback: (response: any) => {
        console.log('recaptchaVerifier');
        console.log(response);
      },
    });
    setRecaptchaVerifier(recaptchaVerifier);
  }, []);

  return (
    <>
      {/* Setup the spinner like this to get around a weird render issue where the Code Input gets the value from the Phone Input */}
      {isSaving ? (
        <CircularProgressComponent />
      ) : !verificationId ? (
        <Grid container spacing={2}>
          <Grid xs={12}>
            <Controller
              control={phoneControl}
              name="phone"
              render={({ field: { onChange, onBlur, value } }) => (
                <FormControl error={!!phoneErrors.phone}>
                  <FormLabel>New Phone</FormLabel>
                  <Input value={value} onBlur={onBlur} onChange={onChange} />
                  {phoneErrors.phone && <FormHelperText>{phoneErrors.phone.message}</FormHelperText>}
                </FormControl>
              )}
            />
          </Grid>

          <Grid xs={12}>
            <Stack direction="row" sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }} spacing={1}>
              <Button variant="outlined" onClick={() => hideForm()}>
                Cancel
              </Button>
              <Button variant="solid" onClick={handlePhoneSubmit(onPhoneSubmit)} disabled={!isPhoneDirty}>
                Update phone
              </Button>
            </Stack>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <Grid xs={12}>
            <Controller
              control={codeControl}
              name="code"
              render={({ field: { onChange, onBlur, value } }) => (
                <FormControl error={!!phoneErrors.phone}>
                  <FormLabel>6 Digit Verification Code</FormLabel>
                  <Input value={value} onBlur={onBlur} onChange={onChange} />
                  {codeErrors.code && <FormHelperText>{codeErrors.code.message}</FormHelperText>}
                </FormControl>
              )}
            />
          </Grid>
          <Grid xs={12}>
            <Stack direction="row" sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }} spacing={1}>
              <Button variant="outlined" onClick={() => hideForm()}>
                Cancel
              </Button>
              <Button variant="solid" onClick={handleCodeSubmit(onCodeSubmit)} disabled={!isPhoneDirty}>
                Confirm code
              </Button>
            </Stack>
          </Grid>
        </Grid>
      )}
      <div id="update-phone-number-button" />
    </>
  );
}
