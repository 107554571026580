import { Button, Stack, Typography } from '@mui/joy';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { ProjectId } from '@builder-bud/common';
import {
  AlertObject,
  FileWithDescription,
  IMAGE_TYPE,
  MAX_IMAGE_FILE_SIZE,
  closeModal,
  showErrorAlert,
  useAppDispatch,
  useCreateFileMutation,
  useGetFileUploadUrlMutation,
} from '@builder-bud/common-ui';

export default function ProjectPhotoUploadModal({ projectId, fileType }: { projectId: ProjectId; fileType: string }) {
  const dispatch = useAppDispatch();
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: {
      'image/*': ['.jpeg', '.png'],
    },
  });
  const [getFileUploadUrl] = useGetFileUploadUrlMutation();
  const [createFile] = useCreateFileMutation();

  const [file, setFile] = useState<FileWithDescription>();
  const [fileSizeError, showFileSizeError] = useState(false);
  const [isFileUploading, setIsFileUploading] = useState(false);

  function onDrop(acceptedFiles: File[]) {
    if (acceptedFiles.length > 0) {
      if (acceptedFiles[0].size > MAX_IMAGE_FILE_SIZE.maxFileSize) {
        showFileSizeError(true);
      } else {
        showFileSizeError(false);

        const files = acceptedFiles.map((file: File) => {
          return {
            name: file.name,
            size: file.size,
            type: file.type,
            lastModified: file.lastModified,
            description: '',
            webFile: file,
            webFileUrl: file.type?.startsWith(IMAGE_TYPE) ? URL.createObjectURL(file) : undefined,
          };
        });
        setFile(files[0] as FileWithDescription);
      }
    }
  }

  async function handleUploadFile(e: React.MouseEvent<HTMLAnchorElement>) {
    e.stopPropagation();
    if (!file) {
      dispatch(showErrorAlert({ message: 'File not found' }));
      return;
    }

    try {
      setIsFileUploading(true);

      const fileUploadUrlResult = await getFileUploadUrl({ projectId, contentType: file.type }).unwrap();

      const url = fileUploadUrlResult.url;

      const fileUploadResult = await fetch(url, {
        method: 'PUT',
        body: file.webFile,
        headers: { 'Content-Type': file.type },
      });

      if (fileUploadResult.status !== 200) {
        throw new Error('File upload failed');
      }

      const body = {
        id: fileUploadUrlResult.id,
        projectId: projectId,
        name: file.name,
        //description: '',
        contentType: file.type,
        type: fileType,
        size: file.size,
      };

      await createFile(body).unwrap();
      dispatch(closeModal());
    } catch (error) {
      console.log(error);
      dispatch(showErrorAlert(error as AlertObject));
    } finally {
      setIsFileUploading(false);
    }
  }

  return (
    <Stack
      sx={{ margin: 2, gap: 2, padding: 8, borderRadius: 'sm', border: '1px dashed ' }}
      {...(file ? {} : getRootProps())}
    >
      <Typography>Upload a photo to your project</Typography>
      <Typography level="body-sm">Drag & drop or click here</Typography>
      <input {...getInputProps()} />
      {fileSizeError ? (
        <Typography variant="solid" color="danger">
          {MAX_IMAGE_FILE_SIZE.warning}
        </Typography>
      ) : (
        <Typography>{MAX_IMAGE_FILE_SIZE.warning}</Typography>
      )}
      {file && <img id={`${file.name}_${file.lastModified}`} height={200} width={200} src={file.webFileUrl} alt="" />}
      <Button onClick={handleUploadFile} disabled={isFileUploading || !file}>
        Upload
      </Button>
    </Stack>
  );
}
