import { Button, Stack } from '@mui/joy';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useState } from 'react';

import { api, useAppDispatch } from '@builder-bud/common-ui';

export default function StripeForm({
  paymentIntentClientSecret,
  pendingSetupIntentClientSecret,
  clearClientSecret,
}: {
  paymentIntentClientSecret?: string;
  pendingSetupIntentClientSecret?: string;
  clearClientSecret?: any;
}) {
  const dispatch = useAppDispatch();
  const stripe = useStripe();
  const elements = useElements();

  const [submitting, setSubmitting] = useState(false);

  async function handleSubmit(event: { preventDefault: () => void }) {
    // We don't want to let default form submission happen here, which would refresh the page.
    event.preventDefault();

    setSubmitting(true);

    if (!stripe || !elements || !(paymentIntentClientSecret || pendingSetupIntentClientSecret)) {
      return;
    }

    let success = false;

    if (paymentIntentClientSecret) {
      const response = await stripe.confirmPayment({
        elements,
        redirect: 'if_required',
        confirmParams: {
          return_url: process.env['NX_PUBLIC_URL'] + '/settings/subscriptions',
        },
      });
      success = response.paymentIntent?.status === 'succeeded';
    }

    if (pendingSetupIntentClientSecret) {
      const response = await stripe.confirmSetup({
        elements,
        redirect: 'if_required',
        confirmParams: {
          return_url: process.env['NX_PUBLIC_URL'] + '/settings/subscriptions',
        },
      });
      success = response.setupIntent?.status === 'succeeded';
    }

    if (success) {
      setTimeout(() => {
        dispatch(api.util.invalidateTags(['me']));
        setSubmitting(false);
        clearClientSecret();
      }, 2000);
    } else {
      setSubmitting(false);
    }

    //if (error ) {
    // This point will only be reached if there is an immediate error when
    // confirming the payment. Show error to your customer (for example, payment
    // details incomplete)
    // dispatch(showErrorAlert(error.message as AlertObject));

    //} else {
    // Your customer will be redirected to your `return_url`. For some payment
    // methods like iDEAL, your customer will be redirected to an intermediate
    // site first to authorize the payment, then redirected to the `return_url`.
    //}
  }

  return (
    <form>
      <PaymentElement />
      <Stack sx={{ flexDirection: 'row', marginY: 2, gap: 1 }}>
        <Button size="sm" variant="outlined" sx={{ flex: 1 }} onClick={clearClientSecret} disabled={!stripe}>
          Cancel
        </Button>
        <Button
          size="sm"
          variant="solid"
          sx={{ flex: 1 }}
          onClick={handleSubmit}
          disabled={!stripe}
          loading={submitting}
        >
          Submit
        </Button>
      </Stack>
    </form>
  );
}
