import { BaseResponse, WithTimestamps } from './common';
import { FileId, FileWithUrl, ReferencedFile } from './file';
import { NotificationType } from './notification';
import { ProjectId, ReferencedProject } from './project';
import { UserId } from './user';

export const CHAT_ID_PREFIX = 'chat_';
export const MESSAGE_ID_PREFIX = 'msg_';

export type ChatId = `${typeof CHAT_ID_PREFIX}${string}`;

export function isChatId(id: string): id is ChatId {
  return id.startsWith(CHAT_ID_PREFIX);
}

export type MessageId = `${typeof MESSAGE_ID_PREFIX}${string}`;

export function isMessageId(id: string): id is MessageId {
  return id.startsWith(MESSAGE_ID_PREFIX);
}

export type Chat = WithTimestamps<{
  id: ChatId;
  projectId: ProjectId;
  name?: string;
  userIds: UserId[];
  createdByUserId: UserId;
  project?: ReferencedProject; // populated in list of all user chats
  unreadMessageCount?: number;
  lastMessage?: Message;
}>;

export type ChatWithFiles = Chat & {
  files: FileWithUrl[];
};

export type Message = WithTimestamps<{
  id: MessageId;
  chatId: ChatId;
  content?: string; // not strictly true, content is required if fileIds is empty
  files?: ReferencedFile[];
  isRead: boolean;
  createdByUserId: UserId;
}>;

export type CreateChatRequest = {
  additionalUserIds: UserId[];
  name?: string;
};

export type CreateChatResponse = BaseResponse<Chat>;

export type GetChatResponse = BaseResponse<ChatWithFiles>;

export type GetChatsResponse = BaseResponse<Chat[]>;

export type UpdateChatRequest = {
  name: string | null;
};

export type UpdateChatResponse = BaseResponse<Chat>;

export type DeleteChatResponse = BaseResponse<void>;

// this isn't technically correct, but class-validator can't handle a union of types
export type CreateMessageRequest = {
  content?: string; // content is required in validator if fileIds is empty
  fileIds?: FileId[];
};

export type CreateMessageResponse = BaseResponse<Message>;

export type GetMessagesResponse = BaseResponse<Message[]>;

export type UpdateMessageRequest = {
  content: string;
  // TODO maybe add attachments
};

export type UpdateMessageResponse = BaseResponse<Message>;

export type DeleteMessageResponse = BaseResponse<void>;

export type ChatNewMessageNotification = {
  title: string;
  body: string;
  data: {
    type: NotificationType.ChatMessageReceived;
    chatId: ChatId;
    chatName: string;
    projectId: ProjectId;
    projectName: string;
    messageId: MessageId;
    message: string;
  };
};
