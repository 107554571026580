import { Avatar, AvatarGroup, Link } from '@mui/joy';
import { useNavigate } from 'react-router-dom';

import { ProjectId, UserId, UserProjectRole } from '@builder-bud/common';
import { getUserProjectRole, useGetUsersQuery } from '@builder-bud/common-ui';

import MemberComponent from './member.component';

function MembersComponent({
  userIds = [],
  projectId,
  projectRole,
  maxMembers = 5,
}: {
  userIds?: UserId[];
  projectId?: ProjectId;
  projectRole?: UserProjectRole;
  maxMembers?: number;
}) {
  const navigate = useNavigate();

  const { data: users = [] } = useGetUsersQuery();

  const filteredUsers = users.flatMap((user) => {
    if (userIds.includes(user.id)) {
      const userWithProjectRole = {
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phone: user.phone,
        projectRole: getUserProjectRole(projectId, user?.projects),
      };

      if (!projectRole || userWithProjectRole.projectRole === projectRole) {
        return [userWithProjectRole];
      }
    }
    return [];
  });

  const plusAvatarCount = filteredUsers.length - maxMembers;

  return (
    <AvatarGroup>
      {filteredUsers.slice(0, maxMembers).map((user) => (
        <MemberComponent key={user.id} userId={user.id} projectId={projectId} />
      ))}
      {plusAvatarCount > 0 && (
        <Link onClick={() => navigate(`/projects/${projectId}/members`)}>
          <Avatar>+{plusAvatarCount}</Avatar>
        </Link>
      )}
    </AvatarGroup>
  );
}

export default MembersComponent;
