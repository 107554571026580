export enum NotificationType {
  EventNewAssignment = 'event-new-assignment',
  ProjectStatusChange = 'project-status-change',
  ProjectInvitationReceived = 'project-invitation-received',
  ProjectInvitationAccepted = 'project-invitation-accepted',
  TaskStatusChange = 'task-status-change',
  TaskNewAssignment = 'task-new-assignment',
  ChatMessageReceived = 'chat-message-received',
  ProjectOnMyWay = 'project-on-my-way',
  ContractPublished = 'contract-published',
  ContractApproved = 'contract-approved',
}

export type UserNotification = {
  title: string;
  body: string;
  data: {
    type: NotificationType;
    [key: string]: string;
  };
};
